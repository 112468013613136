export function calculateImpressions(
	amountInBaseUnits: number,
	cpm: number,
	bonusCpm?: number | null,
	bonusThreshold?: number | null,
): number {
	let effectiveCpm = cpm;
	if (bonusCpm && bonusThreshold && amountInBaseUnits >= bonusThreshold) {
		effectiveCpm = bonusCpm;
	}
	const costPerView = effectiveCpm / 1000;
	return Math.round(amountInBaseUnits / costPerView);
}
