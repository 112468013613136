import { getCategoryApiLabel } from '../../shared/categories';
import { getSubCategoryApiLabel } from '../../shared/subCategories';
import type {
	CommentReportContext,
	FormValues,
	PetitionReportContext,
	ReportContext,
	SupporterCommentReportContext,
	VideoCommentReportContext,
} from '../../shared/types';

import type { Report } from './api';
import type { Common } from './api/shared/types';

const petitionPayload = (context: PetitionReportContext, common: Common): Report => ({
	...common,
	type: 'petition',
	petitionId: context.petitionId,
	petitionUrl: `/p/${context.petitionSlug}`,
});
const commentPayload = (context: CommentReportContext, common: Common): Report => ({
	...common,
	type: 'comment',
	commentId: context.comment.id,
	petitionUrl: `/p/${context.petitionSlug}`,
	commentUrl: `/p/${context.petitionSlug}/c/${context.comment.id}`,
});
const supporterCommentPayload = (context: SupporterCommentReportContext, common: Common): Report => ({
	...common,
	type: 'supporter_comment',
	commentId: context.comment.id,
	petitionUrl: `/p/${context.petitionSlug}`,
	commentUrl: `/p/${context.petitionSlug}/c/${context.comment.id}`,
});
const videoCommentPayload = (context: VideoCommentReportContext, common: Common): Report => ({
	...common,
	type: 'video_comment',
	commentId: context.comment.id,
	petitionUrl: `/p/${context.petitionSlug}`,
	commentUrl: `/p/${context.petitionSlug}/v/${context.comment.id}`,
});

export function normalizeForApi(context: ReportContext, values: FormValues): Report {
	const category = values.category ? getCategoryApiLabel(context.type, values.category) : '';
	const subCategory = values.subCategory ? getSubCategoryApiLabel(context.type, values.subCategory) : category;
	const common: Common = {
		category,
		subCategory,
		reason: values.reason || '',
	};
	switch (context.type) {
		case 'petition':
			return petitionPayload(context, common);
		case 'comment':
			return commentPayload(context, common);
		case 'supporter_comment':
			return supporterCommentPayload(context, common);
		case 'video_comment':
			return videoCommentPayload(context, common);
		case 'generic':
			return {
				type: 'generic',
				...common,
				pageUrl: context.pageUrl,
			};
		default:
			throw new Error('Unsupported report context type');
	}
}
