import { normalizeMedia } from './normalizeMedia';
import type { ApiPetitionUpdate, PetitionContentUpdate, PetitionInfo, PetitionUpdate } from './types';

export function normalizeUpdate(update: ApiPetitionUpdate, petitionInfo: PetitionInfo): PetitionUpdate {
	switch (update.__typename) {
		case 'MilestonePetitionUpdate':
			return {
				id: update.id,
				createdAt: update.createdAt,
				createdAtLocalized: update.createdAtLocalized,
				type: 'milestone',
				signatureCount: update.signatureCount,
			};
		case 'StarterPetitionUpdate':
			return normalizeStarterPetitionUpdate(update, petitionInfo);
		case 'StaffPetitionUpdate':
			return normalizeStaffPetitionUpdate(update, petitionInfo);
		case 'FeaturedNewsPetitionUpdate':
			return normalizeFeaturedNewsPetitionUpdate(update, petitionInfo);
		case 'DmResponsePetitionUpdate':
			return normalizeDmResponsePetitionUpdate(update, petitionInfo);
		default:
			throw new Error(`Invalid update type: ${(update as ApiPetitionUpdate).__typename}`);
	}
}

function normalizeStarterPetitionUpdate(
	update: Extract<ApiPetitionUpdate, { __typename: 'StarterPetitionUpdate' }>,
	{ slug, starter }: PetitionInfo,
): PetitionContentUpdate {
	const base = { id: update.id, createdAt: update.createdAt, createdAtLocalized: update.createdAtLocalized };

	return {
		...base,
		url: `/p/${slug}/u/${update.id}`,
		type: 'starter',
		title: update.title,
		description: update.description,
		photo: normalizeMedia(update),
		author: starter,
	};
}
function normalizeStaffPetitionUpdate(
	update: Extract<ApiPetitionUpdate, { __typename: 'StaffPetitionUpdate' }>,
	{ slug }: PetitionInfo,
): PetitionContentUpdate {
	const base = { id: update.id, createdAt: update.createdAt, createdAtLocalized: update.createdAtLocalized };

	return {
		...base,
		url: `/p/${slug}/u/${update.id}`,
		type: 'staff',
		title: update.title,
		description: update.description,
		photo: normalizeMedia(update),
		author: null,
	};
}

function normalizeFeaturedNewsPetitionUpdate(
	update: Extract<ApiPetitionUpdate, { __typename: 'FeaturedNewsPetitionUpdate' }>,
	{ slug }: PetitionInfo,
): PetitionContentUpdate {
	const base = { id: update.id, createdAt: update.createdAt, createdAtLocalized: update.createdAtLocalized };

	return {
		...base,
		url: `/p/${slug}/u/${update.id}`,
		type: 'featuredNews',
		title: update.title,
		description: update.embeddedMedia?.description || null,
		photo: normalizeMedia(update),
		author: update.embeddedMedia?.providerName
			? {
					displayName: update.embeddedMedia.providerName,
					photo: null,
				}
			: null,
	};
}

function normalizeDmResponsePetitionUpdate(
	update: Extract<ApiPetitionUpdate, { __typename: 'DmResponsePetitionUpdate' }>,
	{ slug }: PetitionInfo,
): PetitionContentUpdate {
	const base = { id: update.id, createdAt: update.createdAt, createdAtLocalized: update.createdAtLocalized };

	return {
		...base,
		url: `/p/${slug}/responses/${update.dmResponse.id}`,
		type: 'dmResponse',
		title: update.title,
		description: update.description,
		photo: normalizeMedia(update),
		author: {
			displayName: update.dmResponse.decisionMaker.displayName,
			photo: update.dmResponse.decisionMaker.photo?.userSmall || null,
		},
	};
}
