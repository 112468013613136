import type { FeatureConfigInfo } from '@change-corgi/core/fcm';

import type { ExperimentName, FcmExperimentConfig } from './types';

/**
 * This is only provided to make typing and type checking easier
 */
export function createExperimentFcmConfig<T extends Record<string, FeatureConfigInfo>, EN extends ExperimentName<T>>(
	options: FcmExperimentConfig<T, EN>,
): typeof options {
	return options;
}
