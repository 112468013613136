import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'membership-guest-confirm',
		path: '/member/confirm',
		component: loadable(async () => import(/* webpackChunkName: "pages/membership_guestConfirm" */ './index'), {
			exportName: 'GuestConfirm',
			fallback: Loading,
		}),
		priority: 10, // to prepare for the migration of /member/:page
		frame: true,
	},
];
