/**
 * Ensures a thrown value is an Error in a type-safe manner.
 * @param e Raw error from `catch(e)` block which may have any type.
 * @returns An Error instance.
 */
export const checkError = (e: unknown): Error => {
	if (e instanceof Error) {
		return e;
	}
	if (typeof e === 'string') {
		return new Error(e);
	}
	return new Error('unknown error', { cause: e });
};
