import { createPrefetchableAsyncUserDataContext, pageDataErrorHandler } from 'src/app/shared/contexts/asyncData';

import { getPetitionDetailsPageUserData } from './api';
import type { PetitionDetailsPageUserDataErrorCode } from './types';

export const {
	Context: PetitionDetailsPageUserDataContext,
	Provider: PetitionDetailsPageUserDataContextProvider,
	useContext: usePetitionDetailsPageUserData,
	useAsyncData: usePetitionDetailsPageUserDataFromApi,
	prefetchAsyncData: prefetchPetitionDetailsPageUserDataFromApi,
} = createPrefetchableAsyncUserDataContext({
	name: 'PetitionDetailsPageUserDataContext',
	dataProperty: 'pageUserData',
	getData: getPetitionDetailsPageUserData,
	getUniqueId: (slug, { isGuest, decisionMakerHash }) => [slug, isGuest, decisionMakerHash].join(','),
	hookDeps: (slug, { isGuest, decisionMakerHash }) => [slug, isGuest, decisionMakerHash],
	errorHandler: pageDataErrorHandler<PetitionDetailsPageUserDataErrorCode>(),
});
