import { useEffect } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

import { trackPromotionEvent } from './api/promotionEvent';
import { usePromotedTrackingDataWithQueryParams } from './promotedTrackingDataWithQueryParams';

export function useTrackPromotedView(): void {
	const promotedData = usePromotedTrackingDataWithQueryParams();
	const countryCode = useCountryCode();
	const utilityContext = useUtilityContext();

	useEffect(() => {
		if (!promotedData?.token) return;
		void trackPromotionEvent(
			'VIEW',
			{
				promotionToken: promotedData.token,
				countryCode,
				placement: promotedData.placement || null,
				algorithm: promotedData.algorithm || null,
			},
			utilityContext,
		);
	}, [promotedData, countryCode, utilityContext]);
}
