import { useContext } from 'react';

import type { PetitionStats, PetitionStatsErrorReason, PetitionStatsState } from './apiPetitionStats';
import { PetitionStatsContext } from './context';

export type { PetitionStats, PetitionStatsErrorReason, PetitionStatsState };

export function usePetitionStats(): PetitionStatsState {
	const context = useContext(PetitionStatsContext);

	if (!context) {
		throw new Error('PetitionStatsContext was not set');
	}

	return context;
}
