import { withPrefetchedData } from '@change-corgi/core/react/prefetch';

import type { MembershipAwarenessBannerPrefetchedDataProps } from './context';
import { MembershipAwarenessBannerContextProvider, prefetchContext } from './context';
import { MembershipAwarenessBanner as Layout } from './layout';

type MembershipAwarenessBannerProps = MembershipAwarenessBannerPrefetchedDataProps;

export const MembershipAwarenessBanner = withPrefetchedData(
	function MembershipAwarenessBannerEntry({ prefetchedData }: MembershipAwarenessBannerProps): React.JSX.Element {
		return (
			<MembershipAwarenessBannerContextProvider prefetchedData={prefetchedData}>
				<Layout />
			</MembershipAwarenessBannerContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => prefetchContext(context),
	},
);
