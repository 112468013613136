import { normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_ALLOW_IDENTIFIED_USER_FETCH_PETITION_LISTS = createFcmConfig(
	'allow_identified_user_fetch_petition_lists',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
);
