import { withPrefetchedData } from '@change-corgi/core/react/prefetch';

import { MembershipBottomBanner as Layout } from './component';
import type { MembershipBottomBannerPrefetchedDataProps } from './context';
import { MembershipBottomBannerContextProvider, prefetchContext } from './context';
import type { Props } from './shared/types';

type MembershipBottomBannerProps = MembershipBottomBannerPrefetchedDataProps & Props;

export const MembershipBottomBanner = withPrefetchedData(
	function MembershipBottomBannerEntry({
		prefetchedData,
		...layoutProps
	}: MembershipBottomBannerProps): React.JSX.Element {
		return (
			<MembershipBottomBannerContextProvider prefetchedData={prefetchedData}>
				<Layout {...layoutProps} />
			</MembershipBottomBannerContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => prefetchContext(context),
	},
);
