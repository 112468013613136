/* eslint-disable @typescript-eslint/naming-convention */
import { HOMEPAGE_L10N_MAP } from 'config/routes-l10n';

import type { LocaleResolverContext } from 'src/shared/routes';

type ShortcutLocalesResolverContext = LocaleResolverContext & { params: { locale?: string } };

export async function resolveShortcutLocales({
	path,
	params: { locale: localeParam },
	query: { lang, country_code },
}: ShortcutLocalesResolverContext): Promise<{ locale?: string; countryCode?: string } | undefined> {
	// react-router 6 removed support of regexs - there's an ongoing discussion to re-add them (https://github.com/remix-run/react-router/discussions/9844)
	const locale = localeParam || path.split('/')[1];
	const l10n = locale ? HOMEPAGE_L10N_MAP[locale.toLowerCase()] : undefined;

	return Promise.resolve({
		locale: typeof lang === 'string' ? lang : l10n?.lang,
		countryCode: typeof country_code === 'string' ? country_code : l10n?.country_code,
	});
}
