import { useRef } from 'react';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

const LOG_ENVS = ['development', 'test'];

export function useCheckHookDeps(deps: readonly unknown[], utilityContext: UtilityContext): void {
	const reported = useRef(false);

	if (reported.current) {
		return;
	}

	if (deps.some((p) => typeof p === 'object' && p !== null)) {
		reported.current = true;
		if (LOG_ENVS.includes(process.env.NODE_ENV)) {
			// eslint-disable-next-line no-console
			console.error('Unexpected object hook dependency => add or update hookDeps on context factory to fix');
		}
		void utilityContext.errorReporter.report(
			{
				error: new Error('Unexpected object hook dependency => add or update hookDeps on context factory to fix'),
			},
			{ severity: 'warning' },
		);
	}
}
