import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		// old url from when we used team-based prefixes - should not be used anymore
		path: '/co/member/:id/admin',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { id }, queryString }) => `/member/${id!}/admin${queryString}`,
		reason: 'deprecated-url',
	},
];
