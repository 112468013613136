import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'account-settings-cookie-prefs',
		path: [
			'/account_settings/manage_cookies',
			// TODO: remove once url is fixed in fe
			'/u/manage_cookies',
		],
		// TODO: remove once url is fully replaced with /account_settings/manage_cookies
		priority: 10, // higher priority than /u/:id
		component: loadable(async () => import(/* webpackChunkName: "pages/cookie_prefs" */ './index'), {
			exportName: 'CookiePrefs',
			fallback: Loading,
		}),
		frame: true,
		preventCookieWall: true,
		ssr: {
			// caveat: since we're still using localStorage, part of the page will be client-rendered
			enabled: true,
			seo: false,
		},
	},
];
