import { optimizelyProjectIds } from '@change-corgi/config/optimizely';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { EventTracker } from '@change-corgi/core/eventTracker';
import type { FcmUtils } from '@change-corgi/core/fcm';
import type { Optimizely } from '@change-corgi/core/optimizely';
import { createOptimizely as _createOptimizely } from '@change-corgi/core/optimizely';

type Options = Readonly<{
	environment: EnvironmentUtils;
	fcm: FcmUtils;
}>;

function getOptimizelyProjectId(environment: EnvironmentUtils): string {
	return optimizelyProjectIds[environment.getEnvironment()] || optimizelyProjectIds.development;
}

export function createOptimizely({ environment, fcm }: Options): Optimizely {
	return _createOptimizely({
		projectId: getOptimizelyProjectId(environment),
		getFeatureConfig: fcm.get.bind(fcm),
	});
}

type InitOptions = Readonly<{
	tracker: EventTracker;
}>;

// this is needed because tracker depends on optimizely, and optimizely depends on tracker
export function initOptimizely(optimizely: Optimizely, { tracker }: InitOptions): Optimizely {
	return optimizely.setTrack(tracker.track.bind(tracker)).preload().init();
}
