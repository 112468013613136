import { useMemo } from 'react';

import type { StateWithoutError } from 'src/app/shared/utils/async';
import { isLoading } from 'src/app/shared/utils/async';

import { useShareChannelsAvailability, useShareChannelsAvailabilityAsync } from './availability';
import type { ShareChannel } from './shared/types';

export function useAvailableShareChannels<CH extends ShareChannel = ShareChannel>(
	channels: readonly CH[],
): readonly CH[] {
	const availability = useShareChannelsAvailability();

	return useMemo(() => {
		const filtered = channels.filter((channel) => availability[channel]);
		// TODO filter out "email" when "gmail" is in the list
		// if (filtered.includes('gmail')) {
		// 	return filtered.filter((channel) => channel !== email)
		// }
		return filtered;
	}, [channels, availability]);
}

export type AsyncResult<CH extends ShareChannel = ShareChannel> = StateWithoutError<{ channels: readonly CH[] }>;

export function useAvailableShareChannelsAsync<CH extends ShareChannel = ShareChannel>(
	channels: readonly CH[],
): AsyncResult<CH> {
	const availability = useShareChannelsAvailabilityAsync();

	return useMemo(() => {
		if (isLoading(availability)) return availability;

		const filtered = channels.filter((channel) => availability.channels[channel]);
		// TODO filter out "email" when "gmail" is in the list
		// if (filtered.includes('gmail')) {
		// 	return filtered.filter((channel) => channel !== email)
		// }
		return { status: 'loaded', channels: filtered };
	}, [channels, availability]);
}
