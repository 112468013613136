import { useMemo } from 'react';
import type { JSX } from 'react';

import { Field, useField } from 'react-final-form';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Select } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';
import { requiredValidator } from 'src/app/shared/form/validators';

import { useCategoryLabel } from '../hooks/useCategoryLabel';
import { getSubCategories } from '../shared/subCategories';
import type { FormValues, ReportType, SubCategory } from '../shared/types';

type Props = Readonly<{
	type: ReportType;
}>;

const validator = requiredValidator<SubCategory>({
	i18n: { key: translationKey('fe.components.petition_report_abuse.errors.sub_category_required') },
});

export function SubCategoryField({ type }: Props): JSX.Element | null {
	const {
		input: { value: category },
	} = useField<FormValues['category']>('category', { subscription: { value: true } });

	const subCategories = useMemo(() => (category ? getSubCategories(type, category) : []), [type, category]);

	const { translate } = useI18n();
	const { getErrorMessage } = useFormI18n();

	const getLabel = useCategoryLabel(type, 'subcategory');

	if (!subCategories.length) return null;

	return (
		<Box mb={8}>
			<Field<FormValues['subCategory']> name="subCategory" validate={validator}>
				{({ input, meta }) => (
					<Select
						id="report-abuse-subcategory"
						name={input.name}
						value={input.value}
						onChange={input.onChange}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						autoComplete="country"
						data-testid="reportAbuse-subCategory-select"
						aria-label={translate('fe.components.petition_report_abuse.sub_category_aria_label')}
						label={translate('fe.components.petition_report_abuse.sub_category_aria_label')}
					>
						<option value="">{translate('fe.components.petition_report_abuse.sub_category_prompt')}</option>
						{subCategories.map((subCategory) => (
							<option value={subCategory} key={subCategory}>
								{getLabel(subCategory)}
							</option>
						))}
					</Select>
				)}
			</Field>
		</Box>
	);
}
