import { useCallback } from 'react';

export function useChangeCallback<T>(
	onChange: (value: T) => void,
): (event: React.ChangeEvent<HTMLInputElement>) => void {
	return useCallback(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value as unknown as T),
		[onChange],
	);
}
