import type { MembershipBottomBannerState } from './types';

export const mutations = {
	setPaymentTerm(
		state: MembershipBottomBannerState,
		value: 'one-time' | 'monthly' | 'yearly',
	): MembershipBottomBannerState {
		return {
			...state,
			paymentTerm: value,
		};
	},
};
