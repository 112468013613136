import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { ROUTES_COMPONENT } from 'src/app/pages/petition/routes.config';
import type { SubRouteConfig } from 'src/app/pages/petition/shared/tabTypes';
import { handleRecruiter, Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'petition-comment',
		path: '/p/:slug/c/:id',
		component: ROUTES_COMPONENT,
		frame: true,
		beforeServerRender: async (context) => {
			await handleRecruiter(context, context.params.slug);
		},
		ssr: {
			enabled: true,
			seo: false,
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		additionalConfig: {
			routeType: 'petitionSubRoute',
			tab: 'comments',
			trackingData: {
				currentPage: 'comment',
			},
			hideTitle: false,
			component: loadable(async () => import(/* webpackChunkName: "pages/petition_comment" */ './index'), {
				exportName: 'PetitionComment',
				fallback: Loading,
			}),
		} as SubRouteConfig,
	},
];
