import type { JSX } from 'react';

import { TranslateHtml } from '@change-corgi/core/react/i18n';
import { Text } from '@change-corgi/design-system/typography';

export function Header(): JSX.Element {
	return (
		<Text as="div">
			{/* TODO: replace links using <Link>? */}
			<TranslateHtml
				value="fe.components.petition_report_abuse.form_description"
				replacements={{ tosUrl: '/policies/terms-of-service', cgUrl: '/policies/community' }}
			/>
		</Text>
	);
}
