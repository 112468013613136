import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { VerifyNewUserEmailMutation, VerifyNewUserEmailMutationVariables } from './verifyNewUserEmail.graphql';

const query = gql`
	mutation VerifyNewUserEmail($input: VerifyNewUserEmailInput!) {
		verifyNewUserEmail(input: $input) {
			__typename
		}
	}
`;

export async function verifyNewUserEmail(
	redirectUrl: string,
	{ gql: { fetch } }: UtilityContext,
	userId: string,
): Promise<boolean> {
	try {
		const { data } = await fetch<VerifyNewUserEmailMutation, VerifyNewUserEmailMutationVariables>({
			query,
			variables: {
				input: {
					userId,
					redirectUrl,
				},
			},
		});

		if (!data) throw new Error('no data');
		if (data.verifyNewUserEmail.__typename === 'VerifyNewUserEmailError') return false;
		return true;
	} catch (e) {
		return false;
	}
}
