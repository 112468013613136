import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		disable_for_users: {
			type: 'array',
			shape: {
				type: 'number',
			},
		},
	},
});

export const FCM_MEMBERSHIP_BADGE = createFcmConfig(
	'membership_badge',
	{
		normalizer: createJsonNormalizer(normalizer),
		defaultValue: undefined,
	},
	{
		merger: ({ globalValue, countryValue }) => {
			let enabled = !!globalValue?.enabled;
			// eslint-disable-next-line @typescript-eslint/naming-convention
			let disable_for_users = globalValue?.disable_for_users ?? [];
			if (countryValue) {
				enabled = countryValue.enabled;
				disable_for_users = [...disable_for_users, ...countryValue.disable_for_users];
			}

			return {
				enabled,
				disable_for_users,
			};
		},
	},
);
