function getPerformance() {
	return typeof performance === 'undefined' ? undefined : performance;
}

export function markTimeline(label: string): void {
	getPerformance()?.mark?.(`${label}:start`);
}

export function markTimelineStart(label: string): void {
	getPerformance()?.mark?.(`${label}:start`);
}

function getLabelIfExists(label: string) {
	const performance = getPerformance();
	if (!performance) {
		return undefined;
	}
	return performance.getEntriesByName?.(label, 'mark')?.length || 0 > 1 ? label : undefined;
}

export function markTimelineEnd(label: string): void {
	const performance = getPerformance();
	if (!performance) {
		return;
	}
	performance.mark?.(`${label}:end`);
	try {
		performance.measure?.(label, getLabelIfExists(`${label}:start`), `${label}:end`);
	} catch (e) {
		// some browser (e.g. IE11) don't accept a undefined/unset start label and throw a SyntaxError
	}
}
