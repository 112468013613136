import type { JSX } from 'react';

import { useNow } from '@change-corgi/core/react/date';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { PetitionContentUpdate } from 'src/app/pages/petition/details/shared/types';

import { UpdateByLineAvatar } from './UpdateByLineAvatar';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

// using this trick to avoid clipping of the first letter (due to Noto-Sans' design)
const clippingFixStyles = {
	paddingLeft: 8,
	marginLeft: -8,
};

export function UpdateByLine({ update }: Props): JSX.Element | null {
	const now = useNow();
	const { localizeRelativeTime, translate } = useI18n();

	const { author } = update;

	const displayName = update.type === 'staff' ? translate('fe.pages.sponsors.change_team') : author?.displayName;

	if (!displayName) {
		return null;
	}

	return (
		<Flex mt={[8, 16]} sx={{ alignItems: 'center' }}>
			<UpdateByLineAvatar update={update} />
			<Box sx={{ flex: 1 }}>
				<Text
					as="div"
					color="typography-primary"
					size="caption"
					sx={{
						fontWeight: 'bold',
						...clippingFixStyles,
					}}
					ellipsis
				>
					{displayName}
				</Text>
				<Text as="div" color="typography-primary" size="caption" sx={clippingFixStyles} ellipsis>
					{localizeRelativeTime(now, update.createdAt)}
				</Text>
			</Box>
		</Flex>
	);
}
