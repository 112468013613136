import type { MutableRefObject } from 'react';
import { useRef } from 'react';

import type { FocusFallbackListener } from '../types';

import { useFocusFallback } from './useFocusFallback';

export function useFocusFallbackRef(listener?: FocusFallbackListener): MutableRefObject<null> {
	const ref = useRef(null);

	useFocusFallback(ref, listener);

	return ref;
}
