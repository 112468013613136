import type { JSX } from 'react';

import { Translate, TranslatePlural, useI18n } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { ProgressIndicator } from '@change-corgi/design-system/components/navigation';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	count: number;
	goal: number;
	localizedCount: string;
	localizedGoal: string;
};

export function SignatureCount({ count, goal, localizedCount, localizedGoal }: Props): JSX.Element {
	const { translate } = useI18n();

	return (
		<>
			<Box data-qa="signature-thermometer">
				<ProgressIndicator
					variant="purple"
					aria-label={translate('corgi.components.signature_thermometer.label')}
					completionPercentage={Math.round((count / goal) * 100)}
				/>
			</Box>
			<Box>
				<VisuallyHidden>
					<Text>
						<Translate
							value="corgi.petition.details.signature_count_bar.a11y.signature_count"
							replacements={{ signCount: localizedCount }}
						/>
					</Text>
					<Text>
						<Translate
							value="corgi.petition.details.signature_count_bar.a11y.signature_goal"
							replacements={{ nextGoal: localizedGoal }}
						/>
					</Text>
				</VisuallyHidden>
				<Box mb={8} sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }} aria-hidden>
					<Box>
						<Text fontWeight="bold" size="huge" color="secondary-purple">
							{localizedCount}
						</Text>
					</Box>
					<Box>
						<Text
							fontWeight="bold"
							size="huge"
							color="neutral-grey600"
							sx={{
								textAlign: 'right',
								display: 'inline-block',
								width: '100%',
							}}
						>
							{localizedGoal}
						</Text>
					</Box>
					<Box>
						<Text color="secondary-purple">
							<TranslatePlural value="corgi.petition.details.signature_count_bar.signature_count" count={count} />
						</Text>
					</Box>
					<Box>
						<Text
							color="neutral-grey600"
							sx={{
								textAlign: 'right',
								display: 'inline-block',
								width: '100%',
							}}
						>
							<Translate value="corgi.petition.details.signature_count_bar.signature_goal" />
						</Text>
					</Box>
				</Box>
			</Box>
		</>
	);
}
