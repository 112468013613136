import type { I18nFieldValidator, NonI18nFieldValidator, WithI18nConfig, WithNonI18nConfig } from './shared/types';

export function requiredValidator<T extends string | number | undefined = string | undefined>({
	i18n,
}: WithI18nConfig<EmptyIntersection>): I18nFieldValidator<T | undefined> {
	return (value) => (value || value === 0 ? undefined : i18n);
}

export function nonI18nRequiredValidator<T extends string | number | undefined = string | undefined>({
	message,
}: WithNonI18nConfig<EmptyIntersection>): NonI18nFieldValidator<T | undefined> {
	return (value) => (value || value === 0 ? undefined : message);
}

export function requiredValidatorWithNoMessage(value: string | number | undefined): true | undefined {
	return value || value === 0 ? undefined : true;
}
