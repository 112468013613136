import { forwardRef } from 'react';
import type { JSX } from 'react';

import { Link } from '@change-corgi/core/react/router';
import { Box } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

import type { PetitionContentUpdate } from 'src/app/pages/petition/details/shared/types';

import { UpdateBody } from './UpdateBody';
import { UpdateByLine } from './UpdateByLine';
import { UpdateCardHeading } from './UpdateCardHeading';
import { UpdateMedia } from './UpdateMedia';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

export const UpdateCard = forwardRef<HTMLAnchorElement, Props>(function UpdateCard(
	{ update }: Props,
	ref,
): JSX.Element | null {
	return (
		<Link
			to={update.url}
			sx={{
				...linkResetStyles,
				display: 'block',
				borderRadius: 'standard',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:focus, &:hover': {
					a: {
						color: 'primary-black',
						textDecoration: 'none',
					},
				},
			}}
			ref={ref}
		>
			<Box
				variant="bordered"
				sx={{
					borderColor: 'neutral-grey100',
					overflow: 'hidden',
				}}
				backgroundColor="primary-white"
			>
				<UpdateCardHeading update={update} />
				<UpdateMedia update={update} />
				<Box p={16}>
					<Text as="div" size="large" sx={{ fontSize: [16, 20], fontWeight: 'bold' }} mb={8}>
						{update.title}
					</Text>
					<UpdateBody update={update} />
					<UpdateByLine update={update} />
				</Box>
			</Box>
		</Link>
	);
});
