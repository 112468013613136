import { normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_STARTER_DASHBOARD_DECISION_MAKERS_LIST_ENABLED = createFcmConfig(
	'starter_dashboard_decision_makers_list_enabled',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
	{
		merger: ({ localeValue, countryValue }) => localeValue && countryValue,
	},
);
