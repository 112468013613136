import type { JSX } from 'react';

import { translationKey } from '@change-corgi/core/i18n';
import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { iconInstagram } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { Instruction } from './Instruction';

const INSTAGRAM_URL = 'instagram://story-camera';

type Props = {
	onContinue: () => void;
};

export function EducationModal({ onContinue }: Props): JSX.Element {
	const { translate } = useI18n();

	return (
		<Box aria-label={translate('fe.components.psf.instagram.modal_name')} mt={16}>
			<Box
				variant="bordered"
				mb={16}
				p={16}
				sx={{ backgroundColor: 'primary-greyBackground', borderWidth: 0, borderRadius: 5 }}
			>
				<Heading as="h2" size="h4" mb={8} color="secondary-purple">
					<Translate value="fe.components.psf.instagram.instructions.how_to" />
				</Heading>
				<Flex
					as="ol"
					role="list"
					sx={{
						flexDirection: 'column',
						gap: 8,
						listStyle: 'inside decimal',
						paddingLeft: 8,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'> li::marker': {
							fontWeight: 'bold',
						},
					}}
				>
					<Box as="li" role="listitem">
						<Box sx={{ display: 'inline-block' }}>
							<Instruction
								text={translationKey('fe.components.psf.instagram.instructions.tap_sticker')}
								icon="https://static.change.org/psf/instagram-share/instagram-sticker.png"
								accessibleText={translationKey('fe.components.psf.instagram.accessibility.sticker_icon')}
							/>
						</Box>
					</Box>
					<Box as="li" role="listitem">
						<Box sx={{ display: 'inline-block' }}>
							<Instruction
								text={translationKey('fe.components.psf.instagram.instructions.select')}
								icon="https://static.change.org/psf/instagram-share/instagram-link.png"
								accessibleText={translationKey('fe.components.psf.instagram.accessibility.link_icon')}
							/>
						</Box>
					</Box>
					<Box as="li" role="listitem">
						<Box sx={{ display: 'inline-block' }}>
							<Instruction text={translationKey('fe.components.psf.instagram.instructions.paste_link')} />
						</Box>
					</Box>
				</Flex>
			</Box>
			<ButtonLink
				to={INSTAGRAM_URL}
				target="_blank"
				variant="primary"
				icon={iconInstagram}
				onClick={onContinue}
				sx={{ width: '100%' }}
				data-testid="instagram-share-continue"
			>
				<Translate value="fe.components.psf.instagram.continue" />
			</ButtonLink>
		</Box>
	);
}
