import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

export function isIPhone({ userAgent }: UtilityContext): boolean {
	return ['iPhone'].includes(userAgent.getParsedUserAgent().getDevice().model || '');
}

export function isIPhoneOrIPad({ userAgent }: UtilityContext): boolean {
	return ['iPhone', 'iPad'].includes(userAgent.getParsedUserAgent().getDevice().model || '');
}

export function isAndroidPhone({ userAgent }: UtilityContext): boolean {
	return (
		userAgent.getParsedUserAgent().getDevice().type === 'mobile' &&
		userAgent.getParsedUserAgent().getOS().name === 'Android'
	);
}

export function isChrome({ userAgent }: UtilityContext): boolean {
	return userAgent.getParsedUserAgent().getBrowser().name === 'Chrome';
}

export function isAndroidChrome(utilityContext: UtilityContext): boolean {
	return isAndroidPhone(utilityContext) && isChrome(utilityContext);
}

export function isAndroidWebview({ userAgent }: UtilityContext): boolean {
	return userAgent.getParsedUserAgent().getOS().name === 'Android' && userAgent.isWebview;
}

export function isSafari({ userAgent }: UtilityContext): boolean {
	return ['Safari', 'Mobile Safari'].includes(userAgent.getParsedUserAgent().getBrowser().name || '');
}
