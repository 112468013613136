import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		activatedPercentage: { type: 'number' },
		specificPetitions: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					id: { type: 'number' },
				},
			},
		},
		blocklist: {
			optional: true,
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					id: { type: 'number' },
				},
			},
		},
		supportedLocales: {
			optional: true,
			type: 'array',
			shape: { type: 'string' },
		},
		isUploadEnabled: {
			optional: true,
			type: 'boolean',
		},
	},
});

export const FCM_CAMPAIGN_ACTIONS_PAGE_CONFIG = {
	name: 'campaign_actions_page_config',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		activatedPercentage: 0,
		specificPetitions: [],
		blocklist: [],
		supportedLocales: [],
		isUploadEnabled: false,
	} as ReturnType<typeof normalizer>,
};
