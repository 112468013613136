import { type JSX } from 'react';

import { Flex } from '@change-corgi/design-system/layout';

import type { SupporterVideo } from '../../shared/types';
import { VideoPlayer } from '../VideoPlayer';

export function PetitionVideoItem({ video }: { video: SupporterVideo }): JSX.Element {
	return (
		<Flex
			sx={{
				placeContent: 'center',
				height: ['unset', 300],
				minHeight: 100,
				maxHeight: 400,
			}}
		>
			<VideoPlayer creator={video.user?.shortDisplayName} videoUrl={video.videoUrl} videoId={video.id} controls />
		</Flex>
	);
}
