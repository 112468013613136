import type { Mutator } from 'final-form';
import { useForm } from 'react-final-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SignFormMutators<FORM_VALUES = any> = Record<'setFormValue', Mutator<FORM_VALUES>>;

export const valueSetterMutator: SignFormMutators = {
	// expect (field, value) args from the mutator
	setFormValue: ([field, value], state, { changeValue }) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
		changeValue(state, field, () => value);
	},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFormValueSetter<FORM_VALUES = any>(): <KEY extends keyof FORM_VALUES>(
	field: KEY,
	value: FORM_VALUES[KEY],
) => void {
	const { setFormValue } = useForm().mutators;
	if (!setFormValue) {
		throw new Error('Cannot find setFormValue form mutator; make sure to set valueSetterMutator on the parent form');
	}
	return setFormValue;
}
