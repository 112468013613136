import type { ComponentProps, JSX, PropsWithChildren } from 'react';

import { Box, Container } from '@change-corgi/design-system/layout';

type Props = Readonly<{
	big?: boolean;
	/**
	 * default: error (yellow)
	 */
	variant?: 'error' | 'success' | 'purple';
}>;

export function BannerWrapper({
	children,
	big,
	variant,
	...rest
}: PropsWithChildren<Props> & Omit<ComponentProps<typeof Box>, 'variant'>): JSX.Element {
	const FlashMessage = variant === 'success' ? FlashMessageSuccess : FlashMessageError;

	return (
		<FlashMessage
			role="alert"
			sx={
				variant === 'purple'
					? {
							backgroundColor: '#3E1B63',
							color: 'primary-white',
						}
					: {
							borderBottomWidth: 1,
							borderBottomStyle: 'solid',
						}
			}
			data-nosnippet
			{...rest}
		>
			<Container
				variant="small"
				px={8}
				sx={{
					lineHeight: big ? '24px' : '20px',
					fontSize: big ? 16 : 14,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'& a': {
						textDecoration: 'underline',
						fontWeight: 'bold',
						color: 'inherit',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': {
							color: 'inherit',
						},
					},
				}}
				py={big ? 8 : 2}
			>
				{children}
			</Container>
		</FlashMessage>
	);
}

function FlashMessageError({ children, ...rest }: PropsWithChildren<EmptyIntersection> & ComponentProps<typeof Box>) {
	return (
		<Box
			sx={{
				// TODO change default colors
				color: '#b48629',
				background: '#fff9df',
				borderBottomStyle: 'solid',
				borderBottomWidth: 1,
				borderBottomColor: '#ffe9aa',
				a: {
					color: '#b48629',
					textDecoration: 'underline',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:hover, &:focus': {
						color: '#dab65d',
					},
				},
			}}
			{...rest}
		>
			{children}
		</Box>
	);
}

function FlashMessageSuccess({ children, ...rest }: PropsWithChildren<EmptyIntersection> & ComponentProps<typeof Box>) {
	return (
		<Box
			sx={{
				// TODO change default colors
				color: '#497143',
				background: '#e2eee0',
				borderBottomColor: '#bbd6b2',
				a: {
					color: '#63985a',
					textDecoration: 'underline',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:hover, &:focus': {
						color: '#63985a',
					},
				},
			}}
			{...rest}
		>
			{children}
		</Box>
	);
}
