import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { MetaFacebookContainer } from './MetaFacebook';
import { MetaLocaleContainer } from './MetaLocale';
import { MetaUrlContainer } from './MetaUrl';

export function MetaContainer(): JSX.Element {
	return (
		<>
			<Helmet>
				<meta property="og:site_name" content="Change.org" />

				{/* Rate-limit the facebook crawler with a TTL of 28 days */}
				<meta property="og:ttl" content="2419200" />

				<meta name="twitter:site" content="@change" />
				<meta name="twitter:creator" content="@change" />
			</Helmet>
			<MetaLocaleContainer />
			<MetaUrlContainer />
			<MetaFacebookContainer />
		</>
	);
}
