import { useEffect, useMemo } from 'react';

import isEqual from 'lodash/fp/isEqual';
import keys from 'lodash/fp/keys';
import { useCustomCompareMemo } from 'use-custom-compare';

import { isLoaded } from 'src/app/shared/utils/async';

import { useShortUrlsState } from './context';

function isConfigsEqual(c1: Record<string, string>, c2: Record<string, string>): boolean {
	if (!isEqual(keys(c1), keys(c2))) {
		return false;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return keys(c1).every((key) => c1[key as never] === c2[key as never]);
}

export function useShortUrls<T extends Record<string, string>>(urlsMapArg: T): Readonly<Record<keyof T, string>> {
	const [state, { addToQueue }] = useShortUrlsState();

	// to avoid re-rendering when the object is new but the contents are the same
	const urlsMap = useCustomCompareMemo(
		() => urlsMapArg,
		[urlsMapArg],
		([c1], [c2]) => isConfigsEqual(c1, c2),
	);

	const longUrls = Object.values(urlsMap);

	const urlsToLoad = useMemo(() => longUrls.filter((url) => !state.cache[url]), [longUrls, state]);

	useEffect(() => {
		if (!urlsToLoad.length) return;
		addToQueue(urlsToLoad);
	}, [addToQueue, urlsToLoad]);

	return useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			Object.entries(urlsMap).reduce<Record<string, string>>((acc, [name, url]) => {
				const urlState = state.cache[url];
				// eslint-disable-next-line no-param-reassign
				acc[name] = (urlState && isLoaded(urlState) ? urlState.shortUrl : undefined) || url;
				return acc;
			}, {}) as Record<keyof T, string>,
		[urlsMap, state],
	);
}
