import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

/**
 * Displays a FB login window.
 *
 * TODO: should we move this logic to fe-core?
 */
export async function requestFacebookPermissions({
	loginOptions,
	context,
}: {
	loginOptions?: fb.LoginOptions;
	context: UtilityContext;
}): Promise<fb.AuthResponse | null> {
	const { facebook } = context;
	const { authResponse } = facebook;
	const rerequest = loginOptions?.auth_type === 'rerequest';
	if (authResponse && !rerequest) {
		return authResponse;
	}

	const loginResult = await facebook.login({
		scope: 'email,public_profile', // These are the default scopes. Requesting additional scopes will necessitate a FB app review.
		return_scopes: true,
		...loginOptions,
	});

	switch (loginResult.status) {
		case 'unknown':
			return null;
		case 'connected':
			// Try again if we don't have email.
			if (!rerequest && !loginResult.authResponse.grantedScopes?.includes('email'))
				return requestFacebookPermissions({ loginOptions: { ...loginOptions, auth_type: 'rerequest' }, context });

			return loginResult.authResponse;
		default:
			throw new Error('Facebook login failed');
	}
}
