import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		// old url from when we used team-based prefixes - should not be used anymore
		path: '/co/p/:slug/promote/thank_you',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { slug }, queryString }) => `/p/${slug!}/promote/thank_you${queryString}`,
		reason: 'deprecated-url',
	},
];
