import type { ComponentPropsWithoutRef, JSX } from 'react';

import { FcmStateContextProvider } from './context';
import { FcmCacheQueueProvider } from './queue';

export function FcmCacheProvider({
	children,
	...rest
}: ComponentPropsWithoutRef<typeof FcmStateContextProvider>): JSX.Element {
	return (
		<FcmStateContextProvider {...rest}>
			<FcmCacheQueueProvider>{children}</FcmCacheQueueProvider>
		</FcmStateContextProvider>
	);
}
