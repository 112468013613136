import { useCallback } from 'react';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';

import type { ReportType } from '../shared/types';

export function useCategoryLabel(
	type: ReportType,
	fieldType: 'category' | 'subcategory',
): (name: string, help?: boolean) => string {
	const { translate, translationExists } = useI18n();

	return useCallback(
		(name: string, help?: boolean) => {
			const suffix = `${fieldType === 'subcategory' ? 'abuse_sub_category' : 'abuse_category'}${
				help ? '_help' : ''
			}.${name}`;

			return translate(
				// necessary because only some labels have a specific label for non-petition reports
				translationExists(`fe.components.${type}_report_abuse.${suffix}`)
					? translationKey(`fe.components.${type}_report_abuse.${suffix}`)
					: translationKey(`fe.components.petition_report_abuse.${suffix}`),
			);
		},
		[type, fieldType, translate, translationExists],
	);
}
