import { createMandatoryContext } from '@change-corgi/core/react/context';

import type { L10nData } from './types';

export const {
	Context: L10nContext,
	Provider: L10nContextProvider,
	useContext: useL10n,
} = createMandatoryContext<L10nData>(undefined, {
	name: 'L10nContext',
});
