import { PX_FAKE_CHALLENGE } from 'src/app/shared/utils/perimeterx';

const { wrapFetch: wrapFetchForPerimeterX, decorateWindow: decorateWindowForPerimeterX } = (() => {
	const mockedPaths: Record<string, number> = {};

	const wrapFetch = (fetchParam: typeof fetch = fetch): typeof fetch => {
		return async (...args) => {
			const url = args[0];
			if (typeof url === 'string' && url.startsWith('/')) {
				try {
					const { pathname } = new URL(url, window.location.origin);
					if (mockedPaths[pathname]) {
						mockedPaths[pathname] -= 1;
						// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/consistent-type-assertions
						return {
							url,
							ok: false,
							status: 403,
							statusText: 'Forbidden',
							text: async () => Promise.resolve(JSON.stringify(PX_FAKE_CHALLENGE)),
							json: async () => Promise.resolve(PX_FAKE_CHALLENGE),
							type: 'default',
							headers: new Headers({
								// eslint-disable-next-line @typescript-eslint/naming-convention
								'Content-Type': 'application/json',
							}),
							redirected: false,
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
						} as any;
					}
				} catch (e) {
					// ignore
				}
			}

			return fetchParam(...args);
		};
	};

	const decorateWindow = () => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(window as any)._pxTest = (() => {
			const simulateChallengeForPath = (path: string, count = 1) => {
				mockedPaths[path] = count;
				// eslint-disable-next-line no-console
				console.log(`Simulating PerimeterX challenge for next ${count} call(s) of ${path}`);
			};
			const simulateChallengeForGqlPath = (path: string, count = 1) => {
				simulateChallengeForPath(`/api-proxy/graphql${path.replace(/^\/*/, '/')}`, count);
			};
			return {
				simulateChallengeForPath,
				simulateChallengeForGqlPath,
			};
		})();
	};

	return { wrapFetch, decorateWindow };
})();

export { wrapFetchForPerimeterX, decorateWindowForPerimeterX };
