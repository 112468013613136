import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		// old url from when we used team-based prefixes - should not be used anymore
		path: '/co/member/confirm',
		buildUrl: ({ queryString }) => `/member/confirm${queryString}`,
		reason: 'deprecated-url',
	},
];
