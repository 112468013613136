import { useCallback } from 'react';

import type { Options } from './PerimeterXCaptchaModal';
import { usePerimeterXCaptchaModal as usePerimeterXCaptchaModalBase } from './PerimeterXCaptchaModal';

export function usePerimeterXCaptcha(): (options: Options) => Promise<boolean> {
	const openModal = usePerimeterXCaptchaModalBase();

	return useCallback(
		async (options) => {
			const result = await openModal(options).waitForResult();
			return !!result?.success;
		},
		[openModal],
	);
}
