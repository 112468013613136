import type { PrefetchContext, PrefetchedDataProps } from '@change-corgi/core/react/prefetch';

import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchMembershipAwarenessBannerDataFromApi, useMembershipAwarenessBannerDataFromApi } from './data';
import type { MembershipAwarenessBannerFcmState } from './fcm';
import { prefetchMembershipAwarenessBannerFcm, useAsyncMembershipAwarenessBannerFcm } from './fcm';
import type { MembershipAwarenessBannerFcmExperimentsState } from './fcmExperiments';
import {
	prefetchMembershipAwarenessBannerFcmExperiments,
	useAsyncMembershipAwarenessBannerFcmExperiments,
} from './fcmExperiments';

export type MembershipAwarenessBannerPrefetchedContext = Readonly<{
	data: Parameters<typeof useMembershipAwarenessBannerDataFromApi>[0];
}>;

export type MembershipAwarenessBannerCombinedState = MergedState<
	ReturnType<typeof useMembershipAwarenessBannerDataFromApi>,
	MembershipAwarenessBannerFcmState,
	MembershipAwarenessBannerFcmExperimentsState
>;

export function useContext(
	prefetchedData?: MembershipAwarenessBannerPrefetchedContext,
): MembershipAwarenessBannerCombinedState {
	return useMergedStates(
		useMembershipAwarenessBannerDataFromApi(prefetchedData?.data),
		useAsyncMembershipAwarenessBannerFcm(),
		useAsyncMembershipAwarenessBannerFcmExperiments(),
	);
}

export async function prefetchContext(context: PrefetchContext): Promise<MembershipAwarenessBannerPrefetchedContext> {
	const [data] = await Promise.all([
		prefetchMembershipAwarenessBannerDataFromApi(context),
		prefetchMembershipAwarenessBannerFcm(context),
		prefetchMembershipAwarenessBannerFcmExperiments(context),
	]);

	return { data };
}

export type MembershipAwarenessBannerPrefetchedDataProps =
	PrefetchedDataProps<MembershipAwarenessBannerPrefetchedContext>;
