import type {
	PrefetchContext,
	PrefetchedDataProps,
	PrefetchedUserDataProps,
	PrefetchUserContext,
} from '@change-corgi/core/react/prefetch';

import { DECISION_MAKER_HASH_QUERY_PARAM_NAME } from 'src/app/pages/petition/details/shared/constants';
import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { useQueryString } from 'src/app/shared/hooks/location';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';
import { isLoaded } from 'src/app/shared/utils/async';
import { getQueryStringSingleValue } from 'src/app/shared/utils/query';

import { prefetchPetitionDetailsPageDataFromApi, usePetitionDetailsPageDataFromApi } from './pageData';
import { prefetchPetitionDetailsPageFcm, useAsyncPetitionDetailsPageFcm } from './pageFcm';
import type { PetitionDetailsPageUserData } from './pageUserData';
import { prefetchPetitionDetailsPageUserDataFromApi, usePetitionDetailsPageUserDataFromApi } from './pageUserData';

export type PetitionDetailsPagePrefetchedContext = Readonly<{
	pageData: Parameters<typeof usePetitionDetailsPageDataFromApi>[0];
}>;

export type PetitionDetailsPagePrefetchedUserContext = NonNullable<
	Parameters<typeof usePetitionDetailsPageUserDataFromApi>[0]
>;

export type PetitionDetailsPageCombinedState = MergedState<
	ReturnType<typeof usePetitionDetailsPageDataFromApi>,
	ReturnType<typeof usePetitionDetailsPageUserDataFromApi>,
	ReturnType<typeof useAsyncPetitionDetailsPageFcm>
>;

export function usePageContext(
	slug: string,
	prefetchedData?: PetitionDetailsPagePrefetchedContext,
	prefetchedUserData?: PetitionDetailsPagePrefetchedUserContext,
): PetitionDetailsPageCombinedState {
	const sessionState = useSessionAsync();
	const session = isLoaded(sessionState) ? sessionState.value : undefined;
	const query = useQueryString();

	return useMergedStates(
		usePetitionDetailsPageDataFromApi(prefetchedData?.pageData, slug, {
			countryCode: useCountryCode(),
			locale: useLocale(),
			loadBandits: !!session && session.loginState !== 'GUEST',
			sessionUser: session?.user,
			sessionUUID: session?.uuid,
			// we don't have access to the data at this point, so the API function will retrieve it if there's no prefetched data
			supporterVoicesConfig: undefined,
		}),
		usePetitionDetailsPageUserDataFromApi(prefetchedUserData, slug, {
			decisionMakerHash: getQueryStringSingleValue(query, DECISION_MAKER_HASH_QUERY_PARAM_NAME),
			isGuest: session?.loginState === 'GUEST',
			sessionUser: session?.user,
			sessionUUID: session?.uuid,
		}),
		useAsyncPetitionDetailsPageFcm(),
	);
}

export async function prefetchPageContext(
	slug: string,
	context: PrefetchContext,
): Promise<PetitionDetailsPagePrefetchedContext> {
	const { supporterVoicesEnabled, openVideoCreationFromPetitionDetails } =
		(await context.getUserData()) as unknown as PetitionDetailsPageUserData;

	const [pageData] = await Promise.all([
		prefetchPetitionDetailsPageDataFromApi(context, slug, {
			...context.l10n,
			loadBandits: !!context.session && context.session.loginState !== 'GUEST',
			supporterVoicesConfig: { supporterVoicesEnabled, openVideoCreationFromPetitionDetails },
			sessionUser: context.session?.user,
			sessionUUID: context.session?.uuid,
		}),
		prefetchPetitionDetailsPageFcm(context),
	]);

	return { pageData };
}

export async function prefetchPageUserContext(
	slug: string,
	context: PrefetchUserContext,
): Promise<PetitionDetailsPagePrefetchedUserContext> {
	return prefetchPetitionDetailsPageUserDataFromApi(context, slug, {
		decisionMakerHash: getQueryStringSingleValue(context.query, DECISION_MAKER_HASH_QUERY_PARAM_NAME),
		isGuest: context.session.loginState === 'GUEST',
		sessionUser: context.session.user,
		sessionUUID: context.session.uuid,
	});
}

export type PetitionDetailsPagePrefetchedDataProps = PrefetchedDataProps<PetitionDetailsPagePrefetchedContext> &
	PrefetchedUserDataProps<PetitionDetailsPagePrefetchedUserContext>;
