import { isLoading } from 'src/app/shared/utils/async';

import type { LogoVariation } from '../../types';

import { useLogoFcm } from './fcm';

export function useLogoVariation(): LogoVariation | undefined {
	const fcmState = useLogoFcm();
	if (isLoading(fcmState)) return undefined;
	if (fcmState.values.showBlackHistoryLogo) return 'blackHistory';
	if (fcmState.values.showPrideLogo) return 'pride';
	if (fcmState.values.showClimateStrikeLogo) return 'climateStrike';
	return undefined;
}
