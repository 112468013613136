import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	AdminEditTagsPageAddTagsToPetitionMutation,
	AdminEditTagsPageAddTagsToPetitionMutationVariables,
} from './addTagsToPetition.graphql';

// FIXME should return AdminEditTagsPageAddTagsToPetitionMutation['addTagsToPetition']
export async function addTagsToPetition(
	{ petitionId, tags }: AdminEditTagsPageAddTagsToPetitionMutationVariables,
	utilityContext: UtilityContext,
): Promise<AdminEditTagsPageAddTagsToPetitionMutation> {
	const {
		gql: { fetch },
	} = utilityContext;
	const { data, errors } = await fetch<
		AdminEditTagsPageAddTagsToPetitionMutation,
		AdminEditTagsPageAddTagsToPetitionMutationVariables
	>({
		query: gql`
			mutation AdminEditTagsPageAddTagsToPetition($petitionId: ID!, $tags: [String!]) {
				addTagsToPetition(input: { petitionId: $petitionId, tags: $tags }) {
					success
				}
			}
		`,
		variables: { petitionId, tags },
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from mutation.');

	return data;
}
