import { useMemo } from 'react';

import type { Sharer } from 'src/app/pages/petitionGamma/details/shared/types';

export function useHeadlineReplacementsSharer(sharer: Sharer | undefined): Record<string, string | number> {
	return useMemo(
		() => ({
			sharer_name: sharer?.displayName || '',
			sharer_name_short: sharer?.shortDisplayName || '',
			sharer_location: sharer?.formattedLocationString || '',
			preferredName: sharer?.shortDisplayName || '',
			preferred_name: sharer?.shortDisplayName || '',
		}),
		[sharer],
	);
}
