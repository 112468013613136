import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'racial-justice-fund',
		path: '/racial-justice-fund',
		component: loadable(async () => import(/* webpackChunkName: "pages/racialJusticeFund" */ './index'), {
			exportName: 'RacialJusticeFund',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			seo: true,
			config: {
				disallowUserData: true,
				cache: {
					enabled: true,
					lifetimeInSeconds: 30 * 60,
				},
			},
			enabled: true,
		},
	},
];
