import type { JSX, PropsWithChildren, ReactElement } from 'react';
import { Children, cloneElement, Fragment } from 'react';

import flatMap from 'lodash/flatMap';

type Props = {
	separator?: ReactElement | string;
};

/**
 * Joins children with a given separator
 */
export function Join({ separator, children }: PropsWithChildren<Props>): JSX.Element {
	if (!separator) return <>{children}</>;

	const childrenArray = Children.toArray(children);

	return (
		<>
			{flatMap(childrenArray, (child, index) => {
				if (index === childrenArray.length - 1) return [child];
				if (typeof separator === 'string') {
					return [child, <Fragment key={`join-separator-${index}`}>{separator}</Fragment>];
				}
				return [child, cloneElement(separator, { key: `join-separator-${index}` })];
			})}
		</>
	);
}
