import { createContext } from 'react';

import type { DLocalSDK, SmartFields } from 'src/app/shared/utils/payments';

export type DLocalStatus = 'loading' | 'error' | 'ready';

export type DLocalInstance = {
	status: DLocalStatus;
	sdk: DLocalSDK | null;
	smartFields: SmartFields | null;
};

export const DLocalContext = createContext<DLocalInstance>({ status: 'loading', sdk: null, smartFields: null });
