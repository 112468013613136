import { useI18n } from '@change-corgi/core/react/i18n';

type Result = ReadonlyArray<{ amount: number; amountLocalized: string }>;

type Props = {
	yearlyContributionsExpVariation: string;
	yearlyContributionsAmounts:
		| {
				yearly: readonly number[];
				monthly: readonly number[] | undefined;
				one_time: readonly number[] | undefined;
		  }
		| undefined;
	paymentTerm: string;
	buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string }>;
	currency: string;
};

export function useYearlyMembershipExperimentAmounts(): ({
	yearlyContributionsExpVariation,
	yearlyContributionsAmounts,
	paymentTerm,
	buttonAmounts,
	currency,
}: Props) => Result {
	const { localizeCurrency } = useI18n();
	return function yearlyMembershipExperimentAmounts({
		yearlyContributionsExpVariation,
		yearlyContributionsAmounts,
		paymentTerm,
		buttonAmounts,
		currency,
	}: Props): Result {
		if (yearlyContributionsExpVariation !== 'control') {
			if (paymentTerm === 'yearly' && yearlyContributionsAmounts?.yearly) {
				return yearlyContributionsAmounts.yearly.map((amount) => {
					return {
						amount,
						amountLocalized: localizeCurrency(amount, currency, { amountInBaseUnits: false }),
					};
				});
			}

			if (paymentTerm === 'monthly' && yearlyContributionsAmounts?.monthly) {
				return yearlyContributionsAmounts.monthly.map((amount) => {
					return {
						amount,
						amountLocalized: localizeCurrency(amount, currency, { amountInBaseUnits: false }),
					};
				});
			}

			if (paymentTerm === 'one-time' && yearlyContributionsAmounts?.one_time) {
				return yearlyContributionsAmounts.one_time.map((amount) => {
					return {
						amount,
						amountLocalized: localizeCurrency(amount, currency, { amountInBaseUnits: false }),
					};
				});
			}
		}
		return buttonAmounts;
	};
}
