import type { JSX } from 'react';

import { Field } from 'react-final-form';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Textarea } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';
import { requiredValidator } from 'src/app/shared/form/validators';

import type { FormValues, ReportType } from '../shared/types';

type Props = Readonly<{
	type: ReportType;
}>;

const validator = requiredValidator({
	i18n: { key: translationKey('fe.components.petition_report_abuse.errors.reason_required') },
});

// false positive eslint bug
// eslint-disable-next-line react/display-name
export function ReasonField({ type }: Props): JSX.Element {
	const { translate } = useI18n();
	const { getErrorMessage } = useFormI18n();

	return (
		<Box mb={8}>
			<Field<FormValues['reason'] | undefined> name="reason" validate={validator}>
				{({ input, meta }) => (
					<Textarea
						id="report-abuse-reason"
						rows={4}
						name={input.name}
						value={input.value}
						onChange={input.onChange}
						onFocus={input.onFocus}
						onBlur={input.onBlur}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						placeholder={translate(`fe.components.${type}_report_abuse.form_placeholder`)}
						aria-label={translate(`fe.components.${type}_report_abuse.form_placeholder`)}
						label={translate(`fe.components.${type}_report_abuse.form_placeholder`)}
						data-testid="reportAbuse-reason-input"
					/>
				)}
			</Field>
		</Box>
	);
}
