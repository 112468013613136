import type { ParsedQs } from 'qs';

import type { AppRouteBeforeServerRenderContext } from 'src/shared/routes';

import { getPetitionId } from 'src/app/shared/api/petitions';
import { isPetitionId } from 'src/app/shared/utils/petition';

import { setPetitionRecruiter } from './api/setRecruiter';

function isValidRecruiterId(recruiterId: string | undefined) {
	return !!recruiterId && recruiterId !== 'false';
}

function onlyIfString(queryParam: string | string[] | ParsedQs | ParsedQs[] | undefined): string | undefined {
	if (typeof queryParam !== 'string') {
		return undefined;
	}
	return queryParam;
}

/**
 * This sets the recruiter information in the session
 * so it can be retrieved when signing, when tracking events, or inside Optimizely experiments
 *
 * This needs to be called before any attempt to retrieve session data,
 * otherwise that data will contain the previous recruiter tracking data
 */
export async function handleRecruiter(
	{ query, utilities, getSessionUtilities }: AppRouteBeforeServerRenderContext,
	slugOrId: string,
): Promise<void> {
	const recruiterId = onlyIfString(query.recruiter);
	const recruiterUuid = onlyIfString(query.recruited_by_id);
	const recruitSource = onlyIfString(query.utm_source);
	const recruitMedium = onlyIfString(query.utm_medium);

	if (!isValidRecruiterId(recruiterId) && !recruiterUuid) return;

	// FIXME do id resolution in graphql?
	const petitionId = isPetitionId(slugOrId) ? slugOrId : await getPetitionId(slugOrId, utilities);

	if (!petitionId) return;

	await setPetitionRecruiter(
		{
			petitionId,
			recruiterId: (isValidRecruiterId(recruiterId) && recruiterId) || null,
			recruiterUuid: recruiterUuid || null,
			recruitSource: recruitSource || null,
			recruitMedium: recruitMedium || null,
		},
		await getSessionUtilities(),
	);
}
