import type { JSX } from 'react';

import { Image } from '@change-corgi/design-system/components/media';

import { Photo } from './Photo';
import type { Media as MediaProp } from './types';
import { Video } from './Video';

type MediaProps = {
	media?: MediaProp;
	shape: 'rectangle' | 'square';
};

// TODO allow passing loading=lazy for underlying Photo
// TODO allow passing responsive src for underlying Photo
export function Media({ media, shape }: MediaProps): JSX.Element | null {
	const aspectRatio = shape === 'square' ? '1/1' : '16/9';

	if (media?.type === 'photo')
		return <Photo src={media.url} processing={media.processing} aspectRatio={aspectRatio} alt="" />;

	if (media?.type === 'video')
		return <Video html={media.html} aspectRatio={aspectRatio} backgroundImage={media.url} alt="" />;

	return (
		<Image
			src="https://static.change.org/images/default-petition-darker.gif"
			fit="contain"
			sx={{ aspectRatio, backgroundColor: '#ebebeb', verticalAlign: 'top', height: '100%' }}
			alt=""
			loading="lazy"
		/>
	);
}
