import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useSession, useSessionAsync } from './session';
import type { AsyncState } from './shared/types';

export function useCookiePrefsSaved(): boolean {
	return useSession().cookiePrefsSaved;
}
export function useCookiePrefsSavedAsync(): AsyncState<boolean> {
	return useMappedLoadedState(useSessionAsync(), ({ value }) => ({ value: value.cookiePrefsSaved }));
}
