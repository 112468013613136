import type { JSX, PropsWithChildren } from 'react';

import { useApiPetitionStats } from './apiPetitionStats';
import { PetitionStatsContext } from './context';

type Props = Readonly<{
	slug: string;
}>;

export function PetitionStatsContextProvider({ slug, children }: PropsWithChildren<Props>): JSX.Element | null {
	const petitionStatsState = useApiPetitionStats(slug);

	return <PetitionStatsContext.Provider value={petitionStatsState}>{children}</PetitionStatsContext.Provider>;
}
