import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		// old url used for caching - should not be used anymore
		path: '/pc/:slug',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { slug }, queryString }) => `/p/${slug!}${queryString}`,
		reason: 'deprecated-url',
	},
	// old signer dashboard
	{
		path: '/p/:slug/f',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { slug }, queryString }) => `/p/${slug!}${queryString}`,
		reason: 'deprecated-url',
	},
	{
		path: '/su/p/:slug/f',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { slug }, queryString }) => `/p/${slug!}${queryString}`,
		reason: 'deprecated-url',
	},
];
