export function hideElement(elt: HTMLElement): () => void {
	// eslint-disable-next-line no-param-reassign
	elt.style.display = 'none';
	return () => unhideElement(elt);
}

function unhideElement(elt: HTMLElement) {
	// eslint-disable-next-line no-param-reassign
	elt.style.display = '';
}
