import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'array',
	shape: {
		type: 'object',
		shape: {
			name: { type: 'string' },
			constraints: {
				type: 'array',
				shape: { type: 'string' },
			},
			variants: {
				type: 'record',
				shape: {
					type: 'object',
					shape: {
						validationRules: { type: 'string', optional: true },
						anchorAmount: { type: 'number', optional: true },
						thresholdAmount: { type: 'number' },
						cpmBelowThreshold: { type: 'number', optional: true },
						cpmAboveThreshold: { type: 'number', optional: true },
						supportersText: { type: 'string', optional: true },
						bonusPromotionsText: { type: 'string', optional: true },
						promotionCalculatorText: { type: 'string', optional: true },
					},
				},
			},
		},
	},
});

export const FCM_CREAM_BONUS_PROMOTIONS_EXPERIMENTS = createFcmConfig('cream_bonus_promotions_experiments', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: [undefined],
});
