import { PageDataError } from 'src/shared/error';

export function pageDataErrorHandler<CODE extends string>(): (error: unknown) => {
	error: CODE;
	message?: string;
	cause?: unknown;
} {
	return (error: unknown) => {
		if (error instanceof PageDataError) {
			return {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: error.code as CODE,
				...(error.message ? { message: error.message } : {}),
				...(error.cause ? { cause: error.cause } : {}),
			};
		}
		throw error;
	};
}
