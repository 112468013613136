/* eslint-disable no-param-reassign */
import type { FeatureConfigInfo } from '@change-corgi/core/fcm';

import type { FcmCacheState } from './types';

// mutations are mutating the state contents to conserve the reference and avoid triggering unnecessary re-renders
// although we're still returning a new state reference to trigger the react lifecycle
export const mutations = {
	addToQueue: (state: FcmCacheState, configs: readonly FeatureConfigInfo[]): FcmCacheState => {
		const filteredConfigs = configs.filter((config) => !state.cache[config.name] && !state.queue.includes(config));
		filteredConfigs.forEach(({ name }) => {
			state.cache[name] = { status: 'loading' };
		});

		if (!filteredConfigs.length) return state;

		state.queue.push(...filteredConfigs);

		return { ...state };
	},
	removeFromQueue: (state: FcmCacheState, configs: readonly FeatureConfigInfo[]): FcmCacheState => {
		if (!state.queue.length || !configs.length) return state;

		configs.forEach((config) => {
			const index = state.queue.indexOf(config);
			if (index === -1) return;
			state.queue.splice(index, 1);
		});

		return { ...state };
	},
	setLoaded: (state: FcmCacheState, values: Record<string, unknown>): FcmCacheState => {
		if (!Object.entries(values).length) return state;

		Object.entries(values).forEach(([name, value]) => {
			state.cache[name] = { status: 'loaded', value };
		});

		return { ...state };
	},
};
