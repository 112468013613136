import { useMemo } from 'react';

import type { StateWithoutError } from 'src/app/shared/utils/async';
import { isLoading } from 'src/app/shared/utils/async';

import type { ShareChannel } from '../shared/types';

import { useAsyncAvailableShareChannels } from './async';
import { useBaseAvailableShareChannels } from './base';
import { useSyncAvailableShareChannels } from './sync';

export function useShareChannelsAvailability(): Readonly<Record<ShareChannel, boolean>> {
	const base = useBaseAvailableShareChannels();
	const sync = useSyncAvailableShareChannels();

	return useMemo(
		() => ({
			...base,
			...sync,
		}),
		[base, sync],
	);
}

type AsyncResult = StateWithoutError<{ channels: Readonly<Record<ShareChannel, boolean>> }>;

export function useShareChannelsAvailabilityAsync(): AsyncResult {
	const base = useBaseAvailableShareChannels();
	const async = useAsyncAvailableShareChannels();

	return useMemo(() => {
		if (isLoading(async)) return async;

		return {
			status: 'loaded',
			channels: {
				...base,
				...async.channels,
			},
		};
	}, [base, async]);
}
