import { normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PROMOTIONS_INELIGIBLE_PETITION_REFUNDS_ENABLED = createFcmConfig(
	'promotions_ineligible_petition_refunds_enabled',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
);
