import type { JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { SignFormContext } from './context';

type Props = {
	insideMobileModal: boolean;
};

export function SignFormContextProvider({ insideMobileModal, children }: PropsWithChildren<Props>): JSX.Element {
	const value = useMemo(() => ({ insideMobileModal }), [insideMobileModal]);

	return <SignFormContext.Provider value={value}>{children}</SignFormContext.Provider>;
}
