import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

export type SignWithEmailButtonClickTrackingResult = Readonly<{
	trackSignWithEmailClick: () => void;
}>;

export function useEmailButtonClickTracking(): SignWithEmailButtonClickTrackingResult {
	const track = useTracking();

	return useMemo(
		() => ({
			trackSignWithEmailClick: () => {
				void track('sign_with_email_button_click');
			},
		}),
		[track],
	);
}
