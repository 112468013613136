import type { I18nFieldValidator, NonI18nFieldValidator, WithI18nConfig, WithNonI18nConfig } from './shared/types';

export function maxLengthValidator({
	i18n,
	length,
}: WithI18nConfig<{ length: number }>): I18nFieldValidator<string | undefined> {
	return (value) => (!value || value.length <= length ? undefined : i18n);
}

export function nonI18nMaxLengthValidator({
	message,
	length,
}: WithNonI18nConfig<{ length: number }>): NonI18nFieldValidator<string | undefined> {
	return (value) => (!value || value.length <= length ? undefined : message);
}
