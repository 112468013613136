import { useCallback, useState } from 'react';

import { useSendVerificationEmail } from '../../../../../../useSendVerificationEmail';

type Result = ModelHookResult<
	{
		resendEmailResult: undefined | 'error' | 'success';
	},
	{
		resendVerificationEmail: () => Promise<void>;
	}
>;

export function useEmailVerificationModalBody(path: string, userId: string): Result {
	const [resendEmailResult, setResendEmailResult] = useState<undefined | 'error' | 'success'>(undefined);
	const resendVerificationEmail = useSendVerificationEmail();

	return {
		data: {
			resendEmailResult,
		},
		actions: {
			resendVerificationEmail: useCallback(async () => {
				setResendEmailResult(undefined);
				setResendEmailResult((await resendVerificationEmail(path, userId)) ? 'success' : 'error');
			}, [resendVerificationEmail, path, userId]),
		},
	};
}
