import { focus } from 'src/app/shared/utils/dom';

export function focusMainContent(): void {
	focus(
		document.querySelector<HTMLElement>('[data-maincontent]') ||
			document.querySelector<HTMLElement>('[data-maincontent-principal]'),
		{
			makeFocusable: true,
		},
	);
}
