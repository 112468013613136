import { useCallback } from 'react';

import type { ShareChannel } from '../shared/types';

import { useShareShortUrls } from './shortUrls';

type Options<CH extends ShareChannel = ShareChannel> = {
	disableShortLinks?: boolean;
	getUrl: (channel: CH) => string;
};

export function useGetShareShortUrl<CH extends ShareChannel = ShareChannel>(
	channels: readonly CH[],
	options: Options<CH>,
): (channel: CH) => string {
	const shortUrls: Partial<Record<CH, string>> = useShareShortUrls(channels, options);

	return useCallback(
		(channel: CH) => {
			const url: string | undefined = shortUrls[channel];
			if (!url) {
				throw new Error(`Missing share url for ${channel}`);
			}
			return url;
		},
		[shortUrls],
	);
}
