import Cookies from 'js-cookie';
import qs from 'qs';

import { isTrackingAllowed } from '@change-corgi/config/cookiePrefs';
import { isGdprCountry } from '@change-corgi/config/countries';
import type { EventTrackingDecorator } from '@change-corgi/core/eventTracker';
import type { Facebook } from '@change-corgi/core/facebook';
import type { Session } from '@change-corgi/core/session';
import { getLocation } from '@change-corgi/core/window';

// temporary function to try and identify when users might have the FB autologin be attempted
// which could result in a session change (and mess up tracking)
// the cookie wall needs to be taking into account as it could trigger an autologin
// we're also ignoring identified=>authenticating as that doesn't result in a uuid change
async function isGuestFbAutologinPossible({
	session: {
		loginState,
		cookiePrefs,
		cookiePrefsSaved,
		country: { countryCode },
	},
	facebook,
}: {
	session: Session;
	facebook: Facebook;
}): Promise<boolean> {
	if (loginState !== 'GUEST') return false;
	if (!isTrackingAllowed(cookiePrefs, 'facebook')) {
		// if tracking is not allowed for FB, we won't try to autologin, and if cookie prefs are saved, we won't even show the cookie wall
		if (cookiePrefsSaved) return false;
		// if cookie prefs are not saved, but the country is not GDPR, we won't show the cookie wall
		if (!isGdprCountry(countryCode)) return false;
	}
	if (
		!qs.parse(getLocation().search, { ignoreQueryPrefix: true }).fb_authed &&
		Cookies.get('dont_auto_login_via_facebook')
	)
		return false;
	// if tracking is not allowed, we cannot check the auth status using the FB SDK, so we assume that auto-login is possible
	if (isTrackingAllowed(cookiePrefs, 'facebook')) {
		// make sure that FB SDK is loaded
		await facebook.load();
		if (!facebook.isConnected || !facebook.authResponse) return false;
	}
	return true;
}

class FacebookDecorator implements EventTrackingDecorator {
	private session: Session;
	private facebook: Facebook;

	constructor({ session, facebook }: { session: Session; facebook: Facebook }) {
		this.session = session;
		this.facebook = facebook;
	}

	async getEventTrackingData(): Promise<Readonly<Record<string, unknown>>> {
		return {
			guest_fb_autologin_possible: await isGuestFbAutologinPossible({ session: this.session, facebook: this.facebook }),
		};
	}
}

export function createFacebookDecorator(utils: { session: Session; facebook: Facebook }): FacebookDecorator {
	return new FacebookDecorator(utils);
}
