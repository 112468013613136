import { isActionsPageEnabled } from 'src/app/shared/components/petition';

import { useFcm } from './fcm';
import { usePetitionDetails } from './petitionDetails';

export function useCampaignActionsEnabled(): boolean {
	const { campaignActionsPageConfig } = useFcm();
	const { id } = usePetitionDetails();

	return isActionsPageEnabled(campaignActionsPageConfig, id);
}
