import type { Simplify } from 'type-fest';

import type { FcmConfig, Merger, SimplifyWhenEqual } from './types';

function createFcmConfig<N extends string, T, D>(
	name: N,
	config: Omit<FcmConfig<T, D>, 'name' | 'merger'>,
): Simplify<Readonly<{ name: N } & Omit<FcmConfig<T, D>, 'name' | 'merger'>>>;
function createFcmConfig<N extends string, T, D, A extends { merger: Merger<SimplifyWhenEqual<T, D>> }>(
	name: N,
	config: Omit<FcmConfig<T, D>, 'name' | 'merger'>,
	additionalConfig: A,
): Simplify<
	Readonly<
		{ name: N } & Omit<FcmConfig<T, D>, 'name' | 'merger'> & {
				// necessary for proper return type, otherwise "readonly" might be removed for instance
				merger: (values: Parameters<A['merger']>[0]) => SimplifyWhenEqual<T, D>;
			}
	>
>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
function createFcmConfig(name: string, config: any, additionalConfig: any = {}): any {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return { name, ...config, ...additionalConfig };
}

export { createFcmConfig };
