import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'decision-maker-responses',
		path: '/dashboard/decision_makers/responses',
		component: loadable(async () => import(/* webpackChunkName: "pages/decision_maker_responses" */ './index'), {
			exportName: 'DecisionMakerResponses',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['staff', 'support'],
		},
		frame: true,
		ssr: {
			enabled: false,
			seo: false,
		},
	},
];
