/**
 * Returns the difference in days
 *
 * @example
 * differenceInDays(new Date('2020-01-02'), new Date('2020-01-01')) === 1
 */
export function differenceInDays(d1: Date, d2: Date): number {
	return Math.floor(
		(Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate()) -
			Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate())) /
			(1000 * 60 * 60 * 24),
	);
}
