import type { PropsWithChildren } from 'react';

import { isLoaded } from 'src/app/shared/utils/async';

import { MembershipReminderPopupDataContextProvider } from './data';
import { MembershipReminderPopupFcmContextProvider } from './fcm';
import { MembershipReminderPopupFcmExperimentsContextProvider } from './fcmExperiments';
import type { MembershipReminderPopupPrefetchedContext } from './hook';
import { useContext } from './hook';

type Props = {
	prefetchedData: MembershipReminderPopupPrefetchedContext | undefined;
};

export function MembershipReminderPopupContextProvider({
	children,
	prefetchedData,
}: PropsWithChildren<Props>): React.JSX.Element | null {
	const contextState = useContext(prefetchedData);
	// not displaying a loader when in a loading state because it might result in nothing
	if (!isLoaded(contextState)) return null;

	const { data: componentData, fcm, fcmExperiments } = contextState;

	return (
		<MembershipReminderPopupDataContextProvider data={componentData}>
			<MembershipReminderPopupFcmContextProvider fcm={fcm}>
				<MembershipReminderPopupFcmExperimentsContextProvider fcmExperiments={fcmExperiments}>
					{children}
				</MembershipReminderPopupFcmExperimentsContextProvider>
			</MembershipReminderPopupFcmContextProvider>
		</MembershipReminderPopupDataContextProvider>
	);
}
