import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { LikeSupporterVideoMutation, LikeSupporterVideoMutationVariables } from './index.graphql';

export const likeSupporterVideo = async ({
	videoId,
	like,
	utilityContext: {
		gql: { fetch },
	},
}: {
	videoId: string;
	like: boolean;
	utilityContext: UtilityContext;
}): Promise<LikeSupporterVideoMutation | null | undefined> => {
	const { data: likeResult } = await fetch<LikeSupporterVideoMutation, LikeSupporterVideoMutationVariables>({
		query: gql`
			mutation LikeSupporterVideo($input: LikeCommentInput!) {
				likeComment(input: $input) {
					success
					liked
					reason
				}
			}
		`,
		variables: {
			input: {
				commentId: videoId,
				like,
			},
		},
	});
	return likeResult;
};
