import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-subscription-offers-programs',
		path: '/dashboard/offers',
		component: loadable(
			async () => import(/* webpackChunkName: "pages/admin_subscription_offers_programs" */ './index'),
			{
				exportName: 'AdminSubscriptionOffersPrograms',
				fallback: Loading,
			},
		),
		restrictedAccess: {
			authOnly: true,
			role: ['staff'],
		},
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
