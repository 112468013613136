import { CustomError } from 'ts-custom-error';

import type { SapCreatePublishedPetitionMutation } from './createPublishedPetition.graphql';

type ResponseError = Exclude<
	SapCreatePublishedPetitionMutation['createPublishedPetition'],
	{ __typename: 'CreatePublishedPetitionSuccess' }
>['__typename'];

type Error = ResponseError | 'NoResponseData' | 'InvalidInput' | 'UnknownError';

type ErrorTypes = Record<Error, string>;

const ERROR_TYPES = {
	CreatePublishedPetitionCreateError: 'sap_api_create_failed',
	CreatePublishedPetitionUnauthorizedError: 'sap_api_session_timeout',
	CreatePublishedPetitionUnexpectedError: 'sap_api_internal_server_error',
	InvalidInput: 'sap_api_validation_failed',
	NoResponseData: 'sap_api_no_response_data',
	UnknownError: 'sap_api_unknown_error',
} satisfies ErrorTypes;

export class SapPetitionSaveError extends CustomError {
	readonly code?: string;

	constructor(error?: Error, options?: ErrorOptions) {
		super(error ? `SAP: Failed to save petition: ${ERROR_TYPES[error]}` : 'SAP: Failed to save petition', options);
		this.code = error && ERROR_TYPES[error];
	}
}
