import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { SignPetitionInput, SignPetitionResult, SignPetitionShareBandit } from 'src/app/shared/api/signature';
import { createSignature } from 'src/app/shared/api/signature';

import { useTrackingSignData } from './signData/trackingSignData';
import { useUserAgentSignData } from './signData/userAgentSignData';

export type SignPetitionHookInput = Omit<
	SignPetitionInput,
	| 'petitionId'
	| 'pageContext'
	| 'recentlySeenMembershipRequest'
	| keyof ReturnType<typeof useTrackingSignData>
	| keyof ReturnType<typeof useUserAgentSignData>
>;
export type SignPetitionHookResult = SignPetitionResult;

type HookOptions = {
	/**
	 * enables bandits tracking based on "current_content"
	 */
	bandits?: boolean;
	pageContext: string;
};

type Options = {
	signature: SignPetitionHookInput;
	shareBandit?: SignPetitionShareBandit;
};

export function useSignPetition(
	petitionId: string,
	{ bandits, pageContext }: HookOptions,
): (options: Options) => Promise<SignPetitionResult> {
	const utilityContext = useUtilityContext();

	const trackingData = useTrackingSignData({ bandits });
	const userAgentData = useUserAgentSignData();

	return useCallback(
		async ({ signature, shareBandit }) => {
			const result = await createSignature(
				{
					signature: {
						petitionId,
						pageContext,
						...trackingData,
						...userAgentData,
						...signature,
					},
					shareBandit,
				},
				utilityContext,
			);

			return result;
		},
		[petitionId, utilityContext, trackingData, userAgentData, pageContext],
	);
}
