import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { isDecisionMaker } from './decisionMaker';
import { isGdprConsentProvided } from './gdprConsentProvided';

export type PetitionDetailsPageUserData = {
	decisionMaker: boolean;
	gdprConsentProvided: boolean;
};

export async function getPetitionDetailsPageUserData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{ decisionMakerHash, isGuest }: { decisionMakerHash?: string; isGuest: boolean },
): Promise<PetitionDetailsPageUserData> {
	const [decisionMaker, gdprConsentProvided] = await Promise.all([
		!isGuest || decisionMakerHash ? isDecisionMaker(utilityContext, { slugOrId, decisionMakerHash }) : false,
		isGdprConsentProvided(utilityContext),
	]);

	return {
		decisionMaker,
		gdprConsentProvided,
	};
}
