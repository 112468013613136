import type { FrameConfig } from 'src/shared/routes';

import type { DefaultLayoutProps } from './types';

/**
 * Translate a route's FrameConfig into the props that should be passed to the DefaultLayout that wraps the page.
 * TODO(CHANGE-68189): This is using the legacy "small" width by default. We want to switch this be "large" by default
 * (or deprecate the option if we can force consistency across all pages).
 */
export function getLayoutProps(frame: FrameConfig | undefined): DefaultLayoutProps {
	if (frame === true) {
		return {
			hideFooter: false,
			hideHeader: false,
			width: 'small',
		};
	}

	if (typeof frame === 'object') {
		return {
			hideFooter: !frame.footer,
			hideHeader: !frame.header,
			width: frame.width ?? 'small',
		};
	}

	return {
		hideFooter: true,
		hideHeader: true,
		width: 'small',
	};
}
