type Listener = () => void;

class StorageEventsHandler {
	private listeners = new Map<string, Set<Listener>>();

	subscribe(key: string, listener: Listener): () => void {
		let set = this.listeners.get(key);
		if (!set) {
			set = new Set<Listener>();
			this.listeners.set(key, set);
		}
		set.add(listener);

		return () => {
			this.unsubscribe(key, listener);
		};
	}

	unsubscribe(key: string, listener: Listener): void {
		const set = this.listeners.get(key);
		set && set.delete(listener);
	}

	trigger(key: string): void {
		const set = this.listeners.get(key);
		set &&
			set.forEach((listener) => {
				listener();
			});
	}

	triggerAll(): void {
		for (const key of this.listeners.keys()) {
			this.trigger(key);
		}
	}
}

export type { StorageEventsHandler };

export function createStorageEventsHandler(): StorageEventsHandler {
	return new StorageEventsHandler();
}
