import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'new-petition',
		path: '/v2/p/:slug',
		component: loadable(async () => import(/* webpackChunkName: "pages/new-petition" */ './index'), {
			exportName: 'Petition',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
		frame: {
			header: true,
			footer: false,
		},
	},
];
