import type { ComponentPropsWithoutRef, JSX } from 'react';

import { ShortUrlsStateContextProvider } from './context';
import { ShortUrlsCacheQueueProvider } from './queue';

export function ShortUrlsCacheProvider({
	children,
	...rest
}: ComponentPropsWithoutRef<typeof ShortUrlsStateContextProvider>): JSX.Element {
	return (
		<ShortUrlsStateContextProvider {...rest}>
			<ShortUrlsCacheQueueProvider>{children}</ShortUrlsCacheQueueProvider>
		</ShortUrlsStateContextProvider>
	);
}
