import type { JSX } from 'react';

import type { ValidationErrors } from 'final-form';
import { FormSpy } from 'react-final-form';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { InlineMessage } from '@change-corgi/design-system/components/alerts';

import { useFormI18n } from 'src/app/shared/form/hooks';

type Props = Readonly<{ errors: ValidationErrors }>;

function InvalidFormErrorMessage({ errors }: Props): JSX.Element | null {
	const { getErrorMessage } = useFormI18n();

	if (!errors) return null;

	return (
		/*
		 * Hiding the list of errors for sighted users as they can see those errors in the form already.
		 * TODO: Ultimately, we might want to display at least the "form is invalid" message for sighted users, once it is translated. The list of errors could stay visually hidden to avoid displaying too much content.
		 */
		<VisuallyHidden>
			<InlineMessage size="large" role="alert" variant="error">
				<Translate value="corgi.components.form.invalid.alert" />
				<ul>
					{Object.entries(errors).map(([name, error]) => (
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						<li key={name}>{getErrorMessage(error)}</li>
					))}
				</ul>
			</InlineMessage>
		</VisuallyHidden>
	);
}

export function InvalidFormError(): JSX.Element {
	return (
		<FormSpy
			subscription={{
				hasValidationErrors: true,
				errors: true,
				modifiedSinceLastSubmit: true,
				submitFailed: true,
			}}
		>
			{({ hasValidationErrors, errors, submitFailed, modifiedSinceLastSubmit }) =>
				/*
				 * this results in the following behavior:
				 * - message is not visible unless form has been submitted at least once
				 * - message is not visible unless there are validation errors in the form
				 * - message is hidden as soon as any field is modified
				 * - message will reappear once the form is re-submitted and there are still validation errors
				 */
				hasValidationErrors && submitFailed && !modifiedSinceLastSubmit ? (
					<InvalidFormErrorMessage errors={errors} />
				) : null
			}
		</FormSpy>
	);
}
