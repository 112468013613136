import { Translate } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconCheck } from '@change-corgi/design-system/icons';
import { createModalHook } from '@change-corgi/design-system/modals';
import { Text } from '@change-corgi/design-system/typography';

export const useReportAbuseConfirmModal = createModalHook({
	name: 'ReportAbuseConfirmModal',
	title: () => <Translate value="fe.components.petition_report_abuse.form_title" />,
	body: function ReportAbuseConfirmModalBody() {
		return (
			<>
				<Icon icon={iconCheck} size="m" mr={8} sx={{ transform: 'translateY(2px)' }} />
				<Text>
					<Translate value="fe.components.petition_report_abuse.form_confirmation" />
				</Text>
			</>
		);
	},
});
