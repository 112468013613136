import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { LoginOrSignupWithFacebookContext } from 'src/app/shared/api/auth';
import { useQueryParamValue } from 'src/app/shared/hooks/location';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoading } from 'src/app/shared/utils/async';

export function useGetFacebookAutoLoginContext(): () => Promise<LoginOrSignupWithFacebookContext | undefined> {
	const fbAuthed = useQueryParamValue('fb_authed');
	const sessionState = useSessionAsync();
	const { facebook, cookies } = useUtilityContext();

	// eslint-disable-next-line complexity
	return useCallback(async () => {
		if (isLoading(sessionState)) {
			return undefined;
		}

		const { loginState, user } = sessionState.value;

		if (loginState === 'AUTHENTICATED') return undefined;
		if (!fbAuthed && cookies.get('dont_auto_login_via_facebook')) return undefined;
		// make sure that FB SDK is loaded
		await facebook.load();

		const fbResponse = facebook.authResponse;

		// with FB Login for Business, accessToken and signedRequest can be undefined (https://github.com/DefinitelyTyped/DefinitelyTyped/pull/66759)
		if (!facebook.isConnected || !fbResponse || !fbResponse.accessToken || !fbResponse.signedRequest) return undefined;

		if (loginState === 'IDENTIFIED') {
			if (!user?.facebookHash || user?.facebookHash !== fbResponse.userID) {
				return undefined;
			}
		}

		return {
			accessToken: fbResponse.accessToken,
			signedRequest: fbResponse.signedRequest,
		};
	}, [fbAuthed, cookies, facebook, sessionState]);
}
