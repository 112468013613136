import { useMemo } from 'react';

import type { StateWithoutError } from 'src/app/shared/utils/async';
import { isLoaded, isLoading } from 'src/app/shared/utils/async';

import { useAvailableShareChannels, useAvailableShareChannelsAsync } from '../availableChannels';
import type { ShareChannel } from '../shared/types';
import { useGetShareShortUrl } from '../urls';

import { useShareActions } from './actions';

export type ShareAction<CH extends ShareChannel = ShareChannel> = {
	channel: CH;
	url: string;
	shortUrl?: string;
	action: () => Promise<boolean>;
};

export type Options<CH extends ShareChannel = ShareChannel> = {
	disableShortLinks?: boolean;
	trackingEventName: string;
	getUrl: (channel: CH) => string;
	getText: (channel: CH, type: 'title' | 'body', context: { url: string }) => string;
	getTrackingData: (channel: CH) => Readonly<Record<string, boolean | string | number | undefined>>;
	onSuccess?: (channel: CH) => void | Promise<void>;
	onFailure?: (channel: CH) => void | Promise<void>;
};

export function useAvailableShareActions<CH extends ShareChannel = ShareChannel>(
	channels: readonly CH[],
	{ getUrl, getTrackingData, getText, trackingEventName, disableShortLinks, onSuccess, onFailure }: Options<CH>,
): ReadonlyArray<ShareAction<CH>> {
	const availableChannels = useAvailableShareChannels(channels);

	const getShortUrl = useGetShareShortUrl(availableChannels, { getUrl, disableShortLinks });

	return useShareActions(availableChannels, {
		getUrl,
		getShortUrl,
		getTrackingData,
		getText,
		trackingEventName,
		onSuccess,
		onFailure,
	});
}

export type AsyncResult<CH extends ShareChannel = ShareChannel> = StateWithoutError<{
	actions: ReadonlyArray<ShareAction<CH>>;
}>;

export function useAvailableShareActionsAsync<CH extends ShareChannel = ShareChannel>(
	channels: readonly CH[],
	{ getUrl, getTrackingData, getText, trackingEventName, disableShortLinks, onSuccess, onFailure }: Options<CH>,
): AsyncResult<CH> {
	const availableChannelsState = useAvailableShareChannelsAsync(channels);

	const availableChannels = useMemo(
		() => (isLoaded(availableChannelsState) ? availableChannelsState.channels : []),
		[availableChannelsState],
	);

	const getShortUrl = useGetShareShortUrl(availableChannels, { getUrl, disableShortLinks });

	const actions = useShareActions(availableChannels, {
		getUrl,
		getShortUrl,
		getTrackingData,
		getText,
		trackingEventName,
		onSuccess,
		onFailure,
	});

	return useMemo(() => {
		if (isLoading(availableChannelsState)) {
			return availableChannelsState;
		}
		return { status: 'loaded', actions };
	}, [availableChannelsState, actions]);
}
