import { useEffect } from 'react';

import { useTrackOnce } from './useTrackOnce';

type Props = NonNullable<Parameters<ReturnType<typeof useTrackOnce>>[1]>;

function useTrackView(eventName: string, data: Props, deps?: readonly unknown[]): void;
function useTrackView(eventName: string, deps?: readonly unknown[]): void;
/**
 * This will trigger only one event immediately (usually for page or component views),
 * even if the tracking data (from the args or the TrackingContext) changes.
 *
 * This will of course still trigger multiple times if the component using the hook is unmounted and re-mounted
 *
 * This will also trigger again if the eventName changes, or if the contents of the optional deps argument change
 *
 * @example
 * useTrackView('my_page_view', { prop: 'value', otherProp: someVar });
 * useTrackView('my_page_view', { prop: 'value', otherProp: someVar }, [someVar]);
 */
function useTrackView(
	eventName: string,
	dataOrDeps?: Props | readonly unknown[],
	depsOrNothing?: readonly unknown[],
): void {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const data: Props | undefined = Array.isArray(dataOrDeps) ? undefined : (dataOrDeps as Props);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const deps = (data ? depsOrNothing : dataOrDeps) as readonly unknown[] | undefined;

	const trackOnce = useTrackOnce([eventName, ...(deps || [])]);

	useEffect(() => {
		void trackOnce(eventName, data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackOnce]);
}

export { useTrackView };
