import qs from 'qs';

import { NotFoundError } from 'src/shared/error';
import type { AppRouteBeforeServerRenderContext } from 'src/shared/routes';

import { getPetitionSlug } from 'src/app/shared/api/petitions';

/**
 * This function tried to resolve the url's slug (which could be an old slug or an id) into the real slug,
 * then redirects to the url with the updated slug
 *
 * This assumes that the route path starts with /p/:slug
 *
 * This conserves all the query params when redirecting
 *
 * The second argument can be used to redirect to the default petition page (/p/:slug) if a specific condition is met
 */
export async function petitionSlugCanonicalRedirect(
	{ path, params: { slug: slugOrId }, query, utilities, redirect }: AppRouteBeforeServerRenderContext,
	redirectToDefaultPage?: boolean,
): Promise<void> {
	const slug = await getPetitionSlug(slugOrId, utilities);
	if (!slug) {
		throw new NotFoundError();
	}
	if (redirectToDefaultPage) {
		const queryString = qs.stringify(query, { addQueryPrefix: true, encodeValuesOnly: true });
		redirect(`/p/${slug}${queryString}`);
		return undefined;
	}
	if (slug !== slugOrId) {
		const queryString = qs.stringify(query, { addQueryPrefix: true, encodeValuesOnly: true });
		const newPath = path.replace(/\/p\/[^/]+/, `/p/${slug}`);
		redirect(`${newPath}${queryString}`);
		return undefined;
	}
	return undefined;
}
