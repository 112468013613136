import { Button } from '@change-corgi/design-system/components/actions';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { PageRenderingButton, SupporterComment } from '../../shared/types';

import { useLikeComment } from './hooks/useLikeComment';
import { LikeIcon, UnLikeIcon } from './LikeCommentIcons';

type LikeCommentProps = {
	comment: SupporterComment;
	petitionId: string;
	renderedOn: PageRenderingButton;
};

// eslint-disable-next-line max-lines-per-function
export function LikeComment({ comment, petitionId, renderedOn }: LikeCommentProps): React.JSX.Element | null {
	const {
		data: { isCommentLiked, commentLikeCount, isGuest },
		actions: { handleLikeClick },
	} = useLikeComment({ comment, petitionId, renderedOn });

	if (isGuest) {
		return null;
	}

	const ariaPrefix = isCommentLiked ? 'unlike comment' : 'like comment';

	return (
		<Button
			size="small"
			aria-label={`${ariaPrefix} from ${comment.user?.shortDisplayName}`}
			data-testid={`supporter-comment-${comment.id}-like-button-combo`}
			sx={{
				border: 'none',
				background: 'primary-greyBackground',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					background: 'rgba(0,0,0,0.1)',
				},
			}}
			onClick={handleLikeClick}
		>
			<Flex sx={{ alignItems: 'center' }}>
				{isCommentLiked ? <UnLikeIcon commentId={comment.id} /> : <LikeIcon commentId={comment.id} />}
				<Text
					fontWeight="bold"
					size="small"
					data-testid={`supporter-comment-${comment.id}-like-count`}
					ml={8}
					color={isCommentLiked ? 'primary-changeRed' : 'primary-black'}
				>
					{commentLikeCount}
				</Text>
			</Flex>
		</Button>
	);
}
