import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useCurrentUser, useCurrentUserAsync } from './currentUser';
import type { AsyncState } from './shared/types';

export function useCurrentUserId(): string | undefined {
	return useCurrentUser()?.id;
}
export function useCurrentUserIdAsync(): AsyncState<string | undefined> {
	return useMappedLoadedState(useCurrentUserAsync(), ({ value }) => ({ value: value?.id }));
}
