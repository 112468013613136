import { CustomError } from 'ts-custom-error';

type HttpErrorCode = 401 | 403 | 404;
type ErrorCode = HttpErrorCode | 'petition_under_review';

export class HttpError extends CustomError {
	readonly httpCode: HttpErrorCode;
	readonly code: ErrorCode;

	constructor(
		message: string,
		httpCode: HttpErrorCode,
		code?: ErrorCode,
		options?: {
			cause?: unknown;
		},
	) {
		super(message, options);
		this.httpCode = httpCode;
		this.code = code || httpCode;
	}
}
