import { useSessionUpdates } from 'src/app/shared/hooks/session';

/**
 * can be used alongside usePrefetchableQuery when used with prefetchUserData
 * to trigger a new query when the session user is updated without a page reload
 */
export function usePrefetchUserDataQueryId(): string {
	const sessionUpdates = useSessionUpdates();

	return `user_${sessionUpdates}`;
}

/**
 * can be used alongside prefetchQuery when used with prefetchUserData
 * to return the initial id returned by usePrefetchUserDataQueryId
 */
export function getInitialPrefetchUserDataQueryId(): string {
	return 'user_0';
}
