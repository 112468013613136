import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { AdminEditTagsPageTagsQuery, AdminEditTagsPageTagsQueryVariables } from './getPetitionTags.graphql';

export type PartialPetition = NonNullable<AdminEditTagsPageTagsQuery['petition']>;

export async function getPetitionTags(
	slug: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition | undefined> {
	const { data } = await fetch<AdminEditTagsPageTagsQuery, AdminEditTagsPageTagsQueryVariables>({
		query: gql`
			query AdminEditTagsPageTags($slug: String!) {
				petition: petitionBySlug(slug: $slug) {
					id
					country {
						countryCode
					}
					stateCode
					city
					tagsConnection(includeUndiscoverableTags: true) {
						nodes {
							name
							id
						}
					}
				}
			}
		`,
		variables: { slug },
	});

	return data?.petition ?? undefined;
}
