import type { JSX } from 'react';

import type { SessionUser } from '@change-corgi/core/session';

import { GoogleAuthButton } from 'src/app/shared/components/buttons';

import { useGoogleButton } from './hooks/useGoogleButton';

type Props = {
	onError: (err: string) => void;
	onSuccess: (user: SessionUser) => void;
};

export function GoogleButton({ onSuccess, onError }: Props): JSX.Element {
	const {
		actions: { handleSuccess, handleFailure },
	} = useGoogleButton({ onSuccess, onError });

	return (
		<GoogleAuthButton onSuccess={handleSuccess} onError={handleFailure} loginType="payment" signupContext="payment" />
	);
}
