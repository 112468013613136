import { useMemo } from 'react';

import { usePetitionSignatureCount } from '../../petitionStats';

export function useHeadlineReplacementsSignatures(): Record<string, string | number> {
	const { signatureCount, signatureTargetLocalized } = usePetitionSignatureCount();

	return useMemo(
		() => ({
			signature_count: signatureCount.displayedLocalized,
			signature_target: signatureTargetLocalized,
		}),
		[signatureCount.displayedLocalized, signatureTargetLocalized],
	);
}
