import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

import { FCM_EXPERIMENT_MEMBERSHIP_AWARENESS_BANNER } from './config';

export const {
	FcmExperimentsContext: MembershipAwarenessBannerFcmExperimentsContext,
	FcmExperimentsProvider: MembershipAwarenessBannerFcmExperimentsContextProvider,
	useFcmExperiments: useMembershipAwarenessBannerFcmExperiments,
	useAsyncFcmExperiments: useAsyncMembershipAwarenessBannerFcmExperiments,
	prefetchFcmExperiments: prefetchMembershipAwarenessBannerFcmExperiments,
} = createFcmExperimentsContext({
	name: 'MembershipAwarenessBannerFcmExperimentsContext',
	dataProperty: 'fcmExperiments',
	configs: {
		membershipAwarenessBannerExperiment: FCM_EXPERIMENT_MEMBERSHIP_AWARENESS_BANNER,
	},
	prefetch: true,
});

export type MembershipAwarenessBannerFcmExperimentsState = ReturnType<
	typeof useAsyncMembershipAwarenessBannerFcmExperiments
>;
export type MembershipAwarenessBannerFcmExperiments = LoadedStateData<MembershipAwarenessBannerFcmExperimentsState>;
