import type { RestrictedAccessConfigRoleFn } from 'src/shared/routes';

import { getQueryStringSingleValue } from 'src/app/shared/utils/query';

import { getIsPetitionDecisionMaker } from './api/isDecisionMaker';

type PetitionDecisionMakerOptions = {
	allowHash: boolean;
	dmHashParam: string;
};

export function petitionDecisionMaker(
	slugOrIdParam: string,
	options?: PetitionDecisionMakerOptions,
): RestrictedAccessConfigRoleFn {
	// function is named for the benefit of src/server/misc/routesStats.ts
	// eslint-disable-next-line @typescript-eslint/no-shadow
	return async function petitionDecisionMaker({ session, utilities, params, query }) {
		const slugOrId = params[slugOrIdParam];
		if (!slugOrId) throw new Error(`Unexpected empty param for "${slugOrIdParam}"`);
		if (!session.user) return false;
		// Option to check if the URL contains a valid decision maker hash
		if (options?.allowHash) {
			const decisionMakerHash = getQueryStringSingleValue(query, options?.dmHashParam);
			return getIsPetitionDecisionMaker(utilities, slugOrId, decisionMakerHash);
		}
		return getIsPetitionDecisionMaker(utilities, slugOrId);
	};
}
