import type { KeyboardEvent, MouseEvent } from 'react';
import { useCallback } from 'react';

import { useFocusFallbackTrigger } from './useFocusFallbackTrigger';

type Event = MouseEvent | KeyboardEvent;

export function useFocusFallbackManualTrigger(): (eventOrElement: Event | EventTarget | Element) => void {
	const focusFallback = useFocusFallbackTrigger();

	return useCallback(
		(eventOrElement: Event | EventTarget | Element) => {
			if (!focusFallback) return;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const element = (eventOrElement as MouseEvent | KeyboardEvent).target || eventOrElement;
			focusFallback({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				element: element as HTMLElement,
			});
		},
		[focusFallback],
	);
}
