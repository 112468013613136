import { useCallback, useMemo } from 'react';

import { useLocalStorageValueAsync } from 'src/app/shared/hooks/storage';

type FormData = {
	petition_id: string;
	first_name: string;
	last_name: string;
	email: string;
	city: string;
	country_code: string;
	state_code?: string;
	postal_code?: string;
	public: boolean;
	share_info?: boolean;
} & (
	| {
			marketing_comms_consent?: false;
	  }
	| {
			marketing_comms_consent: true;
			consent_name: string;
			consent_text: string;
	  }
);

export type PendingSignature = {
	formData: FormData;
	userAccountData: {
		user_id?: string;
		petition_id: string;
		email: string;
		connected_to_facebook?: boolean;
		password_set?: boolean;
	};
	metaData: {
		reasons_for_signing_completed?: boolean;
		mit_or_fht_utm_content?: string;
		algorithm?: string;
		source_location?: string;
		alert_id?: string;
		traffic_metadata?: {
			current_source?: string;
			current_medium?: string;
			referring_domain?: string;
		};
	};
};

export function usePendingSignature(
	petitionId: string,
): [PendingSignature | undefined, (signature: PendingSignature) => void] {
	const [pendingSignatureString, setPendingSignatureString] = useLocalStorageValueAsync(
		`pending-signature-${petitionId}`,
	);

	const parsed = useMemo(() => {
		if (!pendingSignatureString) return undefined;
		try {
			// TODO validate using zod?
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return JSON.parse(pendingSignatureString) as unknown as PendingSignature;
		} catch (e) {
			return undefined;
		}
	}, [pendingSignatureString]);
	const set = useCallback(
		(signature: PendingSignature) => {
			setPendingSignatureString(JSON.stringify(signature));
		},
		[setPendingSignatureString],
	);

	return [parsed, set];
}
