import type { JSX, PropsWithChildren } from 'react';

import { usePreloadRoutes } from '../hooks/preloadRoutes';

type PreloadProps = Readonly<{
	preload?: readonly string[];
}>;

export function Preload({ preload, children }: PropsWithChildren<PreloadProps>): JSX.Element | null {
	usePreloadRoutes(preload);

	return <>{children}</>;
}
