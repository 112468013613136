import type { ComponentProps, JSX, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { Button } from '@change-corgi/design-system/components/actions';

type Props = Readonly<{
	onClick?: () => void;
}> &
	ComponentProps<typeof Button>;

export const LoadMoreButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(function LoadMoreButton(
	{ children, onClick, ...rest }: PropsWithChildren<Props>,
	ref,
): JSX.Element {
	return (
		<Button variant="secondary" onClick={onClick} ref={ref} sx={{ width: '100%' }} {...rest}>
			{children}
		</Button>
	);
});
