import { useState } from 'react';

type Result = ModelHookResult<
	{
		error: string | undefined;
	},
	{
		handleError: (error: string) => void;
	}
>;

export function useLoginModalSocialAuth(): Result {
	const [error, setError] = useState<string | undefined>(undefined);

	return {
		data: {
			error,
		},
		actions: {
			handleError: setError,
		},
	};
}
