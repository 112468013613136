import type { EnvironmentUtils } from '@change-corgi/core/environment';
import { createErrorReporter as _createErrorReporter } from '@change-corgi/core/errorReporter/client';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import { getLocation } from '@change-corgi/core/window';

import sentryConfig from 'config/sentry.browser.json';

import webappInfo from 'src/webappInfo';

import { additionalContext, additionalParams } from './processors';

type Options = Readonly<{
	environment: EnvironmentUtils;
}>;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		newrelic: Readonly<{
			noticeError: (error: string | Error, customAttributes?: Record<string, unknown>) => void;
			// ... and a bunch of other stuff
		}>;
	}
}

const BREADCRUMBS_IGNORED_DOMAINS = [
	// NewRelic
	'bam.nr-data.net',
	'bam-cell.nr-data.net',
	// Sentry
	/^.*\.sentry\.io$/,
	// PerimeterX
	/^.*\.px-cdn\.net$/,
	/^.*\.px-cloud\.net$/,
	/^.*\.px-client\.net$/,
	// Optimizely
	/^.*\.optimizely\.com$/,
	// Google Analytics
	'www.google-analytics.com',
];

export function createErrorReporter({ environment }: Options): ErrorReporter {
	const { hostname } = getLocation();

	return _createErrorReporter({
		environment: environment.getEnvironment(),
		subEnvironment: environment.getDemoEnvironment() || environment.getEnvironment(),
		hostname,
		webappInfo,
		reporters: {
			sentry: {
				// production DSN has a rate limit configured at the project level
				dsn: environment.getEnvironment() === 'production' ? sentryConfig.dsn.production : sentryConfig.dsn.other,
				applicationKey: sentryConfig.applicationKey,
				// sampleRate: environment.getEnvironment() === 'production' ? 0.5 : 1.0
				htmlPiiSelectors: {
					mask: ['[data-pii-unsafe]'],
					unmask: ['[data-pii-safe]'],
				},
				breadcrumbs: {
					domSerializedAttributes: ['data-testid', 'data-qa'],
					ignoredDomains: BREADCRUMBS_IGNORED_DOMAINS,
				},
			},
		},
		additionalContext,
		additionalParams,
		...(process.env.ERROR_REPORTING_ENABLED ? { silencedEnvironments: [] } : {}),
	})
		.toggleLogging(environment.getEnvironment() === 'development')
		.init();
}
