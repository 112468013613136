import type { JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { Locale } from '@change-corgi/config/locales';

import { L10nContextProvider } from './context';

type Props = {
	countryCode: string;
	locale: Locale;
};

export function L10n({ children, countryCode, locale }: PropsWithChildren<Props>): JSX.Element {
	const value = useMemo(
		() => ({
			countryCode,
			locale,
		}),
		[countryCode, locale],
	);

	return <L10nContextProvider value={value}>{children}</L10nContextProvider>;
}
