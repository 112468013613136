import type { JSX } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import { Avatar } from '@change-corgi/design-system/components/media';
import { iconChangeOrg, iconDescription } from '@change-corgi/design-system/icons';

import type { PetitionContentUpdate } from 'src/app/pages/petition/details/shared/types';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

const MARGIN = 12;
const SIZE = 36;

export function UpdateByLineAvatar({ update }: Props): JSX.Element | null {
	if (update.type === 'staff') {
		return <Icon size={SIZE} icon={iconChangeOrg} mr={MARGIN} color="primary-changeRed" />;
	}
	if (update.type === 'featuredNews') {
		return <Icon size={SIZE} icon={iconDescription} mr={MARGIN} color="neutral-grey600" />;
	}

	if (!update.author) {
		return null;
	}

	const { photo } = update.author;
	const photoUrl = (photo && !photo.processing && photo.url) || undefined;

	return <Avatar variant="subtle" src={photoUrl} alt="" size={SIZE} mr={MARGIN} />;
}
