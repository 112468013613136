import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { sendResetPasswordLink } from './api/sendResetPasswordLink';

export function useSendResetPasswordLink(email: string): () => Promise<boolean> {
	const utilityContext = useUtilityContext();

	return useCallback(async () => {
		try {
			return await sendResetPasswordLink({ email }, utilityContext);
		} catch (error: unknown) {
			return false;
		}
	}, [utilityContext, email]);
}
