import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { ROUTES_COMPONENT } from 'src/app/pages/petition/routes.config';
import type { SubRouteConfig } from 'src/app/pages/petition/shared/tabTypes';
import { createRoutes, handleRecruiter, Loading, petitionSlugCanonicalRedirect } from 'src/app/shared/routes';

import { resolvePetitionL10nForBotsFromSlugParam } from './shared/resolvePetitionL10nForBotsFromSlugParam';
import { shareUrlCanonicalRedirect } from './shared/shareUrlCanonicalRedirect';

function genPetitionSubRouteConfigConfig(config: Omit<SubRouteConfig, 'routeType'>): SubRouteConfig {
	return {
		routeType: 'petitionSubRoute',
		...config,
	};
}

const PETITION_DETAILS_COMMON_SUB_ROUTE_CONFIG: Omit<SubRouteConfig, 'routeType' | 'component'> = {
	tab: 'campaign',
	showBanner: true,
	hideTitle: true,
	trackingData: {
		currentPage: 'show',
	},
};

function genPetitionDetailsSubRouteConfigConfig(config: Pick<SubRouteConfig, 'component'>): SubRouteConfig {
	return genPetitionSubRouteConfigConfig({
		...PETITION_DETAILS_COMMON_SUB_ROUTE_CONFIG,
		...config,
	});
}

const PETITION_DETAILS_BOT_SSR_CONFIG: AppRoute['ssr'] = {
	enabled: true,
	seo: true, // enabled so that twitter/FB can generate a preview
	resolveL10n: resolvePetitionL10nForBotsFromSlugParam,
	config: {
		disallowUserData: true,
	},
};

export const ROUTES: readonly AppRoute[] = [
	...createRoutes(
		{
			component: ROUTES_COMPONENT,
			frame: true,
		},
		[
			{
				id: 'petition-details',
				path: '/p/:slug',
				beforeServerRender: async (context) => {
					await handleRecruiter(context, context.params.slug);
					await petitionSlugCanonicalRedirect(context);
				},
				ssr: {
					enabled: true,
					seo: true,
					resolveL10n: resolvePetitionL10nForBotsFromSlugParam,
					config: ({ loginState }) => {
						if (loginState === 'GUEST') {
							return {
								disallowUserData: true,
								cache: {
									enabled: true,
									lifetimeInSeconds: 1 * 60, // 1min
									queryParams: ['expired_session'],
								},
							};
						}
						// avoid caching for non-guest
						// mainly so that starters see their changes right away
						return {};
					},
				},
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetails',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-sfs',
				path: '/p/:slug/sfs/:shareChannel/:sharerUserId',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsShareForStarters',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-nftexp',
				path: '/p/:slug/nftexp/:experimentName/:experimentVariantName/:sharerUserId',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsFacebookBandit',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-expgst',
				path: '/p/:slug/expgst/:experimentName/:experimentVariantName/:sharerDisplayName/:sharerLocation',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsFacebookBanditGuest',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-fbog',
				path: '/p/:slug/fbog/:sharerUserId',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsFacebook',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-fbgst',
				path: '/p/:slug/fbgst/:sharerDisplayName/:sharerLocation',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsFacebookGuest',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-exp',
				path: '/p/:slug/exp/:shareType/:experimentName/:experimentVariantName/:sharerUserId',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsBandit',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-exp-v2',
				path: '/p/:slug/exp/v2/:shareType/:experimentName/:experimentVariantName/:sharerUserId',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsBanditV2',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-bandit-expg',
				path: '/p/:slug/expg/:shareType/:experimentName/:experimentVariantName/:sharerDisplayName/:sharerLocation',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetailsBanditGuest',
						fallback: Loading,
					}),
				}),
			},
			{
				id: 'petition-details-victory',
				path: '/p/:slug/share_victory',
				beforeServerRender: shareUrlCanonicalRedirect,
				ssr: PETITION_DETAILS_BOT_SSR_CONFIG,
				additionalConfig: genPetitionDetailsSubRouteConfigConfig({
					component: loadable(async () => import(/* webpackChunkName: "pages/petition_details" */ '../index'), {
						exportName: 'PetitionDetails',
						fallback: Loading,
					}),
				}),
			},
		],
	),
];
