import { useCallback, useState } from 'react';

import { Waypoint } from 'react-waypoint';

import type { WaypointCallbackArgs } from 'src/app/shared/components/layout/lazy';
import { useTrackPromotionImpression } from 'src/app/shared/hooks/promotion';
import type { TrackingPromotionPlacement } from 'src/app/shared/hooks/promotion';

type Props = Readonly<{
	petitionId: string;
	promotionToken: string;
	placement: TrackingPromotionPlacement;
	victory?: boolean;
	algorithm?: string;
}>;

export function PromotedImpressionTracker({
	petitionId,
	promotionToken,
	placement,
	algorithm,
	victory,
}: Props): React.JSX.Element {
	const [tracked, setTracked] = useState(false);

	const trackPromotedImpression = useTrackPromotionImpression({
		petitionId,
		promotionToken,
		algorithm,
		placement: victory ? `${placement}_victory` : placement,
	});

	const onPositionChange = useCallback(
		({ currentPosition }: WaypointCallbackArgs) => {
			if (tracked || currentPosition === 'below') {
				return;
			}
			trackPromotedImpression();
			setTracked(true);
		},
		[trackPromotedImpression, tracked, setTracked],
	);

	return <Waypoint onPositionChange={onPositionChange} />;
}
