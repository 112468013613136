import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoading } from 'src/app/shared/utils/async';

import type { SupporterComment } from '../../types';

export function useCanDeleteComment(comment: SupporterComment | null): boolean {
	const currentUser = useCurrentUserAsync();

	if (isLoading(currentUser)) return false;
	if (!comment) return false;
	const isCommentOwner = currentUser.value?.id === comment.user?.id;
	return currentUser.value?.roles.support || currentUser.value?.roles.campaigns || isCommentOwner;
}
