import type { ComponentProps, JSX } from 'react';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { Translate } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Box } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	labelKey: TranslationKey;
	mobileOnly?: boolean;
	onClick?: () => void;
	to: string;
};

export function DropDownMenuItem({
	labelKey,
	mobileOnly = false,
	onClick,
	to,
	...rest
}: Props & ComponentProps<typeof Box>): JSX.Element | null {
	return (
		<Box
			as="li"
			role="none"
			mb={8}
			sx={{
				display: mobileOnly ? ['block', null, 'none'] : 'block',
				// this allows the item to take as much space as possible before wrapping
				maxWidth: 200,
				width: 'max-content',
			}}
			{...rest}
		>
			<RouterLink
				role="menuitem"
				to={to}
				onClick={onClick}
				sx={{
					...linkResetStyles,
					display: 'block',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:hover': {
						color: 'primary-changeRed',
					},
				}}
			>
				<Text breakWord>
					<Translate value={labelKey} />
				</Text>
			</RouterLink>
		</Box>
	);
}
