import once from 'lodash/once';

/*
 *
 * TODO: Re-impleent this code in fe-core
 *
 * https://change.atlassian.net/browse/FE-222
 *
 */

type DataLayerObj = Record<string, unknown>;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		dataLayer?: DataLayerObj[];
	}
}

const push = (dataLayerObj: DataLayerObj): void => {
	window.dataLayer ??= [];
	window.dataLayer.push(dataLayerObj);
};

const pushPetitionId = (id: number): void => {
	push({ petition_id: id });
};

const pushUserId = (id: number): void => {
	push({ uid: id });
};

const pushUserEmail = (email: string): void => {
	push({ user_email: email });
};

type ConversionAction = 'membership_creation' | 'optin' | 'petition_publish' | 'unsubscribe';
type ConversionCalback = () => void;
type ConversionData = {
	event: 'conversion';
	conversionAction: ConversionAction;
	eventCallback?: ConversionCalback;
};

const pushConversion = (conversionAction: ConversionAction, callback?: ConversionCalback): void => {
	const onceCallback = callback && once(callback);
	const data: ConversionData = {
		event: 'conversion',
		conversionAction,
	};

	if (onceCallback) {
		// handles case in prod where GTM fails to load, is blocked, or is slow
		setTimeout(() => {
			onceCallback();
		}, 3000);
		data.eventCallback = onceCallback;
	}

	// push event like normal, in prod GTM should call the callback if defined
	push(data);
};

const pushUserDataAvailable = (): void => {
	push({ event: 'user-data-available' });
};

export const googleTagManagerTracking = {
	push,
	pushPetitionId,
	pushUserId,
	pushUserEmail,
	pushConversion,
	pushUserDataAvailable,
};
