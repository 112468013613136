import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { countryTeamOfUser, hasRoleAndCountryTeam, Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-users-history',
		path: '/dashboard/users/:user/history',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_users_history" */ './index'), {
			exportName: 'AdminUsersHistory',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: [
				'product',
				'support',
				hasRoleAndCountryTeam('userResearch', 'user'),
				countryTeamOfUser(['campaigns'], 'user'),
			],
		},
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
