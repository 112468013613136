import { createPrefetchableAsyncUserDataContext, pageDataErrorHandler } from 'src/app/shared/contexts/asyncData';

import { getPetitionDetailsPageUserData } from './api';
import type { PetitionDetailsPageUserDataErrorCode } from './types';

export const {
	Context: PetitionDetailsPageUserDataContext,
	Provider: PetitionDetailsPageUserDataContextProvider,
	useContext: usePetitionDetailsPageUserData,
	useAsyncData: usePetitionDetailsPageUserDataFromApi,
	prefetchAsyncData: prefetchPetitionDetailsPageUserDataFromApi,
} = createPrefetchableAsyncUserDataContext({
	name: 'PetitionDetailsPageUserDataContext',
	dataProperty: 'pageUserData',
	getData: getPetitionDetailsPageUserData,
	// no need to have the sessionUser in the list, sessionUUID is enough
	getUniqueId: (slug, { isGuest, decisionMakerHash, sessionUUID }) =>
		[slug, isGuest, decisionMakerHash, sessionUUID].join(','),
	hookDeps: (slug, { isGuest, decisionMakerHash, sessionUUID }) => [slug, isGuest, decisionMakerHash, sessionUUID],
	errorHandler: pageDataErrorHandler<PetitionDetailsPageUserDataErrorCode>(),
});
