import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		// Admin users always add /admin to the URL, so we need to redirect them to the correct URL
		path: '/p/:slug/admin',
		buildUrl: ({ params: { slug }, queryString }) =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			`/dashboard/petitions/${encodeURIComponent(slug || '')}${queryString}`,
		reason: 'obsolete-url',
	},
];
