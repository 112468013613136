import gql from 'graphql-tag';

import type { Utilities, UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	PetitionIdFromSlugQuery,
	PetitionIdFromSlugQueryVariables,
	PetitionSlugFromIdQuery,
	PetitionSlugFromIdQueryVariables,
} from './petition.graphql';

export async function getPetitionId(
	slug: string,
	utilities: UtilityContext | Pick<Utilities, 'gql'>,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<PetitionIdFromSlugQuery, PetitionIdFromSlugQueryVariables>({
		query: gql`
			query PetitionIdFromSlug($slug: String!) {
				petition: petitionBySlug(slug: $slug) {
					slug
					id
				}
			}
		`,
		variables: { slug },
	});
	return data?.petition?.id ?? undefined;
}

export async function getPetitionSlug(
	slugOrId: string,
	// Utilities support is currently necessary for this to be called by route config
	utilities: UtilityContext | Pick<Utilities, 'gql'>,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<PetitionSlugFromIdQuery, PetitionSlugFromIdQueryVariables>({
		query: gql`
			query PetitionSlugFromId($slugOrId: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					slug
					id
				}
			}
		`,
		variables: { slugOrId },
	});
	return data?.petition?.slug ?? undefined;
}
