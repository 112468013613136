import type { ApiPetitionUpdate } from './types';

export function normalizeMedia(update: ApiPetitionUpdate): Readonly<{ url: string; processing: boolean }> | null {
	if (update.__typename === 'MilestonePetitionUpdate') return null;
	if (update.photo)
		return {
			url: update.photo.petitionMedium.url,
			processing: update.photo.petitionMedium.processing,
		};
	if (update.embeddedMedia && update.embeddedMedia.photoUrl)
		return {
			url: update.embeddedMedia.photoUrl,
			processing: false,
		};
	return null;
}
