import { useCurrentUserRole } from 'src/app/shared/hooks/session';

type Result = {
	isOpen: boolean;
};

export function useUserStaff(): Result {
	const userIsStaff = useCurrentUserRole('staff');

	return {
		isOpen: userIsStaff,
	};
}
