import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'array',
	shape: {
		type: 'string',
	},
});

export const FCM_OPTIMIZELY_EVENT_SAFELIST = createFcmConfig('optimizely_event_safelist', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: [],
});
