import { useEffect, useRef, useState } from 'react';

import { backOff } from 'exponential-backoff';

import { loadImage } from 'src/app/shared/utils/dom';

const MAX_ATTEMPTS = 10;
const POLLING_TIME = 3 * 1000; // 3sec

type Strategy = 'normal' | 'exponentialBackoff';

type Options = Readonly<{
	/**
	 * default: 10
	 */
	maxAttempts?: number;
	/**
	 * default: 3 sec
	 *
	 * in exponentialBackoff mode, this is the max delay
	 */
	pollingTime?: number;
	processing?: boolean;
	/**
	 * default: normal
	 */
	strategy?: Strategy;
}>;

const STRATEGY_FN: Record<
	Strategy,
	(url: string, options: { maxAttempts: number; pollingTime: number; isCancelled: () => boolean }) => Promise<boolean>
> = {
	normal: async (url, { maxAttempts, pollingTime, isCancelled }) => {
		try {
			await backOff(async () => loadImage(url), {
				numOfAttempts: maxAttempts,
				// using same value for start and max delay to simulate a non-exponentialBackoff strategy
				startingDelay: pollingTime,
				maxDelay: pollingTime,
				delayFirstAttempt: true,
				retry: () => !isCancelled(),
			});
			return true;
		} catch (e) {
			return false;
		}
	},
	exponentialBackoff: async (url, { maxAttempts, pollingTime, isCancelled }) => {
		try {
			await backOff(async () => loadImage(url), {
				numOfAttempts: maxAttempts,
				maxDelay: pollingTime,
				retry: () => !isCancelled(),
			});
			return true;
		} catch (e) {
			return false;
		}
	},
};

export function useLoadImage(url: string | null | undefined, { processing, ...options }: Options): boolean {
	const [loaded, setLoaded] = useState(processing === false);
	// these should not change over time
	const pollingTime = useRef(options.pollingTime || POLLING_TIME).current;
	const maxAttempts = useRef(options.maxAttempts || MAX_ATTEMPTS).current;
	const strategy = useRef<Strategy>(options.strategy || 'normal').current;

	// reset attempts count and loaded state when url changes
	useEffect(() => {
		setLoaded(processing === false);
	}, [url, processing]);

	useEffect(() => {
		if (!url || loaded) {
			return undefined;
		}
		let cancelled = false;
		(async () => {
			setLoaded(await STRATEGY_FN[strategy](url, { maxAttempts, pollingTime, isCancelled: () => cancelled }));
		})();
		return () => {
			cancelled = true;
		};
	}, [url, loaded, strategy, maxAttempts, pollingTime]);

	return loaded;
}
