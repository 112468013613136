import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	RestrictedAccessPetitionDecisionMakerQuery,
	RestrictedAccessPetitionDecisionMakerQueryVariables,
} from './isDecisionMaker.graphql';

export async function getIsPetitionDecisionMaker(
	utilities: Pick<UtilityContext, 'gql'>,
	slugOrId: string,
	decisionMakerHash?: string,
): Promise<boolean> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessPetitionDecisionMakerQuery,
		RestrictedAccessPetitionDecisionMakerQueryVariables
	>({
		// cannot be in a .graphql file at this point because this file is indirectly imported by webpack
		query: gql`
			query RestrictedAccessPetitionDecisionMaker(
				$slugOrId: String!
				$decisionMakerHash: String!
				$fetchDmByHash: Boolean!
			) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					viewerIsDecisionMaker
					decisionMakerByHash(decisionMakerHash: $decisionMakerHash) @include(if: $fetchDmByHash) {
						id
					}
				}
			}
		`,
		variables: { slugOrId, decisionMakerHash: decisionMakerHash || '', fetchDmByHash: !!decisionMakerHash },
		rejectOnError: true,
	});

	if (decisionMakerHash) {
		return !!data?.petition?.viewerIsDecisionMaker || !!data?.petition?.decisionMakerByHash?.id;
	}

	return !!data?.petition?.viewerIsDecisionMaker;
}
