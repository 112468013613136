import copy from 'copy-to-clipboard';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { getWindow } from '@change-corgi/core/window';

// to support older browsers
function fallback(text: string, { translate }: UtilityContext['i18n']) {
	// TODO different message for desktop?
	const message = translate('fe.components.share_buttons.mobile_copy_text');
	// library does not support navigator.clipboard yet
	return copy(text, { format: 'text/plain', message });
}

export async function copyToClipboard(text: string, i18nContext: UtilityContext['i18n']): Promise<boolean> {
	const { navigator } = getWindow();
	if (!navigator.clipboard) {
		return fallback(text, i18nContext);
	}
	try {
		// this is the current proper way of copying to the clipboard
		await navigator.clipboard.writeText(text);
		return true;
	} catch (e) {
		return fallback(text, i18nContext);
	}
}
