import type { PrefetchContext } from '@change-corgi/core/react/prefetch';

import { useQueryString } from 'src/app/shared/hooks/location';
import { getQueryParamToken } from 'src/app/shared/utils/auth';

export function useQueryParamToken(prefix?: string): string | undefined {
	const query = useQueryString();
	return getQueryParamToken(query, prefix);
}

export const prefetchQueryParamToken = (context: PrefetchContext, prefix?: string): string | undefined =>
	getQueryParamToken(context.query, prefix);
