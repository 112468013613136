import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	GammaPetitionDetailsPageGdprConsent,
	type GammaPetitionDetailsPageGdprConsentQuery,
	type GammaPetitionDetailsPageGdprConsentQueryVariables,
} from './gdprConsentProvided.graphql';

export async function isGdprConsentProvided({ gql: { fetch } }: UtilityContext): Promise<boolean> {
	const { data } = await fetch<
		GammaPetitionDetailsPageGdprConsentQuery,
		GammaPetitionDetailsPageGdprConsentQueryVariables
	>({
		query: GammaPetitionDetailsPageGdprConsent,
		rejectOnError: true,
	});
	const session = data?.session;
	if (!session) {
		throw new Error('Cannot retrieve session');
	}
	return !!session.consentResponses.globalConsentInitialEu;
}
