import React from 'react';

import type { Props } from '../shared/types';

import { useMembershipBottomBanner } from './hooks/useMembershipBottomBanner';
import { Layout } from './layout';

export function MembershipBottomBanner(props: Props): React.JSX.Element | null {
	const { displayed } = useMembershipBottomBanner();
	if (!displayed) return null;

	return <Layout {...props} />;
}
