import { createPrefetchableAsyncDataContext, pageDataErrorHandler } from 'src/app/shared/contexts/asyncData';

import { getPetitionDetailsPageData } from './api';
import type { PetitionDetailsPageDataErrorCode } from './types';

export const {
	Context: PetitionDetailsPageDataContext,
	Provider: PetitionDetailsPageDataContextProvider,
	useContext: usePetitionDetailsPageData,
	useAsyncData: usePetitionDetailsPageDataFromApi,
	prefetchAsyncData: prefetchPetitionDetailsPageDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'PetitionDetailsPageDataContext',
	dataProperty: 'pageData',
	getData: getPetitionDetailsPageData,
	// do not look at loadBandits otherwise that results in the SSR not working anymore for non-guests
	// as that results in the id/deps changing at first client-side render due to useSessionAsync
	getUniqueId: (slug, { countryCode, locale }) => [slug, countryCode, locale].join(','),
	hookDeps: (slug, { countryCode, locale }) => [slug, countryCode, locale],
	errorHandler: pageDataErrorHandler<PetitionDetailsPageDataErrorCode>(),
});
