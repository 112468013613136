import { useCallback, useState } from 'react';

import type { SupporterVideo } from '../../../../shared/types';

type Result = ModelHookResult<
	{
		isVideoLiked: boolean;
		videoLikeCount: number;
	},
	{
		updateLike: (like: boolean) => void;
	}
>;

export function useLikeVideoState({ video: { hasCurrentUserLiked, likeCount } }: { video: SupporterVideo }): Result {
	const [isVideoLiked, setIsVideoLiked] = useState(hasCurrentUserLiked || false);
	const [videoLikeCount, setVideoLikeCount] = useState(likeCount || 0);

	const updateLike = useCallback(
		(like: boolean) => {
			setIsVideoLiked(like);
			if (like) {
				setVideoLikeCount(like !== isVideoLiked ? videoLikeCount + 1 : videoLikeCount);
			} else {
				setVideoLikeCount(like !== isVideoLiked ? videoLikeCount - 1 : videoLikeCount);
			}
		},
		[isVideoLiked, videoLikeCount],
	);

	return {
		data: {
			isVideoLiked,
			videoLikeCount,
		},
		actions: {
			updateLike,
		},
	};
}
