import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import type { SessionUser } from '@change-corgi/core/session';

import { FacebookAuthButton } from 'src/app/shared/components/buttons';

import { useFacebookButton } from './hooks/useFacebookButton';

type Props = {
	onError: (err: string) => void;
	onSuccess: (user: SessionUser) => void;
};

export function FacebookButton({ onSuccess, onError }: Props): JSX.Element {
	const {
		actions: { handleSuccess, handleFailure },
	} = useFacebookButton({ onSuccess, onError });

	return (
		<FacebookAuthButton
			onSuccess={handleSuccess}
			onError={handleFailure}
			optionalOnClickTrackProps={{ facebook_login_type: 'login_or_join' }}
			loginType="payment"
		>
			<Translate value="corgi.facebook.continue_with" />
		</FacebookAuthButton>
	);
}
