import { createFcmContext } from 'src/app/shared/contexts/fcm';
import { FCM_MEMBERSHIP_AWARENESS_LOCALIZED_SIGN_COUNTS } from 'src/app/shared/fcm/configs';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: MembershipAwarenessBannerFcmContext,
	FcmProvider: MembershipAwarenessBannerFcmContextProvider,
	useFcm: useMembershipAwarenessBannerFcm,
	useAsyncFcm: useAsyncMembershipAwarenessBannerFcm,
	prefetchFcm: prefetchMembershipAwarenessBannerFcm,
} = createFcmContext({
	name: 'MembershipAwarenessBannerFcmContext',
	dataProperty: 'fcm',
	configs: {
		localizedCountsConfig: FCM_MEMBERSHIP_AWARENESS_LOCALIZED_SIGN_COUNTS,
	},
	prefetch: true,
});

export type MembershipAwarenessBannerFcmState = ReturnType<typeof useAsyncMembershipAwarenessBannerFcm>;
export type MembershipAwarenessBannerFcm = LoadedStateData<MembershipAwarenessBannerFcmState>;
