import { useLocale } from 'src/app/shared/hooks/l10n';

import type { LinkGroup } from '../../shared/types';

export function useGuideLinks(): LinkGroup | null {
	const locale = useLocale();

	return locale === 'en-US'
		? {
				title: 'Guides',
				links: [
					{ href: '/l/us/how-to-write-a-petition', title: 'How To Write A Petition' },
					{ href: '/l/us/do-online-petitions-work', title: 'Do Petitions Work' },
					{ href: '/l/us/what-is-the-right-to-petition', title: 'What is the Right To Petition' },
					{ href: '/l/us/how-to-start-a-school-petition', title: 'How to Start a School Petition' },
				],
			}
		: null;
}
