import { getLocation, getWindow } from '@change-corgi/core/window';

import { getRouteConfigFromPath } from 'src/app/routes';

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
export function additionalParams(): Record<string, unknown> | undefined {
	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const window = getWindow() as any;
		const optimizelyExperiments =
			window.optimizely && window.optimizely.get
				? window.optimizely.get('state').getExperimentStates({ isActive: true })
				: undefined;

		if (!optimizelyExperiments) return undefined;

		return {
			optimizelyExperiments: !!Object.keys(optimizelyExperiments || {}).length,
			optimizelyExperimentsIds: Object.keys(optimizelyExperiments || {}),
		};
	} catch (e) {
		return undefined;
	}
}
/* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */

export function additionalContext(): Record<string, unknown> | undefined {
	try {
		// window.location.pathname is encoded
		const match = getRouteConfigFromPath(decodeURI(getLocation().pathname));
		if (!match) return undefined;
		return {
			route: {
				id: match.config.id,
				path: match.path,
				params: match.params,
			},
		};
	} catch (e) {
		return undefined;
	}
}
