import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { setStateCode } from './api';

export function useSetCurrentStateCode(): (stateCode: string) => Promise<boolean> {
	const utilityContext = useUtilityContext();

	return useCallback(async (stateCode) => setStateCode({ stateCode }, utilityContext), [utilityContext]);
}
