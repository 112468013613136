/**
 * Most of our videos are vertical phone recordings, so defaulting to a vertical aspect ratio
 * reduces layout shifts.
 */
export const DEFAULT_ASPECT_RATIO = 3 / 4;

/**
 * Image elements and media elements have different attributes names for their intrinsic dimensions:
 *
 * - https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement/videoHeight
 * - https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/naturalHeight
 */
export type MediaDimensions = {
	height: number;
	width: number;
	intrinsicHeight: number;
	intrinsicWidth: number;
};

export function getVideoDimensions(videoEl: HTMLVideoElement): MediaDimensions {
	return {
		height: videoEl.height,
		width: videoEl.width,
		intrinsicHeight: videoEl.videoHeight,
		intrinsicWidth: videoEl.videoWidth,
	};
}
export function getImageDimensions(imageEl: HTMLImageElement): MediaDimensions {
	return {
		height: imageEl.height,
		width: imageEl.width,
		intrinsicHeight: imageEl.naturalHeight,
		intrinsicWidth: imageEl.naturalWidth,
	};
}

export function calculateAspectRatio(dimensions: MediaDimensions): number | undefined {
	const { intrinsicHeight, intrinsicWidth } = dimensions;

	if (intrinsicHeight === 0) {
		return undefined;
	}

	return intrinsicWidth / intrinsicHeight;
}

export function getVideoAspectRatio(videoEl: HTMLVideoElement): number | undefined {
	// State 1 is `HAVE_METADATA` which is when the videos dimensions should be available.
	if (videoEl.readyState < 1) {
		return undefined;
	}

	return calculateAspectRatio(getVideoDimensions(videoEl));
}

export function getImageAspectRatio(imageEl: HTMLImageElement): number | undefined {
	// We can only use the image's dimensions if loading has finished.
	if (!imageEl.complete) {
		return undefined;
	}

	return calculateAspectRatio(getImageDimensions(imageEl));
}
