import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	RestrictedAccessCountryTeamRoleUserIdQuery,
	RestrictedAccessCountryTeamRoleUserIdQueryVariables,
} from './getUserCountryCode.graphql';

export async function getUserCountryCode(
	utilities: Pick<UtilityContext, 'gql'>,
	userSlugOrId: string,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessCountryTeamRoleUserIdQuery,
		RestrictedAccessCountryTeamRoleUserIdQueryVariables
	>({
		// cannot be in a .graphql file at this point because this file is indirectly imported by webpack
		query: gql`
			query RestrictedAccessCountryTeamRoleUserId($userSlugOrId: String!) {
				user: userBySlugOrId(slugOrId: $userSlugOrId) {
					id
					country {
						countryCode
					}
				}
			}
		`,
		variables: { userSlugOrId },
		rejectOnError: true,
	});

	return data?.user?.country?.countryCode || undefined;
}
