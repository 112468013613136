import { Avatar } from '@change-corgi/design-system/components/media';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useLocalizeRelativeNow } from '../../shared/hooks/useLocalizeRelativeNow';
import type { SupporterVideo } from '../../shared/types';

export function PetitionVideoItemHeader({ video }: { video: SupporterVideo }): React.JSX.Element {
	const localizeRelativeNow = useLocalizeRelativeNow();

	const { createdAt, user } = video;
	const author = user?.shortDisplayName;
	const localizedTime = createdAt && localizeRelativeNow(createdAt);

	return (
		<Flex sx={{ justifyContent: 'space-between', flexDirection: 'row' }} mb={16}>
			<Flex sx={{ alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
				<Avatar />
				<Text fontWeight="bold">{author}</Text>
			</Flex>
			<Flex sx={{ alignItems: 'center' }}>
				<Text sx={{ textAlign: 'right' }}> {localizedTime}</Text>
			</Flex>
		</Flex>
	);
}
