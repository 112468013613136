import { useCallback } from 'react';

import type { SignPetitionResult } from 'src/app/shared/api/signature';

import { useOneClickSignPetition } from './useOneClickSignPetition';

type HookOptions = {
	pageContext: string;
	promotionPlacement?: string;
};

export function useSignSuggestedPetition(
	{
		id: petitionId,
		promotionToken,
	}: {
		id: string;
		promotionToken: string | null | undefined;
	},
	{ promotionPlacement, ...hookOptions }: HookOptions,
): () => Promise<SignPetitionResult> {
	const signPetition = useOneClickSignPetition(petitionId, hookOptions);

	return useCallback(async () => {
		return signPetition({
			promotionPlacement,
			promotionToken,
		});
	}, [signPetition, promotionToken, promotionPlacement]);
}
