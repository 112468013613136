import isEqual from 'lodash/fp/isEqual';

import type { FeatureConfigInfo } from '@change-corgi/core/fcm';

const CONFIGS_MAP: Record<string, FeatureConfigInfo> = {};

export function updateConfigsMap(configs: Record<string, FeatureConfigInfo>): readonly string[] {
	return Object.values(configs).reduce<string[]>((acc, config) => {
		if (CONFIGS_MAP[config.name]) {
			if (!isEqual(CONFIGS_MAP[config.name], config) && !acc.includes(config.name)) {
				return [...acc, config.name];
			}
			return acc;
		}
		CONFIGS_MAP[config.name] = config;
		return acc;
	}, []);
}
