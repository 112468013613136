import { useCallback } from 'react';
import type { JSX } from 'react';

import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';

import { translationKey } from '@change-corgi/core/i18n';

import type { I18nFieldValidatorResult } from 'src/app/shared/form/validators';
import { useCountryCode } from 'src/app/shared/hooks/l10n';

import { Buttons } from '../components/Buttons';
import { CategoryField } from '../components/CategoryField';
import { FormError } from '../components/FormError';
import { InvalidFormError } from '../components/InvalidFormError';
import { ReasonField } from '../components/ReasonField';
import { SubCategoryField } from '../components/SubCategoryField';
import { useReportAbuse } from '../hooks/useReportAbuse';
import type { FormValues, ReportContext } from '../shared/types';

type Props = ReportContext & {
	onSuccess?: () => void;
};

export function ReportAbuseFormContainer({ onSuccess, ...reportContext }: Props): JSX.Element {
	const countryCode = useCountryCode();
	const reportAbuse = useReportAbuse(reportContext);

	const { type } = reportContext;

	const onSubmit = useCallback(
		async (values: FormValues) => {
			try {
				await reportAbuse(values);
				onSuccess?.();
				return undefined;
			} catch (e) {
				return {
					[FORM_ERROR]: { key: translationKey('fe.errors.generic_try_again') },
				} satisfies Record<string, I18nFieldValidatorResult>;
			}
		},
		[reportAbuse, onSuccess],
	);

	return (
		<Form<FormValues> onSubmit={onSubmit}>
			{({ handleSubmit }) => (
				<form onSubmit={handleSubmit} data-qa="abuse-report-form">
					<CategoryField type={type} countryCode={countryCode} />
					<SubCategoryField type={type} />
					<ReasonField type={type} />
					<InvalidFormError />
					<FormError />
					<Buttons />
				</form>
			)}
		</Form>
	);
}
