import type { JSX } from 'react';

import { parseHtml } from '@change-corgi/core/react/html';
import type { SessionUser } from '@change-corgi/core/session';
import { InlineMessage } from '@change-corgi/design-system/components/alerts';
import { Box } from '@change-corgi/design-system/layout';

import { FacebookButton } from './components/FacebookButton';
import { GoogleButton } from './components/GoogleButton';
import { useLoginModalSocialAuth } from './hooks/useLoginModalSocialAuth';

type Props = {
	googleAuthEnabled: boolean;
	onSuccess: (user: SessionUser) => void;
};

export function LoginModalSocialAuth({ onSuccess, googleAuthEnabled }: Props): JSX.Element {
	const {
		data: { error },
		actions: { handleError },
	} = useLoginModalSocialAuth();

	return (
		<>
			{error && (
				<InlineMessage variant="error" mb={16} px={8}>
					{parseHtml(error)}
				</InlineMessage>
			)}
			{googleAuthEnabled && (
				<Box mb={16}>
					<GoogleButton onSuccess={onSuccess} onError={handleError} />
				</Box>
			)}
			<Box>
				<FacebookButton onSuccess={onSuccess} onError={handleError} />
			</Box>
		</>
	);
}
