import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { CancelMembershipEnrollmentMutation, CancelMembershipEnrollmentMutationVariables } from './api.graphql';

export async function cancelMembership(id: string, { gql: { fetch } }: UtilityContext): Promise<void> {
	const { data, errors } = await fetch<CancelMembershipEnrollmentMutation, CancelMembershipEnrollmentMutationVariables>(
		{
			query: gql`
				mutation CancelMembershipEnrollment($id: String!) {
					cancelMembershipEnrollment(input: { id: $id }) {
						success
						errorReason
					}
				}
			`,
			variables: { id },
		},
	);

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from mutation.');
	if (!data.cancelMembershipEnrollment.success)
		throw new Error(data.cancelMembershipEnrollment.errorReason ?? undefined);
}
