type CraftEmailHrefArgs = {
	dmName: string;
	dmEmail?: string | null;
	ask: string;
	slug: string;
	signatureCount?: string;
	emailDm: { emailSubjectLine: string; emailBody: readonly string[] };
};

const ccChangeEmail = 'decision.makers@change.org';
export const craftSupporterEmail = ({
	ask,
	slug,
	dmName,
	dmEmail,
	signatureCount,
	emailDm,
}: CraftEmailHrefArgs): string => {
	const petitionUrl = `https://www.change.org/p/${slug}?utm_source=supporter_emails_dm`;

	const { emailSubjectLine: subject, emailBody } = emailDm;

	const body = emailBody
		.map((line) =>
			line
				.replace('%{dmName}', dmName)
				.replace('%{ask}', ask)
				.replace('%{signatureCount}', signatureCount || '')
				.replace('%{petitionUrl}', petitionUrl),
		)
		.join('\n\n');

	const encodedSubject = encodeURIComponent(subject);
	const encodedBody = encodeURIComponent(body);

	return `mailto:${dmEmail}?cc=${ccChangeEmail}&body=${encodedBody}&subject=${encodedSubject}`;
};
