import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		path: '/u/:slugOrId/edit',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: () => '/account_settings/profile',
		reason: 'deprecated-url',
	},
];
