import { normalizeBoolean } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PROMOTIONS_SHOW_ALL_PROMOTIONS_AT_START_OF_CAROUSEL = createFcmConfig(
	'promotions_show_all_promotions_at_start_of_carousel',
	{
		normalizer: normalizeBoolean,
		defaultValue: false,
	},
);
