import { useMemo } from 'react';

import {
	usePetitionDetailsPageUserData,
	usePetitionDetailsPageUserInfo,
} from 'src/app/pages/petitionGamma/details/pageContext';
import type {
	PetitionDetailsPageUserData,
	PetitionDetailsPageUserInfo,
} from 'src/app/pages/petitionGamma/details/pageContext';

export type FullUserInfo = PetitionDetailsPageUserInfo & PetitionDetailsPageUserData;

/**
 * @deprecated use usePetitionDetailsPageUserInfo() and usePetitionDetailsPageUserData() instead
 */
export function useUserInfo(): FullUserInfo {
	const userInfo = usePetitionDetailsPageUserInfo();
	const { data: pageUserData } = usePetitionDetailsPageUserData();
	return useMemo(() => ({ ...userInfo, ...pageUserData }), [userInfo, pageUserData]);
}
