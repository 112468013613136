import { useMemo } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

export function useIsMobile(): boolean {
	const { userAgent } = useUtilityContext();

	return useMemo(
		() => ['mobile', 'tablet'].includes(userAgent.getParsedUserAgent().getDevice().type || ''),
		[userAgent],
	);
}
