import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { PetitionUpdateType } from '@change-corgi/middle-end/gql';

import type {
	PetitionDetailsPagePetitionUpdatesQuery,
	PetitionDetailsPagePetitionUpdatesQueryVariables,
} from './getPetitionUpdates.graphql';
import { normalizePetitionInfo } from './normalizePetitionInfo';
import { normalizeUpdates } from './normalizeUpdates';
import type { PetitionUpdate, PetitionUpdates } from './types';

const QUERY = gql`
	query PetitionDetailsPagePetitionUpdates(
		$petitionId: ID!
		$count: Int!
		$types: [PetitionUpdateType!]!
		$cursor: ID
	) {
		petition: petitionById(id: $petitionId) {
			id
			slug

			user {
				id
				displayName
				photo {
					id
					userSmall {
						url
						processing
					}
				}
			}
			organization {
				id
				name
				photo {
					id
					userSmall {
						url
						processing
					}
				}
			}

			updatesConnection: petitionUpdatesConnection(types: $types, first: $count, after: $cursor) {
				nodes {
					__typename
					id
					createdAt
					... on MilestonePetitionUpdate {
						signatureCount
					}
					... on StarterPetitionUpdate {
						title
						description
						...PetitionDetailsPagePetitionUpdatesMediaFields
					}
					... on StaffPetitionUpdate {
						title
						description
						...PetitionDetailsPagePetitionUpdatesMediaFields
					}
					... on FeaturedNewsPetitionUpdate {
						title
						...PetitionDetailsPagePetitionUpdatesMediaFields
					}
					... on DmResponsePetitionUpdate {
						title
						description
						...PetitionDetailsPagePetitionUpdatesMediaFields
						dmResponse {
							id
							decisionMaker {
								id
								displayName
								photo {
									id
									userSmall {
										url
										processing
									}
								}
							}
						}
					}
				}
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}
	}

	fragment PetitionDetailsPagePetitionUpdatesMediaFields on PetitionUpdateWithMedia {
		photo {
			id
			petitionMedium {
				url
				processing
			}
		}
		embeddedMedia {
			providerName
			photoUrl
			description
		}
	}
`;

type Options = Readonly<{
	count: number;
	cursor?: string;
	types?: readonly PetitionUpdateType[];
}>;

export async function getPetitionUpdates<U extends PetitionUpdate = PetitionUpdate>(
	petitionId: string,
	{ cursor, count, types }: Options,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionUpdates<U> | undefined> {
	const { data } = await fetch<
		PetitionDetailsPagePetitionUpdatesQuery,
		PetitionDetailsPagePetitionUpdatesQueryVariables
	>({
		query: QUERY,
		variables: {
			petitionId,
			count,
			types: types || ['MILESTONE', 'STARTER', 'DM_RESPONSE', 'STAFF', 'FEATURED_NEWS'],
			cursor: cursor || null,
		},
		// to separate query from other batched queries
		important: true,
	});
	const petition = data?.petition;
	if (!petition) {
		return undefined;
	}
	const { slug, organization, user } = petition;
	return normalizeUpdates<U>(petition.updatesConnection, normalizePetitionInfo({ slug, organization, user }));
}
