import { useMemo } from 'react';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useIsMembershipEligibleCountry } from '../useIsMembershipEligibleCountry';

export function useIsMembershipEligibleUser(): boolean {
	const isMembershipEligibleCountry = useIsMembershipEligibleCountry();
	const session = useSessionAsync();

	return useMemo(() => {
		if (!isLoaded(session)) return false;
		return isMembershipEligibleCountry && !session.value.hasMembership;
	}, [isMembershipEligibleCountry, session]);
}
