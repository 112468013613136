import { useCallback, useState } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { cancelMembership } from './api';

export type CancelMembershipResult = [
	{ cancelled: boolean; cancelError: string | undefined },
	(id: string) => Promise<void>,
];

export function useCancelMembership(): CancelMembershipResult {
	const utilityContext = useUtilityContext();

	const [cancelled, setCancelled] = useState(false);
	const [cancelError, setCancelError] = useState<string | undefined>(undefined);

	const cancel = useCallback(
		async (id: string) => {
			try {
				await cancelMembership(id, utilityContext);
				setCancelError(undefined);

				// TODO: Remove timeout after addressing cancellation webhook delay https://change.atlassian.net/browse/CHANGE-50777
				setTimeout(() => setCancelled(true), 1500);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
				setCancelError(`An error occurred trying to cancel the membership: ${e.message}`);
			}
		},
		[utilityContext],
	);

	return [{ cancelled, cancelError }, cancel];
}
