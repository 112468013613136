import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		activatedPercentage: { type: 'number' },
		createdAfter: { type: 'string' },
		minSignaturePercentageToBeACluster: { type: 'number', optional: true },
		signatureCount: {
			type: 'object',
			shape: {
				minimum: { type: 'number' },
				maximum: { type: 'number' },
			},
		},
		clusterSearch: {
			type: 'object',
			optional: true,
			shape: {
				minimumSize: { type: 'number' },
				maximumSize: { type: 'number' },
				increment: { type: 'number' },
			},
		},
		petitions: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					petitionId: { type: 'string' },
					clusterSize: { type: 'number', optional: true },
					zoomLevel: { type: 'number', optional: true },
					minSignaturePercentageToBeACluster: { type: 'number', optional: true },
				},
			},
		},
		blocklist: {
			type: 'array',
			optional: true,
			shape: {
				type: 'object',
				shape: {
					petitionId: { type: 'string' },
				},
			},
		},
	},
});

export const FCM_SIGNATURES_DISTRIBUTION_CONFIG = {
	name: 'signatures_distribution_config',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		activatedPercentage: 0,
		createdAfter: '2015-01-01',
		minSignaturePercentageToBeACluster: 0.6,
		signatureCount: { minimum: 100, maximum: 50000 },
		clusterSearch: { minimumSize: 0.1, maximumSize: 1.1, increment: 0.1 },
		petitions: [],
		blocklist: [],
	} as ReturnType<typeof normalizer>,
};
