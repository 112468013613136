import type { I18nFieldValidator, WithI18nConfig } from './shared/types';

export function requiredIfOtherFieldValidator<
	T extends string = string,
	U extends Record<string, unknown> = Record<string, unknown>,
>({ i18n, otherFieldName }: WithI18nConfig<{ otherFieldName: keyof U }>): I18nFieldValidator<T | undefined> {
	return (value, allValues) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const otherField = (allValues as U)[otherFieldName];
		return value || !otherField ? undefined : i18n;
	};
}
