import type { PrefetchContext, PrefetchedDataProps } from '@change-corgi/core/react/prefetch';

import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchMembershipReminderPopupDataFromApi, useMembershipReminderPopupDataFromApi } from './data';
import type { MembershipReminderPopupFcmState } from './fcm';
import { prefetchMembershipReminderPopupFcm, useAsyncMembershipReminderPopupFcm } from './fcm';
import type { MembershipReminderPopupFcmExperimentsState } from './fcmExperiments';
import {
	prefetchMembershipReminderPopupFcmExperiments,
	useAsyncMembershipReminderPopupFcmExperiments,
} from './fcmExperiments';

export type MembershipReminderPopupPrefetchedContext = Readonly<{
	data: Parameters<typeof useMembershipReminderPopupDataFromApi>[0];
}>;

export type MembershipReminderPopupCombinedState = MergedState<
	ReturnType<typeof useMembershipReminderPopupDataFromApi>,
	MembershipReminderPopupFcmState,
	MembershipReminderPopupFcmExperimentsState
>;

export function useContext(
	prefetchedData?: MembershipReminderPopupPrefetchedContext,
): MembershipReminderPopupCombinedState {
	return useMergedStates(
		useMembershipReminderPopupDataFromApi(prefetchedData?.data),
		useAsyncMembershipReminderPopupFcm(),
		useAsyncMembershipReminderPopupFcmExperiments(),
	);
}

export async function prefetchContext(context: PrefetchContext): Promise<MembershipReminderPopupPrefetchedContext> {
	const [data] = await Promise.all([
		prefetchMembershipReminderPopupDataFromApi(context),
		prefetchMembershipReminderPopupFcm(context),
		prefetchMembershipReminderPopupFcmExperiments(context),
	]);

	return { data };
}

export type MembershipReminderPopupPrefetchedDataProps = PrefetchedDataProps<MembershipReminderPopupPrefetchedContext>;
