import { useCallback } from 'react';

import { useGetNewSession } from 'src/app/shared/hooks/session';

export function useGetNewUserId(): () => Promise<string | undefined> {
	const getNewSession = useGetNewSession();
	const trackSuccessBase = useCallback(async () => {
		try {
			return (await getNewSession()).user?.id;
		} catch (e) {
			return undefined;
		}
	}, [getNewSession]);

	return trackSuccessBase;
}
