// eslint-disable-next-line no-control-regex
const word = /('?|([a-zA-Z0-9]|[^\x00-\x7F])*)([a-zA-Z0-9]|[^\x00-\x7F])+('?([a-zA-Z0-9]|[^\x00-\x7F])*)/;
const connector = /(\s|-)/;
// eslint-disable-next-line security/detect-non-literal-regexp
const regex = new RegExp(`^((${word.source})((${connector.source}${word.source}))*)$`);

/**
 * Allows for words separated by spaces, hyphens, apostrophes, or foreign connectors.
 * Words can begin or end with a single apostrophe.
 * A word made up of only connectors in a row are not allowed (for example, '''').
 */
export function alphaNumAposHyphen(inputValue: string | undefined): boolean {
	return !!inputValue?.trim()?.match(regex);
}
