import qs from 'qs';

import type { I18nCountriesLocaleModule } from '@change-corgi/config/i18n/countries/locale-data/common';
import type { Locale } from '@change-corgi/config/locales';
import { fallbackLocale, locales } from '@change-corgi/config/locales';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { I18n, Translations } from '@change-corgi/core/i18n';
import { createI18n } from '@change-corgi/core/i18n';

import { getTranslations as getTranslationsWrapper } from './translations';

function getSupportedLocale(locale: string): Locale {
	if (locales.includes(locale as Locale)) {
		return locale as Locale;
	}

	return fallbackLocale;
}

export async function setupI18n(context: {
	locale: string;
	getTranslations: (locale: Locale) => Promise<Translations>;
	reportError: ErrorReporter['report'];
	/**
	 * translations to use as a priority, generally coming from hydration data
	 */
	translations?: Translations;
}): Promise<I18n> {
	const locale = getSupportedLocale(context.locale);
	const [translations, { i18nCountries: countries }] = await Promise.all([
		getTranslationsWrapper(locale, context),
		import(
			/* webpackChunkName: "i18n/countries/locale/[request]" */ `@change-corgi/config/i18n/countries/locale-data/${locale}.js`
		) as Promise<I18nCountriesLocaleModule>,
	]);

	const i18n = createI18n({
		locale,
		translations: context.translations ? [context.translations, translations] : translations,
		countries,
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		reportError: context.reportError,
	});

	return i18n;
}

export function getTranslationsCacheBuster({
	env,
	translationsIdentifier,
}: {
	env: string;
	translationsIdentifier?: string;
}): string | undefined {
	const queryParams = {
		cb: 1,
		...(env === 'production' ? {} : { host: env }),
		// WARNING: if the query param is changed, make sure to update src/server/shared/i18n/getAppTranslations.ts
		...(translationsIdentifier ? { hash: translationsIdentifier } : {}),
	};
	return qs.stringify(queryParams);
}

export { getTranslations } from './getTranslations';
export { getFilteredTranslationsUrl } from './getFilteredTranslationsUrl';
