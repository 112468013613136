/* eslint-disable no-param-reassign */
import type { ShortUrlsCacheState } from './types';

// mutations are mutating the state contents to conserve the reference and avoid triggering unnecessary re-renders
// although we're still returning a new state reference to trigger the react lifecycle
export const mutations = {
	addToQueue: (state: ShortUrlsCacheState, urls: readonly string[]): ShortUrlsCacheState => {
		const filteredUrls = urls.filter((url) => !state.cache[url] && !state.queue.includes(url));
		filteredUrls.forEach((url) => {
			state.cache[url] = { status: 'loading' };
		});

		if (!filteredUrls.length) return state;

		state.queue.push(...filteredUrls);

		return { ...state };
	},
	removeFromQueue: (state: ShortUrlsCacheState, configs: readonly string[]): ShortUrlsCacheState => {
		if (!state.queue.length || !configs.length) return state;

		configs.forEach((config) => {
			const index = state.queue.indexOf(config);
			if (index === -1) return;
			state.queue.splice(index, 1);
		});

		return { ...state };
	},
	setLoaded: (state: ShortUrlsCacheState, shortUrls: Record<string, string>): ShortUrlsCacheState => {
		if (!Object.entries(shortUrls).length) return state;

		Object.entries(shortUrls).forEach(([longUrl, shortUrl]) => {
			state.cache[longUrl] = { status: 'loaded', shortUrl };
		});

		return { ...state };
	},
	setError: (state: ShortUrlsCacheState, urls: readonly string[]): ShortUrlsCacheState => {
		if (!urls.length) return state;

		urls.forEach((url) => {
			state.cache[url] = { status: 'error' };
		});

		return { ...state };
	},
};
