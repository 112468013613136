import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import type { SessionUser } from '@change-corgi/core/session';

import { useCurrentUser } from 'src/app/shared/hooks/session';
import { setPxUserData } from 'src/app/shared/utils/payments';

import { useLoginModal } from './useLoginModal';

type Props = { email: string; showContinueAsGuest: boolean; googleAuthEnabled: boolean };

export function useLoginWithModal(): (props?: Props) => Promise<SessionUser | undefined> {
	const user = useCurrentUser();
	const track = useTracking();

	const openLoginModal = useLoginModal();

	return useCallback(
		async (
			{ email, showContinueAsGuest, googleAuthEnabled } = {
				email: '',
				showContinueAsGuest: true,
				googleAuthEnabled: false,
			},
		) => {
			if (!user && !email) return undefined;
			const currentEmail = user?.email ?? email;
			void track('login_or_join_view', { context: 'log_in', user_flow: 'payment' });
			const modalResult = await openLoginModal({
				user,
				email: currentEmail,
				showContinueAsGuest,
				googleAuthEnabled,
			}).waitForResult();
			if (!modalResult?.user) {
				void track('close_login_signup');
			}
			setPxUserData(modalResult?.user);
			return modalResult?.user;
		},
		[openLoginModal, user, track],
	);
}
