import type { RefObject } from 'react';

import type { FocusFallback, FocusFallbackListener } from '../types';

export function createFallback(
	ref: RefObject<HTMLElement | null | undefined>,
	listener?: FocusFallbackListener,
): FocusFallback {
	if (listener) {
		return { ref, listener };
	}
	return {
		ref,
		listener: ({ container, focus }) => focus(container),
	};
}
