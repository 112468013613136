import uniq from 'lodash/fp/uniq';

import { loadable } from '@change-corgi/core/react/loadable';

import { SAP_L10N_MAP } from 'config/routes-l10n';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

import { resolveSapShortcutLocales } from './shared/resolveSapShortcutLocales';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'sap',
		path: [
			'/start-a-petition',
			// TODO improve utf-8 support
			// need both utf-8 and encoded version for proper support in react-router (utf-8) and express (encoded)
			// this path means we are supporting urls like /fr/создать-петицию, which is not a big deal as long as the canonical url is still /fr/lancer-une-pétition
			// react-router 6 removed support of regexs - there's an ongoing discussion to re-add them (https://github.com/remix-run/react-router/discussions/9844)
			// `/:locale(${SAP_LOCALES.join('|')})/:slug(${uniq([...SAP_SLUGS, ...SAP_SLUGS_ENCODED]).join('|')})`,
			...Object.entries(SAP_L10N_MAP).reduce<string[]>((acc, [locale, details]) => {
				acc.push(...uniq([`/${locale}/${details.sap}`, `/${locale}/${encodeURIComponent(details.sap)}`]));
				return acc;
			}, []),
		],
		component: loadable(async () => import(/* webpackChunkName: "pages/startAPetition" */ '../index'), {
			exportName: 'StartAPetition',
			fallback: Loading,
		}),
		frame: { footer: false, header: true },
		ssr: {
			enabled: true,
			seo: true,
			resolveL10n: resolveSapShortcutLocales,
		},
	},
];
