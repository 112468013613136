import type { JSX } from 'react';
import { useEffect, useState } from 'react';

import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

type VideoState = 'paused' | 'buffering' | 'playing';

/**
 * This is a simplified set of HTML media states. See:
 * https://html.spec.whatwg.org/multipage/media.html#event-media-stalled
 */
const getVideoState = (eventType: string): VideoState => {
	if (eventType === 'playing') {
		return 'playing';
	}
	if (['waiting', 'stalled'].includes(eventType)) {
		return 'buffering';
	}
	return 'paused';
};

/**
 * Shows an icon based on the video's state. For now it just shows a loading spinner if the video is
 * buffering, but we could track and show more.
 */
export function VideoStateIndicator({ videoRef }: { videoRef: React.RefObject<HTMLVideoElement> }): JSX.Element | null {
	const [videoState, setVideoState] = useState<VideoState>('paused');

	useEffect(() => {
		const videoEl = videoRef.current;
		if (!videoEl) {
			return () => {};
		}

		const handleEvent = (event: Event) => {
			setVideoState(getVideoState(event.type));
		};

		videoEl.addEventListener('playing', handleEvent);
		videoEl.addEventListener('pause', handleEvent);
		videoEl.addEventListener('waiting', handleEvent);
		videoEl.addEventListener('stalled', handleEvent);

		return () => {
			videoEl.removeEventListener('playing', handleEvent);
			videoEl.removeEventListener('pause', handleEvent);
			videoEl.removeEventListener('waiting', handleEvent);
			videoEl.removeEventListener('stalled', handleEvent);
		};
	}, [videoRef]);

	// If the video has controls then the customer indicator would just conflict.
	const canBeVisible = !videoRef.current?.controls;
	const isVisible = canBeVisible && videoState === 'buffering';

	return (
		<div
			sx={{
				height: '20%',
				display: isVisible ? 'block' : 'none',
			}}
			data-testid="video-state-indicator"
		>
			<Loader color="typography-lightPrimary" />
		</div>
	);
}
