import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-users-stop-impersonating',
		path: '/dashboard/users/stop_impersonating',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_users_stop_impersonating" */ './index'), {
			exportName: 'AdminUsersStopImpersonating',
			fallback: Loading,
		}),
		// no role check as we might be impersonating someone and therefore not staff...
		frame: true,
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
