export function truncate(string: string, width = 210, ellipsis = ' …'): string {
	if (width > string.length) {
		return string;
	}
	let tempStringTruncated = string.slice(0, width - ellipsis.length); // Truncated + ellipsis;
	const lastword = tempStringTruncated.lastIndexOf(' '); // get last word to avoid "break last word"

	tempStringTruncated = tempStringTruncated.slice(0, lastword); // Remove the last 'break' word

	return tempStringTruncated + ellipsis;
}
