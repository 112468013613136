import type { JSX, PropsWithChildren } from 'react';

import { DLocalContext } from './context';
import { useDLocalSDK } from './useDLocalSDK';

export function DLocalProvider({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	const dLocalSDK = useDLocalSDK();

	return <DLocalContext.Provider value={dLocalSDK}>{children}</DLocalContext.Provider>;
}
