import { createStateContext } from '@change-corgi/core/react/context';

import { INITIAL_STATE } from './initialState';
import { mutations } from './mutations';
import type { PetitionDetailsPageState } from './types';

export const {
	StateContext: PetitionDetailsPageStateContext,
	StateProvider: PetitionDetailsPageStateContextProvider,
	useStateContext: usePetitionDetailsPageState,
} = createStateContext<PetitionDetailsPageState, typeof mutations>({
	name: 'PetitionDetailsPageStateContext',
	mutations,
	initialState: INITIAL_STATE,
});
