import type { SessionUser } from '@change-corgi/core/session';

import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useSession, useSessionAsync } from './session';
import type { AsyncState } from './shared/types';

export function useCurrentUser(): SessionUser | undefined {
	return useSession().user || undefined;
}
export function useCurrentUserAsync(): AsyncState<SessionUser | undefined> {
	return useMappedLoadedState(useSessionAsync(), ({ value }) => ({ value: value.user || undefined }));
}
