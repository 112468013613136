import { CustomError } from 'ts-custom-error';

export class PageDataError<CODE extends string> extends CustomError {
	readonly code: CODE;

	constructor(
		code: CODE,
		{
			message,
			...options
		}: {
			message?: string;
			cause?: unknown;
		} = {},
	) {
		super(message || code, options);
		this.code = code;
	}
}
