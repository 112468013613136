import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-experiments-new',
		path: '/admin/experiments/new',
		priority: 10, // higher priority than /admin/experiments/:id
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_experiments_details" */ './index'), {
			exportName: 'AdminExperimentsNew',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['product', 'engineering'],
		},
		frame: true,
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
