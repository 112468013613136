import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { handleRecruiter, Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'petition-update',
		path: '/p/:petitionSlugOrId/u/:updateId',
		component: loadable(async () => import(/* webpackChunkName: "pages/petition_update" */ './index'), {
			exportName: 'PetitionUpdate',
			fallback: Loading,
		}),
		frame: true,
		beforeServerRender: async (context) => {
			await handleRecruiter(context, context.params.petitionSlugOrId);
		},
		ssr: {
			enabled: true,
			seo: true,
		},
	},
];
