import { isFocusable } from './focusable';

/**
 * Wrapper around Element.focus that adds the support of a makeFocusable option
 *
 * if makeFocusable is true and the element is not focusable,
 * it adds a tabindex=-1 to it before focusing it, then removes it on blur
 *
 * returns true if element was focused
 */
export function focus(
	elt: Element | null | undefined,
	{ makeFocusable, ...options }: FocusOptions & { makeFocusable?: boolean } = {},
): boolean {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (!elt || !(elt as HTMLElement).focus) return false;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const focusableElement = elt as HTMLElement;
	if (makeFocusable && !isFocusable(focusableElement)) {
		focusableElement.setAttribute('tabindex', '-1');
		if (!isFocusable(focusableElement)) {
			focusableElement.removeAttribute('tabindex');
			return false;
		}
		focusableElement.focus(options);
		const listener = () => {
			focusableElement.removeAttribute('tabindex');
			focusableElement.removeEventListener('blur', listener);
		};
		focusableElement.addEventListener('blur', listener);
		return true;
	}
	focusableElement.focus(options);
	return true;
}
