import { useCallback } from 'react';

import { useRefreshSession } from 'src/app/shared/hooks/session';

import { useFacebookAutoLoginTracking } from './tracking';

export function useUpdateSessionOrReload(): () => Promise<void> {
	const { trackReload, trackSuccess, trackFailure } = useFacebookAutoLoginTracking();
	const refreshSession = useRefreshSession();

	return useCallback(async () => {
		try {
			const newSession = await refreshSession({
				reloadPageOnFailure: false,
				reloadPageOnL10nChange: false,
				onFailure: () => {
					trackFailure('session_retrieval_failure');
				},
				onReloadPage: (reason, session) => {
					trackReload(reason, session);
				},
			});

			trackSuccess(newSession);
		} catch (e) {
			// silent failure
		}
	}, [refreshSession, trackReload, trackSuccess, trackFailure]);
}
