__webpack_public_path__ = (() => {
	const base = (() => {
		if (process.env.NODE_ENV === 'development') {
			return process.env.DEV_PUBLIC_PATH;
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
		return (typeof window !== 'undefined' && ((window as any).SCRIPTS_ASSETS_BASE as string)) || '';
	})();
	return `${base}${process.env.ASSETS_PATH_BASE}/`;
})();
