import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

import { FCM_EXPERIMENT_MEMBERSHIP_REMINDER_MODAL } from './config';

export const {
	FcmExperimentsContext: MembershipReminderPopupFcmExperimentsContext,
	FcmExperimentsProvider: MembershipReminderPopupFcmExperimentsContextProvider,
	useFcmExperiments: useMembershipReminderPopupFcmExperiments,
	useAsyncFcmExperiments: useAsyncMembershipReminderPopupFcmExperiments,
	prefetchFcmExperiments: prefetchMembershipReminderPopupFcmExperiments,
} = createFcmExperimentsContext({
	name: 'MembershipReminderPopupFcmExperimentsContext',
	dataProperty: 'fcmExperiments',
	configs: {
		showMembershipReminderModal: FCM_EXPERIMENT_MEMBERSHIP_REMINDER_MODAL,
	},
	prefetch: true,
});

export type MembershipReminderPopupFcmExperimentsState = ReturnType<
	typeof useAsyncMembershipReminderPopupFcmExperiments
>;
export type MembershipReminderPopupFcmExperiments = LoadedStateData<MembershipReminderPopupFcmExperimentsState>;
