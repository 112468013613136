import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { isSafari } from 'src/app/shared/utils/userAgent';

import { forceReflow } from '../../utils/forceReflow';
import { DEFAULT_ASPECT_RATIO, getImageAspectRatio, getVideoAspectRatio } from '../../utils/mediaDimensions';

type Props = {
	wrapperRef: React.RefObject<HTMLDivElement>;
};

type Result = ModelHookResult<
	{
		sizeStyle: {
			height?: string;
			width?: string;
			aspectRatio: number;
		};
	},
	{
		updateMediaDimensions: () => void;
	}
>;

/**
 * This hook includes the logic the VideoPlayer needs to maintain its sizing as its poster and video
 * gradually load in. Returns:
 *
 *   - `sizeStyle` - Style object which is used on the wrapper div.
 *   - `updateMediaDimensions` - Function that is called whenever more the media elements have
 *     loaded more information, until the true aspect ratio of the video is known.
 */
export function useResponsiveVideo({ wrapperRef }: Props): Result {
	const utilityContext = useUtilityContext();
	const [mediaDimensions, setMediaDimensions] = useState<{
		posterAspectRatio?: number;
		videoAspectRatio?: number;
	}>({});

	const updateMediaDimensions = useCallback(() => {
		const wrapper = wrapperRef.current;
		if (!wrapper) {
			return;
		}

		const poster = wrapper.getElementsByTagName('img').item(0);
		const video = wrapper.getElementsByTagName('video').item(0);

		const posterAspectRatio = poster ? getImageAspectRatio(poster) : undefined;
		const videoAspectRatio = video ? getVideoAspectRatio(video) : undefined;

		setMediaDimensions({
			posterAspectRatio,
			videoAspectRatio,
		});
	}, [wrapperRef]);

	const sizeStyle = useMemo(() => {
		const { videoAspectRatio, posterAspectRatio } = mediaDimensions;

		// Use the poster's aspect ratio if the video's isn't available.
		const bestAspectRatio = videoAspectRatio ?? posterAspectRatio;

		// If the media elements haven't loaded yet, then let the wrapper fill its container. The
		// default aspect ratio won't affect the div, but it prevents the `video` element from doing
		// crazy things in Safari.
		if (!bestAspectRatio) {
			return {
				height: '100%',
				width: '100%',
				aspectRatio: DEFAULT_ASPECT_RATIO,
			};
		}

		return {
			height: '100%',
			aspectRatio: bestAspectRatio,
		};
	}, [mediaDimensions]);

	// We check the dimensions on first render to get a basis for the wrapper size.
	// useEffect(() => {
	useEffect(() => {
		updateMediaDimensions();
	}, [updateMediaDimensions]);

	// Safari needs to be forced to reflow elements when the media dimensions change.
	useEffect(() => {
		if (!isSafari(utilityContext)) {
			return;
		}

		forceReflow(wrapperRef.current);
	}, [mediaDimensions, utilityContext, wrapperRef]);

	return {
		data: {
			sizeStyle,
		},
		actions: {
			updateMediaDimensions,
		},
	};
}
