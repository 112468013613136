import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabledPercent: { type: 'number' },
		blockedEmailProviders: {
			type: 'array',
			optional: true,
			shape: {
				type: 'string',
			},
		},
	},
});

export const FCM_CAMPAIGN_STARTER_DM_RECOMMENDER_ADD_EMAIL = createFcmConfig(
	'campaign_starter_dm_recommender_add_email',
	{
		normalizer: createJsonNormalizer(normalizer),
		defaultValue: { enabledPercent: 0, blockedEmailProviders: [] },
	},
);
