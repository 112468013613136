import { createImage } from './create';

export async function loadImage(url: string): Promise<void> {
	// eslint-disable-next-line promise/avoid-new
	return new Promise((resolve, reject) => {
		const image = createImage();
		image.onload = () => {
			resolve();
		};
		image.onerror = () => {
			reject(new Error('Error loading image'));
		};
		image.src = url;
	});
}
