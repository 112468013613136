import { FCM_MEMBERSHIP_YEARLY_CONTRIBUTIONS } from 'src/app/shared/fcm/configs';
import { createExperimentFcmConfig } from 'src/app/shared/hooks/experiment';

export const FCM_EXPERIMENT_MEMBERSHIP_YEARLY_CONTRIBUTIONS = createExperimentFcmConfig({
	experimentName: ({ fcm: { config } }) => config?.experiment_name,
	fcmConfigs: {
		config: FCM_MEMBERSHIP_YEARLY_CONTRIBUTIONS,
	},
	isEnabled: ({ fcm: { config } }) => {
		return !!config?.experiment_name;
	},
});
