import { useEffect, useRef, useState } from 'react';

import { Loader as GoogleApiLoader } from '@googlemaps/js-api-loader';

import { isSsr } from '@change-corgi/core/ssr';

import { googleMapsApiClientKey } from 'config/google';

export type UseLoadGooglePlacesReturn = {
	status: 'loading' | 'loaded' | 'error';
	autocompleteService?: google.maps.places.AutocompleteService;
};

const getGoogleApiLoader = () =>
	new GoogleApiLoader({
		apiKey: googleMapsApiClientKey,
		version: 'weekly',
		libraries: ['places'],
	});

async function loadGooglePlacesAPI(): Promise<typeof google> {
	if (isSsr()) {
		return Promise.reject(new Error('Google Places API can only be loaded on the client-side!'));
	}
	return getGoogleApiLoader().load();
}

export function useLoadGooglePlaces(): UseLoadGooglePlacesReturn {
	const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>('loading');
	const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService>();

	useEffect(() => {
		(async () => {
			try {
				const google = await loadGooglePlacesAPI();
				autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
				setStatus('loaded');
			} catch (e) {
				setStatus('error');
			}
		})();
	}, []);

	return { status, autocompleteService: autocompleteServiceRef.current };
}
