/**
 * This utility can be used to force an HTML element to be reconsidered in the document flow. This
 * is a hack to address a bug in Safari in which a media element has room to grow but its parent is
 * not updated to match.
 *
 * For explanation of browsers tested, see:
 * src/app/shared/components/petitionVideo/components/VideoPlayer/__tests__/README.md
 *
 * There are few similar hacks people suggest online. This function uses the approach of hiding
 * the parent element for one animation frame and then restoring it one frame later.
 */
/* istanbul ignore next: This can only be test with real browse behavior. See "VideoPlayer Manual Testing Guide" */
export function forceReflow<E extends HTMLElement>(childEl: E | null): void {
	const parentEl = childEl?.parentElement;
	if (!(childEl && parentEl)) {
		return;
	}

	requestAnimationFrame(() => {
		const initialDisplay = parentEl.style.display;

		// Ensure repeat calls do not conflict with each other.
		if (initialDisplay === 'none') {
			return;
		}

		// We can avoid the hack if the child is not overflowing
		const childRect = childEl.getBoundingClientRect();
		const parentRect = parentEl.getBoundingClientRect();
		const isChildOverflowing = parentRect.width < childRect.width || parentRect.height < childRect.height;
		if (!isChildOverflowing) {
			return;
		}

		// Hide the parent for one frame.
		parentEl.style.display = 'none';

		// Restore the parent after one frame to trigger browser reflow.
		requestAnimationFrame(() => {
			parentEl.style.display = initialDisplay;
		});
	});
}
