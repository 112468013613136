import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	RestrictedAccessStarterRoleStarterIdQuery,
	RestrictedAccessStarterRoleStarterIdQueryVariables,
} from './getStarterId.graphql';

export async function getStarterId(
	utilities: Pick<UtilityContext, 'gql'>,
	slugOrId: string,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessStarterRoleStarterIdQuery,
		RestrictedAccessStarterRoleStarterIdQueryVariables
	>({
		// cannot be in a .graphql file at this point because this file is indirectly imported by webpack
		query: gql`
			query RestrictedAccessStarterRoleStarterId($slugOrId: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					user {
						id
					}
				}
			}
		`,
		variables: { slugOrId },
		rejectOnError: true,
	});

	return data?.petition?.user.id || undefined;
}
