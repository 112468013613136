export function identifyVariation(
	position: number,
	variants: ReadonlyArray<{ variant: string; percentage: number }>,
): string {
	let total = 0;
	for (const { variant, percentage } of variants) {
		total += percentage;
		if (position < total) {
			return variant;
		}
	}
	return variants[0] ? variants[0].variant : 'control';
}
