import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading, petitionDecisionMaker } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'decision-maker-complete-profile',
		path: '/p/:slug/responses/new/complete-profile',
		component: loadable(async () => import(/* webpackChunkName: "pages/decision_maker_complete_profile" */ './index'), {
			exportName: 'DecisionMakerCompleteProfile',
			fallback: Loading,
		}),
		frame: true,
		restrictedAccess: {
			authOnly: true,
			role: petitionDecisionMaker('slug'),
		},
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
