import { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import { usePetitionDetails } from '../../petitionDetails';

export function useHeadlineReplacementsPetition(): Record<string, string | number> {
	const petition = usePetitionDetails();
	const { getCountry } = useI18n();

	return useMemo(
		() => ({
			petition_name: petition.ask,
			petition_country: getCountry(petition.country.countryCode)?.name || '',
			petition_location: (() => {
				const { city } = petition;
				const { stateCode } = petition;
				const { countryCode } = petition.country;
				const countryName = getCountry(countryCode)?.name || '';
				const { formattedLocationString } = petition.user;
				const petitionLocation = [city, stateCode, countryName].filter((v): v is string => !!v).join(', ');
				return petitionLocation || formattedLocationString;
			})(),
		}),
		[petition, getCountry],
	);
}
