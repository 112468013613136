import type { JSX } from 'react';

import { FormSpy } from 'react-final-form';

import { InlineMessage } from '@change-corgi/design-system/components/alerts';

import { useFormI18n } from 'src/app/shared/form/hooks';
import type { I18nFieldValidatorResult } from 'src/app/shared/form/validators';

type Props = Readonly<{ error: I18nFieldValidatorResult }>;

function FormErrorMessage({ error }: Props): JSX.Element {
	const { getErrorMessage } = useFormI18n();

	return (
		<InlineMessage size="large" variant="error" my={16}>
			{getErrorMessage(error)}
		</InlineMessage>
	);
}

export function FormError(): JSX.Element {
	return (
		<FormSpy subscription={{ submitError: true, submitting: true }}>
			{({ submitError, submitting }) =>
				/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */ submitError && !submitting ? (
					<FormErrorMessage error={submitError} />
				) : null
			}
		</FormSpy>
	);
}
