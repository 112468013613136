import type { Locale } from '@change-corgi/config/locales';

import type { GeoScope, Place } from '../shared/types';

type GooglePlaceHookProps = { input: string; geoScope?: GeoScope; locale: Locale };

const LOCAL_TYPES = [
	'administrative_area_level_1',
	'administrative_area_level_2',
	'locality',
	'neighborhood',
	'sublocality',
];

const getAutocompleteOptions = (geoScope?: GeoScope) => {
	switch (geoScope) {
		case 'local':
			return { types: LOCAL_TYPES };
		case 'national':
			return { types: ['country'] };
		case 'global':
		default:
			return {};
	}
};

export async function getPlacePredictions(
	{ input, geoScope, locale }: GooglePlaceHookProps,
	autocompleteService?: google.maps.places.AutocompleteService,
): Promise<Place[]> {
	if (input === '') return [];

	const autocompleteOptions = getAutocompleteOptions(geoScope);

	const results = await autocompleteService?.getPlacePredictions({
		...autocompleteOptions,
		input,
		language: locale,
	});

	return (
		results?.predictions.map((prediction) => ({
			displayName: prediction.description,
			googlePlacesId: prediction.place_id,
		})) || []
	);
}
