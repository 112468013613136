import type { ComponentProps, JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

// TODO move to DS as an form component option?
// TODO use in SAP
export function RemainingCharacters({
	count,
	...rest
}: {
	count: number;
} & ComponentProps<typeof Box>): JSX.Element {
	return (
		<Text as="div" sx={{ display: 'flex', justifyContent: 'flex-end' }} {...rest}>
			<VisuallyHidden as="span">
				{/* FIXME the i18n key should not be sap-specific */}
				<Translate
					value="corgi.sap.ai_description.personal_prompt.a11y.remaining_characters"
					replacements={{ numRemainingCharacters: count }}
				/>
			</VisuallyHidden>{' '}
			<Box aria-hidden>{count}</Box>
		</Text>
	);
}
