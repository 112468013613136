import { useMemo } from 'react';

import { focus } from '../shared/focus';
import type { FocusFallbackListenerContext } from '../types';

import { useGetFocusFallbacks } from './useGetFocusFallbacks';

type TriggerContext = Pick<FocusFallbackListenerContext, 'removedElement' | 'removedFocusedElement'> & {
	element: HTMLElement;
};

export function useFocusFallbackTrigger(): ((context: TriggerContext) => void) | undefined {
	const getFocusFallbacks = useGetFocusFallbacks();

	return useMemo(() => {
		if (!getFocusFallbacks) return undefined;

		return (context: TriggerContext) => {
			getFocusFallbacks(context.element).some((fallback) =>
				fallback.listener({
					...context,
					container: fallback.ref.current,
					focus,
				}),
			);
		};
	}, [getFocusFallbacks]);
}
