import { normalizeNumber } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PROMOTIONS_INELIGIBLE_PETITION_REFUND_LIMIT = createFcmConfig(
	'promotions_ineligible_petition_refund_limit',
	{
		normalizer: normalizeNumber,
		defaultValue: 100000,
	},
);
