import { useEffect } from 'react';

import uniq from 'lodash/fp/uniq';

import { getAppRoutesIdMap } from '../getAppRoutesIdMap';

export function usePreloadRoutes(preload?: readonly string[]): void {
	useEffect(() => {
		preload &&
			uniq(preload).forEach((id) => {
				getAppRoutesIdMap()[id]?.component.preload();
			});
	}, [preload]);
}
