import { createFcmContext } from 'src/app/shared/contexts/fcm';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: MembershipReminderPopupFcmContext,
	FcmProvider: MembershipReminderPopupFcmContextProvider,
	useFcm: useMembershipReminderPopupFcm,
	useAsyncFcm: useAsyncMembershipReminderPopupFcm,
	prefetchFcm: prefetchMembershipReminderPopupFcm,
} = createFcmContext({
	name: 'MembershipReminderPopupFcmContext',
	dataProperty: 'fcm',
	configs: {},
	prefetch: true,
});

export type MembershipReminderPopupFcmState = ReturnType<typeof useAsyncMembershipReminderPopupFcm>;
export type MembershipReminderPopupFcm = LoadedStateData<MembershipReminderPopupFcmState>;
