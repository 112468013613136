import { useMemo } from 'react';
import type { JSX } from 'react';

import { useCurrentRouteConfig } from 'src/app/routes';

import { SkipToMainContentButton } from './components/SkipToMainContentButton';
import { focusMainContent } from './shared/focusMainContent';

export function SkipToMainContent(): JSX.Element | null {
	const routeConfig = useCurrentRouteConfig();
	// checking if the current route has header frame
	const hideFrameHeader = useMemo(() => {
		if (!routeConfig) return true;
		if (!routeConfig.config.frame) return true;
		if (routeConfig.config.frame === true) return false;
		return !routeConfig.config.frame.header;
	}, [routeConfig]);

	if (hideFrameHeader) return null;
	return <SkipToMainContentButton onClick={focusMainContent} />;
}
