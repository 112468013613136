import type { PropsWithChildren } from 'react';

import { useIsMembershipEligibleUser } from 'src/app/shared/hooks/membership';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { MembershipAwarenessBannerDataContextProvider } from './data';
import { MembershipAwarenessBannerFcmContextProvider } from './fcm';
import { MembershipAwarenessBannerFcmExperimentsContextProvider } from './fcmExperiments';
import type { MembershipAwarenessBannerPrefetchedContext } from './hook';
import { useContext } from './hook';

type Props = {
	prefetchedData: MembershipAwarenessBannerPrefetchedContext | undefined;
};

export function MembershipAwarenessBannerContextProvider({
	children,
	prefetchedData,
}: PropsWithChildren<Props>): React.JSX.Element | null {
	const contextState = useContext(prefetchedData);
	const session = useSessionAsync();

	const isMembershipEligibleUser = useIsMembershipEligibleUser();

	// not displaying a loader when in a loading state because it might result in nothing
	if (!isLoaded(contextState) || !isLoaded(session)) return null;

	const { data: componentData, fcm, fcmExperiments } = contextState;

	if (!isMembershipEligibleUser || (session.value.user?.totalSignatureCount ?? 0) < 1) return null;

	return (
		<MembershipAwarenessBannerDataContextProvider data={componentData}>
			<MembershipAwarenessBannerFcmContextProvider fcm={fcm}>
				<MembershipAwarenessBannerFcmExperimentsContextProvider fcmExperiments={fcmExperiments}>
					{children}
				</MembershipAwarenessBannerFcmExperimentsContextProvider>
			</MembershipAwarenessBannerFcmContextProvider>
		</MembershipAwarenessBannerDataContextProvider>
	);
}
