export function isInViewport(element: Element): boolean {
	const rect = element.getBoundingClientRect();
	// only checking if top-left is in viewport
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.left <= (window.innerWidth || document.documentElement.clientWidth)
	);
}
