import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: {
			type: 'boolean',
		},
		earliest_petition_published_at: {
			type: 'string',
		},
		minimum_signature_count: {
			type: 'number',
		},
	},
});

export const FCM_STARFISH_STARTER_DASHBOARD_SIGNATURE_SOURCES = createFcmConfig(
	'starfish_starter_dashboard_signature_sources',
	{
		normalizer: createJsonNormalizer(normalizer),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		defaultValue: {
			enabled: false,
			earliest_petition_published_at: '2024-03-01', // 'yyyy-mm-dd'
			minimum_signature_count: 5,
		} as ReturnType<typeof normalizer>,
	},
);
