import { createPrefetchableAsyncDataContext } from 'src/app/shared/contexts/asyncData';

import { getMembershipBottomBannerData } from './api';

export const {
	Context: MembershipBottomBannerDataContext,
	Provider: MembershipBottomBannerDataContextProvider,
	useContext: useMembershipBottomBannerData,
	useAsyncData: useMembershipBottomBannerDataFromApi,
	prefetchAsyncData: prefetchMembershipBottomBannerDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'MembershipBottomBannerDataContext',
	dataProperty: 'data',
	getData: getMembershipBottomBannerData,
	getUniqueId: ({ countryCode }) => countryCode,
	hookDeps: ({ countryCode }) => [countryCode],
});
