export function isExperimentNameABandit(name: string): boolean {
	return !!/^(mit-|fht|washarecopy|cl_sharecopy|bandit-)/.exec(name);
}

export const PSF_COMBO_QUERY_PARAM_DATA_NAME = 'cbd_s';

export type PsfComboQueryParamData = {
	amountId: string;
	comboBanditAmount: number;
	experimentName: string;
	variantName: string;

	variant: {
		pulls: number;
		rewards: number;
		variantName: string;
		data: {
			amount: number;
			amount_id: string;
		};
	};
};
