import type { ComponentProps, JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useBadgeScore } from './hooks/useBadgeScore';

type BadgeScoreProps = ComponentProps<typeof Box> & { score: number; smallText?: boolean };
export function BadgeScore({ score, ...rest }: BadgeScoreProps): JSX.Element {
	const {
		data: { labelKey, color, backgroundColor },
	} = useBadgeScore(score);
	return (
		<Flex sx={{ alignItems: 'center', gap: 16, width: '100%' }} {...rest}>
			<Box data-qa="score-container" sx={{ display: 'grid', placeItems: 'center', minWidth: 72 }}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 16 16"
					fill={backgroundColor}
					sx={{ gridColumn: '1/-1', gridRow: '1/-1' }}
				>
					<path d="M7.5.29a1 1 0 0 1 1 0l5.93 3.42c.3.18.5.51.5.87v6.84a1 1 0 0 1-.5.87L8.5 15.7a1 1 0 0 1-1 0L1.57 12.3a1 1 0 0 1-.5-.87V4.58a1 1 0 0 1 .5-.87L7.5.3Z" />
				</svg>
				<Text
					size="huge"
					color={color}
					fontWeight="bold"
					sx={{
						gridColumn: '1/-1',
						gridRow: '1/-1',
					}}
				>
					{score}
				</Text>
			</Box>
			<Text
				id="score-text"
				data-qa="score-text"
				breakWord
				sx={{ maxWidth: '10ch', width: 'max-content', hyphens: 'auto' }}
			>
				<Translate value={labelKey} />
			</Text>
		</Flex>
	);
}
