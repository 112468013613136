import type { ComponentType, JSX, PropsWithChildren } from 'react';

import type { UserInfoState } from 'src/app/pages/petition/shared/tabTypes';
import type { ErrorStateDetails } from 'src/app/shared/utils/async';
import { isError, isLoaded } from 'src/app/shared/utils/async';

import type {
	PetitionDetailsPageCombinedState,
	PetitionDetailsPagePrefetchedContext,
	PetitionDetailsPagePrefetchedUserContext,
} from './hook';
import { usePageContext } from './hook';
import { PetitionDetailsPageDataContextProvider } from './pageData';
import { PetitionDetailsPageFcmContextProvider } from './pageFcm';
import { PetitionDetailsPageStateContextProvider } from './pageState';
import { PetitionDetailsPageUserDataContextProvider } from './pageUserData';
import { PetitionDetailsPageUserInfoContextProvider } from './pageUserInfo/context';

export type ErrorHandlerProps = {
	context: ErrorStateDetails<PetitionDetailsPageCombinedState> | { error: 'user_info_load_failure'; cause?: unknown };
};

type Props = {
	slug: string;
	/**
	 * once we remove the tabs, the content of this should be moved to pageUserData
	 */
	userInfo: UserInfoState;
	prefetchedData: PetitionDetailsPagePrefetchedContext | undefined;
	prefetchedUserData: PetitionDetailsPagePrefetchedUserContext | undefined;
	errorHandler: ComponentType<ErrorHandlerProps>;
	loadingFallback: JSX.Element;
};

export function PetitionDetailsPageContextProvider({
	children,
	slug,
	userInfo,
	prefetchedData,
	prefetchedUserData,
	errorHandler: ErrorHandler,
	loadingFallback,
}: PropsWithChildren<Props>): JSX.Element | null {
	const pageContextState = usePageContext(slug, prefetchedData, prefetchedUserData);

	if (isError(pageContextState)) {
		const { status, ...rest } = pageContextState;
		return <ErrorHandler context={rest} />;
	}
	if (isError(userInfo)) {
		return <ErrorHandler context={{ error: 'user_info_load_failure', cause: userInfo.cause }} />;
	}
	if (!isLoaded(pageContextState) || !isLoaded(userInfo)) return loadingFallback;

	const { pageData, pageUserData, pageFcm } = pageContextState;

	return (
		<PetitionDetailsPageDataContextProvider pageData={pageData}>
			<PetitionDetailsPageUserInfoContextProvider userInfo={userInfo.info}>
				<PetitionDetailsPageUserDataContextProvider pageUserData={pageUserData}>
					<PetitionDetailsPageFcmContextProvider pageFcm={pageFcm}>
						<PetitionDetailsPageStateContextProvider>{children}</PetitionDetailsPageStateContextProvider>
					</PetitionDetailsPageFcmContextProvider>
				</PetitionDetailsPageUserDataContextProvider>
			</PetitionDetailsPageUserInfoContextProvider>
		</PetitionDetailsPageDataContextProvider>
	);
}
