import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { HttpClient } from '@change-corgi/core/http';
import { createHttpClient as _createHttpClient } from '@change-corgi/core/http';

import webappInfo from 'src/webappInfo';

type Options = Readonly<{
	errorReporter: ErrorReporter;
}>;

export function createHttpClient({ errorReporter }: Options): HttpClient {
	return _createHttpClient({
		reportNetworkError: errorReporter.createSampledReporter(0.01),
		headers: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-requested-with': `corgi-front-end-browser:${webappInfo.version}`,
		},
	});
}
