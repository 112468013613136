import Cookies from 'js-cookie';

import type { Experiments } from '@change-corgi/core/experiments';
import {
	createExperiments as _createExperiments,
	extractExperimentOverridesFromQueryString,
} from '@change-corgi/core/experiments';
import type { GqlClient } from '@change-corgi/core/gql';
import type { Session } from '@change-corgi/core/session';
import { getLocation } from '@change-corgi/core/window';

type Options = Readonly<{
	gql: GqlClient;
	session: Session;
}>;

export function createExperiments({ gql, session }: Options): Experiments {
	return (
		_createExperiments({
			gqlFetch: gql.fetch.bind(gql),
			cookies: Cookies,
			experiments: session.experiments || null,
			overrides: extractExperimentOverridesFromQueryString(getLocation().search, {
				ignoreQueryPrefix: true,
			}),
		})
			// this will save the overrides coming from the query string to the cookies
			.persistOverrides()
			.decorateWindow()
	);
}
