import { useContext } from 'react';

import { SignFormContext } from './context';

/**
 * Used to generate different ids based on whether the form is inside the mobile modal or not
 * to avoid having the same id twice in the DOM
 */
export function useGetFieldId(): (id: string) => string {
	const context = useContext(SignFormContext);
	if (!context || !context.insideMobileModal) {
		return (id) => id;
	}
	return (id) => `mobile___${id}`;
}
