import { createFcmContext } from 'src/app/shared/contexts/fcm';
import type { LoadedStateData } from 'src/app/shared/utils/async';

import { configs } from './constants';

export { configs } from './constants';

export const {
	FcmContext: PetitionDetailsPageFcmContext,
	FcmProvider: PetitionDetailsPageFcmContextProvider,
	useFcm: usePetitionDetailsPageFcm,
	useAsyncFcm: useAsyncPetitionDetailsPageFcm,
	prefetchFcm: prefetchPetitionDetailsPageFcm,
} = createFcmContext({
	name: 'PetitionDetailsPageFcmContext',
	dataProperty: 'pageFcm',
	configs,
	prefetch: true,
});

export type PetitionDetailsPageFcmState = ReturnType<typeof useAsyncPetitionDetailsPageFcm>;
export type PetitionDetailsPageFcm = LoadedStateData<PetitionDetailsPageFcmState>;
