import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { RedirectError } from 'src/shared/error';

import type { SupporterVoicesConfig } from 'src/app/pages/petitionGamma/details/shared/api';
import { getSupporterVoices, getSupporterVoicesConfig } from 'src/app/pages/petitionGamma/details/shared/api';
import { getPetitionId } from 'src/app/shared/api/petitions';
import { differenceInDays } from 'src/app/shared/utils/dates';

import type { GammaPetitionDetailsDataQuery, GammaPetitionDetailsDataQueryVariables } from './api.graphql';
import { GammaPetitionDetailsData } from './api.graphql';
import { getContactDmConfig } from './getContactDmConfig';
import { getPetitionStrengthScore } from './getPetitionStrengthScore';
import type { PetitionDetailsPageData } from './types';

const EMPTY_MEDIA_HITS: NonNullable<GammaPetitionDetailsDataQuery['mediaHitsPage']> = {
	isLastPage: true,
	numberOfStarterMedia: 0,
	mediaHits: [],
};

async function resolvePetitionId(slugOrId: string, utilityContext: UtilityContext): Promise<string> {
	if (Number(slugOrId)) return slugOrId;
	return (await getPetitionId(slugOrId, utilityContext)) || '';
}

async function fetchPetitionDetails(utilityContext: UtilityContext, variables: GammaPetitionDetailsDataQueryVariables) {
	const {
		gql: { fetch },
	} = utilityContext;
	const { data } = await fetch<GammaPetitionDetailsDataQuery, GammaPetitionDetailsDataQueryVariables>({
		query: GammaPetitionDetailsData,
		variables,
		rejectOnError: true,
		// to separate query from other batched queries
		important: true,
	});
	return data;
}

export async function getPetitionDetailsPageData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{
		locale,
		countryCode,
		loadBandits,
		supporterVoicesConfig,
	}: {
		locale: string;
		countryCode: string;
		loadBandits: boolean;
		supporterVoicesConfig?: SupporterVoicesConfig;
	},
): Promise<PetitionDetailsPageData> {
	const petitionId = await resolvePetitionId(slugOrId, utilityContext);
	if (!petitionId) throw new RedirectError({ url: '/?petition_not_found=true' });

	const { dmContactsToFetch, emailBody, emailSubjectLine } = await getContactDmConfig(utilityContext);

	const config = supporterVoicesConfig || (await getSupporterVoicesConfig(utilityContext, slugOrId));

	const data = await fetchPetitionDetails(utilityContext, {
		slugOrId,
		petitionId,
		locale,
		countryCode,
		loadBandits,
		dmContactsToFetch,
		commentsSortBy: config.sortByScoreEnabled ? 'SCORE' : 'POPULAR',
	});

	if (!data?.petition) {
		throw new RedirectError({ url: '/?petition_not_found=true' });
	}

	const supporterVoicesData = await getSupporterVoices(
		utilityContext,
		slugOrId,
		config,
		data?.petition?.videoSettingsMetadata.disableDisplay,
	);

	return {
		petition: data.petition,
		recommendedDecisionMakers: data.recommendedDecisionMakers?.items || [],
		createdLessThanOneDayAgo: differenceInDays(new Date(), new Date(data.petition.createdAt)) < 1,
		score: getPetitionStrengthScore(data.petition),
		supporterVoicesData,
		emailDm: { emailSubjectLine, emailBody },
		mediaHitsPage: data.mediaHitsPage ?? EMPTY_MEDIA_HITS,
	};
}
