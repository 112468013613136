import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { InlineMessage } from '@change-corgi/design-system/components/alerts';
import { Box } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { useEmailVerificationModalBody } from './hooks/useEmailVerificationModalBody';

type Props = {
	path: string;
	onContinueAsGuest?: () => void;
	userId: string;
};

export function EmailVerificationModalBody({ path, onContinueAsGuest, userId }: Props): JSX.Element {
	const {
		data: { resendEmailResult },
		actions: { resendVerificationEmail },
	} = useEmailVerificationModalBody(path, userId);

	return (
		<>
			{resendEmailResult === 'success' && (
				<InlineMessage variant="success" role="alert" p={8} mb={64}>
					<Translate value="fe.pages.sponsors.verify_email.verify_email_resent" />
				</InlineMessage>
			)}
			{resendEmailResult === 'error' && (
				<InlineMessage variant="error" role="alert" p={8} mb={64}>
					<Translate value="fe.errors.sponsors.send_verify_email" />
				</InlineMessage>
			)}
			<Heading as="h1" size={['subtitle', 'h3']} sx={{ textAlign: 'center' }}>
				<Translate value="fe.pages.sponsors.verify_email.verify_email" />
			</Heading>
			<Text as="div" sx={{ textAlign: 'center' }} mt={16}>
				<Translate value="fe.pages.sponsors.verify_email.require_verify" />
			</Text>
			<Box backgroundColor="primary-greyBackground" my={24} p={8}>
				<Text as="div" sx={{ textAlign: 'center' }}>
					<Translate value="fe.pages.sponsors.verify_email.check_verify" />{' '}
					<FakeLink onClick={resendVerificationEmail}>
						<Translate value="fe.pages.sponsors.verify_email.resend_email" />
					</FakeLink>
				</Text>
			</Box>
			{onContinueAsGuest && (
				<Text as="div" sx={{ textAlign: 'center' }} mt={40} mb={24}>
					<FakeLink onClick={onContinueAsGuest}>
						<Translate value="fe.pages.sponsors.guest_login" />
					</FakeLink>
				</Text>
			)}
		</>
	);
}
