import { useCallback, useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

import { useYearlyMembershipExperimentAmounts } from 'src/app/shared/hooks/membership';

import {
	useMembershipBottomBannerData,
	useMembershipBottomBannerFcm,
	useMembershipBottomBannerFcmExperiments,
	useMembershipBottomBannerState,
} from '../../../../../../context';

type Result = ModelHookResult<
	{
		buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string; link: string }>;
		selectedAmount: { amount: number; amountLocalized: string };
		buttonText: string;
		otherLink: string;
		ctaButtonLink: string;
		paymentTerm: string;
	},
	{
		onClick: () => void;
	}
>;

type Props = {
	slug: string;
	onClick: () => void;
};

// eslint-disable-next-line max-lines-per-function
export function useButtonContainer(props: Props): Result {
	const track = useTracking();
	const { translate, amountToBaseUnits } = useI18n();
	const {
		data: { currency, subscriptionConfig },
	} = useMembershipBottomBannerData();
	const { yearlyContributionsExperimentAmountsConfig } = useMembershipBottomBannerFcm();
	const { showYearlyContributions } = useMembershipBottomBannerFcmExperiments();
	const [{ paymentTerm }] = useMembershipBottomBannerState();
	const yearlyContributionsExperimentAmounts = useYearlyMembershipExperimentAmounts();

	const encodedURI = useMemo(() => encodeURIComponent(props.slug), [props.slug]);

	const generateLink = useCallback(
		(amount: number) => {
			return `/p/${encodedURI}/psf/membership?source_location=petition_membership_popup&amount=${amount}&payment_term=${paymentTerm}`;
		},
		[encodedURI, paymentTerm],
	);

	const buttonAmounts = useMemo(() => {
		if (!subscriptionConfig?.amounts) return [];
		return subscriptionConfig.amounts.map(({ amount, amountLocalized }) => {
			return {
				amount,
				amountLocalized,
				link: generateLink(amountToBaseUnits(amount, currency)),
			};
		});
	}, [amountToBaseUnits, currency, generateLink, subscriptionConfig]);

	const contributionsAmounts = useMemo(() => {
		return yearlyContributionsExperimentAmounts({
			yearlyContributionsExpVariation: showYearlyContributions.variation,
			yearlyContributionsAmounts: yearlyContributionsExperimentAmountsConfig,
			paymentTerm,
			buttonAmounts: subscriptionConfig?.amounts ?? [],
			currency,
		});
	}, [
		currency,
		paymentTerm,
		showYearlyContributions.variation,
		subscriptionConfig?.amounts,
		yearlyContributionsExperimentAmounts,
		yearlyContributionsExperimentAmountsConfig,
	]);

	const yearlyContributionsExperimentAmountsResult = useMemo(() => {
		if (showYearlyContributions.variation !== 'control') {
			return contributionsAmounts.map(({ amount, amountLocalized }) => {
				return {
					amount,
					amountLocalized,
					link: generateLink(amountToBaseUnits(amount, currency)),
				};
			});
		}
		return buttonAmounts;
	}, [
		amountToBaseUnits,
		buttonAmounts,
		contributionsAmounts,
		currency,
		generateLink,
		showYearlyContributions.variation,
	]);

	const onClick = useCallback(() => {
		void track('petition_membership_popup_click');
		props.onClick();
	}, [track, props]);

	const selectedAmount = useMemo(() => {
		return contributionsAmounts[1] ?? subscriptionConfig?.defaultAmount ?? { amount: 0, amountLocalized: '0' };
	}, [contributionsAmounts, subscriptionConfig?.defaultAmount]);

	return {
		data: {
			selectedAmount,
			buttonAmounts: yearlyContributionsExperimentAmountsResult,
			buttonText: translate('fe.pages.sponsors.continue'),
			otherLink: generateLink(0),
			ctaButtonLink: generateLink(amountToBaseUnits(selectedAmount.amount, currency)),
			paymentTerm,
		},
		actions: {
			onClick,
		},
	};
}
