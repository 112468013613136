import React from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import { iconFavorite, iconFavoriteBorder } from '@change-corgi/design-system/icons';

type LikeIconProps = {
	videoId: string;
};

export function LikeIcon({ videoId }: LikeIconProps): React.JSX.Element {
	return (
		<Icon
			icon={iconFavoriteBorder}
			size="l"
			sx={{
				color: 'primary-black',
			}}
			data-testid={`supporter-video-${videoId}-like-icon`}
		/>
	);
}
export function UnLikeIcon({ videoId }: LikeIconProps): React.JSX.Element {
	return (
		<Icon
			icon={iconFavorite}
			size="l"
			sx={{
				color: 'primary-changeRed',
			}}
			data-testid={`supporter-video-${videoId}-unlike-icon`}
		/>
	);
}
