import { useEffect } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { getShortUrls } from '../api/getShortUrls';
import { useShortUrlsState } from '../context';

export function useLoadQueue(): void {
	const [state, { removeFromQueue, setLoaded, setError }] = useShortUrlsState();
	const utilityContext = useUtilityContext();

	useEffect(() => {
		if (!state.queue.length) return;
		// copying the contents because queue is mutated by the state mutators
		const queue = [...state.queue];
		(async () => {
			// removing the items from the queue to make sure we're not making the request multiple times
			removeFromQueue(queue);
			try {
				setLoaded(await getShortUrls(queue, utilityContext));
			} catch (e) {
				setError(queue);
			}
		})();
	}, [utilityContext, state, removeFromQueue, setLoaded, setError]);
}
