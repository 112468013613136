import { countrySettings } from '@change-corgi/config/legacy/countries';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { getSubscriptionConfig } from 'src/app/shared/api/payments';

type SubscriptionConfig =
	| Pick<NonNullable<Awaited<ReturnType<typeof getSubscriptionConfig>>>, 'amounts' | 'defaultAmount'>
	| undefined;

export type MembershipBottomBannerData = {
	subscriptionConfig?: SubscriptionConfig;
	currency: string;
};

export async function getMembershipBottomBannerData(
	utilityContext: UtilityContext,
	{
		countryCode,
	}: {
		countryCode: string;
	},
): Promise<MembershipBottomBannerData> {
	const currency = countrySettings[countryCode]?.currency || 'USD';

	const subscriptionConfig = await getSubscriptionConfig(utilityContext, currency);

	return {
		currency,
		subscriptionConfig: subscriptionConfig
			? { amounts: subscriptionConfig.amounts, defaultAmount: subscriptionConfig.defaultAmount }
			: undefined,
	};
}
