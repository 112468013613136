import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useSession, useSessionAsync } from './session';
import type { AsyncState } from './shared/types';

export function useSessionUuid(): string {
	return useSession().uuid;
}
export function useSessionUuidAsync(): AsyncState<string> {
	return useMappedLoadedState(useSessionAsync(), ({ value }) => ({ value: value.uuid }));
}
