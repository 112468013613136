import { addScript } from '@change-corgi/core/dom';
import type { ReportableError } from '@change-corgi/core/errorReporter/common';

import { reportAddScriptError } from './reportAddScriptError';

export async function addScriptWithErrorReporting(
	url: string,
	reportError: (error: ReportableError) => Promise<{
		url?: string | undefined;
	}>,
): Promise<unknown> {
	// eslint-disable-next-line promise/avoid-new
	return new Promise<unknown>((resolve) => {
		void addScript(url)
			// eslint-disable-next-line promise/prefer-await-to-then
			.catch((e) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				void reportAddScriptError(url, reportError, e);
			})
			// eslint-disable-next-line promise/prefer-await-to-then
			.then(() => resolve(undefined));
	});
}
