import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-bulk-delete-signatures',
		path: '/dashboard/bulk-delete-signatures',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_bulk_delete_signatures" */ './index'), {
			exportName: 'AdminBulkDeleteSignatures',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['supportAdmin', 'engineering'],
		},
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
