import { useSessionStorageValueAsync } from 'src/app/shared/hooks/storage';

type Result = {
	bannerClosed: boolean;
	setBannerClosed: (val: string | null) => void;
};

export function useBannerClosedStateStorage(): Result {
	const [bannerClosed, setBannerClosed] = useSessionStorageValueAsync('membershipBottomBannerClosed');

	return {
		bannerClosed: bannerClosed === undefined || bannerClosed === 'true',
		setBannerClosed,
	};
}
