import { Radio, RadioGroup } from '@change-corgi/design-system/components/forms';
import { Box, Flex } from '@change-corgi/design-system/layout';

type Props = {
	paymentTerm: 'one-time' | 'monthly' | 'yearly';
	name: string;
	showYearlyPaymentTerm: boolean;
	showOneTimePaymentTerm: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function MembershipPaymentTermSelector({
	paymentTerm,
	onChange,
	name,
	showYearlyPaymentTerm,
	showOneTimePaymentTerm,
}: Props): React.JSX.Element | null {
	if (!showYearlyPaymentTerm) return null;

	return (
		<Box sx={{ pb: 16 }}>
			<RadioGroup id={name} data-qa="payment-term-selector" defaultValue={paymentTerm}>
				<Flex sx={{ flex: 1, flexDirection: 'row' }}>
					{!!showOneTimePaymentTerm && (
						<Flex sx={{ flex: 1 }}>
							<Radio
								label="One-time"
								value="one-time"
								name={name}
								data-testid={`${name}-one-time-radio`}
								checked={paymentTerm === 'one-time'}
								onChange={onChange}
							/>
						</Flex>
					)}
					<Flex sx={{ flex: 1 }}>
						<Radio
							label="Monthly"
							value="monthly"
							name={name}
							data-testid={`${name}-monthly-radio`}
							checked={paymentTerm === 'monthly'}
							onChange={onChange}
						/>
					</Flex>
					{!!showYearlyPaymentTerm && (
						<Flex sx={{ flex: 1 }}>
							<Radio
								label="Yearly"
								value="yearly"
								name={name}
								data-testid={`${name}-yearly-radio`}
								checked={paymentTerm === 'yearly'}
								onChange={onChange}
							/>
						</Flex>
					)}
				</Flex>
			</RadioGroup>
		</Box>
	);
}
