import { useCallback } from 'react';

import type { PerimeterXEnforcerChallengeWithBlockedUrl } from '@change-corgi/core/perimeterx';

import { usePerimeterXCaptcha } from 'src/app/shared/components/perimeterx';

import { usePerimeterXTracking } from './usePerimeterXTracking';

export function usePaymentFormPerimeterXCaptcha(
	form: string,
): ({
	pxResponse,
	operationName,
}: {
	pxResponse: PerimeterXEnforcerChallengeWithBlockedUrl;
	operationName?: string;
}) => Promise<void> {
	const { trackFailure, trackSuccess, trackShow } = usePerimeterXTracking(form);

	const triggerCaptcha = usePerimeterXCaptcha();

	return useCallback(
		async ({ pxResponse, operationName }) => {
			trackShow(operationName);
			const result = await triggerCaptcha({ pxResponse });
			if (result) {
				trackSuccess(pxResponse, operationName);
			} else {
				trackFailure(pxResponse, operationName);
			}
		},
		[triggerCaptcha, trackShow, trackSuccess, trackFailure],
	);
}
