import { useCallback } from 'react';

import type { PageRenderingButton, SupporterVideo } from '../../types';
import { useDeleteVideoModalBase } from '../useDeleteVideoModalBase';

type UseDeleteVideoModalProps = {
	petitionId: string;
	renderedOn: PageRenderingButton;
	afterDeleteAction: (videoId?: string) => void;
};

export function useDeleteVideoModal({
	petitionId,
	afterDeleteAction,
	renderedOn,
}: UseDeleteVideoModalProps): (video: SupporterVideo) => Promise<void> {
	const openModal = useDeleteVideoModalBase();

	const openDeleteModal = useCallback(
		async (video: SupporterVideo) => {
			const result = await openModal({
				petitionId,
				video,
				renderedOn,
			}).waitForResult();

			if (result?.value === 'success') {
				afterDeleteAction(video.id);
			}
		},
		[openModal, petitionId, afterDeleteAction, renderedOn],
	);

	return openDeleteModal;
}
