export type VideoSourceInfo = {
	src: string;
	preload: 'auto' | 'metadata' | 'none';
	poster?: string;
};

/**
 * Regular expression that matches a Cloudflare Stream video URL. The named groups can
 * be used to construct other CFS resource URLS:
 * 	1. Account ID
 * 	2. Cloudflare UID
 */
const CFS_VIDEO_RE = /https?:\/\/customer-(\w+)\.cloudflarestream\.com\/(\w+)\//;

/**
 * See: https://www.w3.org/TR/media-frags/#URIfragment-user-agent
 */
const URI_FRAGMENT = '#t=0.0001';

/**
 * Parses additional video source info from a video URL.
 *
 * For non-Cloudflare URLs, this returns the `src` property that should be used on the video
 * element. The video can be preloaded by the browser to display a frame as a poster. We add a tiny
 * fragment to the URL to offset the first frame which is sometimes blank.
 *
 *
 * For CloudflareStream URLs we are able to build extra information based on their API. The poster
 * image allows us to avoid preloading the video and (hopefully) reduce cost. See:
 * https://developers.cloudflare.com/api/operations/stream-videos-retrieve-video-details
 */
export function parseSourceInfoFromUrl(videoUrl: string): VideoSourceInfo {
	const cfsMatch = CFS_VIDEO_RE.exec(videoUrl);

	if (!cfsMatch) {
		return {
			src: videoUrl + URI_FRAGMENT,
			preload: 'auto',
		};
	}

	const [, accountId, cloudflareUid] = cfsMatch;
	const cfsThumbnailUrl = `https://customer-${accountId}.cloudflarestream.com/${cloudflareUid}/thumbnails/thumbnail.jpg`;

	return {
		src: videoUrl,
		preload: 'none',
		poster: cfsThumbnailUrl,
	};
}
