import { useCallback, useState } from 'react';

import type { SupporterComment } from '../../../../shared/types';

type Result = ModelHookResult<
	{
		isCommentLiked: boolean;
		commentLikeCount: number;
	},
	{
		updateLike: (like: boolean) => void;
	}
>;

export function useLikeCommentState({
	comment: { hasCurrentUserLiked, likeCount },
}: {
	comment: SupporterComment;
}): Result {
	const [isCommentLiked, setIsCommentLiked] = useState(hasCurrentUserLiked || false);
	const [commentLikeCount, setCommentLikeCount] = useState(likeCount || 0);

	const updateLike = useCallback(
		(like: boolean) => {
			setIsCommentLiked(like);
			if (like) {
				setCommentLikeCount(like !== isCommentLiked ? commentLikeCount + 1 : commentLikeCount);
			} else {
				setCommentLikeCount(like !== isCommentLiked ? commentLikeCount - 1 : commentLikeCount);
			}
		},
		[isCommentLiked, commentLikeCount],
	);

	return {
		data: {
			isCommentLiked,
			commentLikeCount,
		},
		actions: {
			updateLike,
		},
	};
}
