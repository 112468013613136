type Options = Readonly<{
	splitChar: string;
	checkContentsFit: () => boolean;
}>;

export function getTruncatedValue(el: Text, { splitChar, checkContentsFit }: Options): string {
	const initialValue = el.nodeValue;

	const chunks = (initialValue || '').split(splitChar);
	let min = 0;
	let max = chunks.length - 1;
	let bestValue = '';

	// using a binary search for performance
	while (min <= max) {
		const mid = Math.floor((min + max) / 2);

		const testValue = chunks.slice(0, mid).join(splitChar);

		// eslint-disable-next-line no-param-reassign
		el.nodeValue = testValue ? testValue + splitChar : testValue;

		if (checkContentsFit()) {
			bestValue = el.nodeValue;
			min = mid + 1;
		} else {
			max = mid - 1;
		}
	}

	// eslint-disable-next-line no-param-reassign
	el.nodeValue = initialValue;

	return bestValue;
}
