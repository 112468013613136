import type { SubEnvironment } from '@change-corgi/config/environments';
import { facebookAppIds } from '@change-corgi/config/facebook';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { Facebook } from '@change-corgi/core/facebook';
import { createFacebook as _createFacebook } from '@change-corgi/core/facebook';
import type { Session } from '@change-corgi/core/session';

type Options = Readonly<{
	errorReporter: ErrorReporter;
	session: Session;
	environment: EnvironmentUtils;
}>;

function getFacebookAppId(environment: EnvironmentUtils): string {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const env = (environment.getDemoEnvironment() as SubEnvironment) || environment.getEnvironment();
	return facebookAppIds[env] || facebookAppIds.development;
}

export function createFacebook({ errorReporter, environment, session }: Options): Facebook {
	const {
		locale: { localeCode: locale },
	} = session;

	const facebook = _createFacebook({
		appId: getFacebookAppId(environment),
		locale,
		reportError: errorReporter.createSampledReporter(0.01),
	});

	session.cookiePrefs.marketing && facebook.preload();

	return facebook;
}
