import type { LoginMethodMap, LoginOrJoinMethod, LoginOrJoinMethodShortcode } from './types';

export const LOGIN_METHOD_MAP: LoginMethodMap = {
	google: 'google_api',
	facebook: 'facebook_api',
	email: 'email',
};

export const LOGIN_OR_JOIN_METHOD_QUERY_KEY = 'met';
export const LOGIN_OR_JOIN_METHODS: Record<LoginOrJoinMethodShortcode, LoginOrJoinMethod> = {
	ep: 'email_pswd',
	mf: 'manual_fb',
	mg: 'manual_google',
	esnv: 'email_signup_no_verify',
	ev: 'email_verify',
};
export const NOT_KNOWN = 'not_known';

export const LOGIN_OR_SIGNUP_WIDTH = 400;
export const UNAUTHENTICATED_ERROR_MESSAGE = 'Unauthenticated';
