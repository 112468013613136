import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'account-settings',
		path: '/account_settings',
		component: loadable(async () => import(/* webpackChunkName: "pages/account_settings" */ './index'), {
			exportName: 'AccountSettings',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
		},
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: false,
		},
	},
];
