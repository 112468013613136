import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { RedirectError } from 'src/shared/error';

import { getPetitionId } from 'src/app/shared/api/petitions';

import type { SupporterVoicesConfig } from '../supporterVoicesConfig/types';

import type {
	GammaPetitionPageVideosQuery as PetitionPageVideosQuery,
	GammaPetitionPageVideosQueryVariables as PetitionPageVideosQueryVariables,
} from './api.graphql';

export type SupporterVoicesData = {
	supporterVoicesEnabled: boolean;
	videos?: PetitionPageVideosQuery | null;
	openVideoCreationFromPetitionDetails?: boolean;
};

const query = gql`
	query GammaPetitionPageVideos($petitionId: ID!, $pageOffset: Int!, $pageSize: Int!, $orderBy: String) {
		getPetitionVideosWithLikes(
			petitionId: $petitionId
			pageOffset: $pageOffset
			pageSize: $pageSize
			orderBy: $orderBy
		) {
			isLastPage
			petitionVideosWithLikes {
				id
				videoUrl
				likeCount
				createdAt
				hasCurrentUserLiked
				user {
					id
					shortDisplayName
				}
			}
		}
	}
`;

export const getSupporterVoices = async (
	utilityContext: UtilityContext,
	slugOrId: string,
	supporterVoicesConfig: SupporterVoicesConfig,
	disableDisplay?: boolean | null,
): Promise<SupporterVoicesData> => {
	let petitionId: string;

	if (Number(slugOrId)) petitionId = slugOrId;
	else petitionId = (await getPetitionId(slugOrId, utilityContext)) || '';

	if (!petitionId) throw new RedirectError({ url: '/?petition_not_found=true' });

	/**
	 * The supporter_voices_petition_details FCM is fetched first and if the feature should be
	 * disabled for the user, the fetch for the supporter videos is not called.
	 */
	if (!supporterVoicesConfig.supporterVoicesEnabled || disableDisplay) {
		return {
			supporterVoicesEnabled: false,
		};
	}

	const { data: videos } = await utilityContext.gql.fetch<
		NonNullable<PetitionPageVideosQuery>,
		PetitionPageVideosQueryVariables
	>({
		query,
		variables: { petitionId, pageOffset: 0, pageSize: 10, orderBy: 'voting_score' },
		important: true,
	});

	return {
		videos,
		...supporterVoicesConfig,
	};
};
