import { useMemo } from 'react';

import type { StateWithoutError } from 'src/app/shared/utils/async';

type AsyncShareChannel = never; // TODO omit async channels like gmail from ShareChannel

type AsyncResult = StateWithoutError<{ channels: Readonly<Record<AsyncShareChannel, boolean>> }>;

export function useAsyncAvailableShareChannels(): AsyncResult {
	// const gmail = useGmailAsync(); // gmail will need user info + fcm info

	return useMemo(
		() =>
			// TODO gmail
			// if (isLoading(gmail)) return { status: 'loading' };

			({
				status: 'loaded',
				channels: {
					// TODO
					// gmail
				},
			}),
		[],
	);
}
