export function getElementDepth(elt: Node): number {
	if (!document.body || !document.body.contains(elt)) return 0;
	let depth = 0;
	while (elt.parentNode) {
		depth += 1;
		// eslint-disable-next-line no-param-reassign
		elt = elt.parentNode;
	}
	return depth;
}
