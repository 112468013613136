import React from 'react';

import { Button } from '@change-corgi/design-system/components/actions';
import { iconOutlinedFlag } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';

import { ReportAbuseLink } from 'src/app/shared/components/abuse';

import type { SupporterComment } from '../../shared/types';

import { useReportComment } from './hooks/useReportComment';

type ReportCommentProps = {
	comment: SupporterComment;
	petitionId: string;
	petitionSlug: string;
};
export function ReportComment({ comment, petitionId, petitionSlug }: ReportCommentProps): React.JSX.Element | null {
	const {
		data: { canReportComment },
		actions: { handleReportClick },
	} = useReportComment({ petitionId, comment });

	if (!canReportComment) return null;

	return (
		<Flex onClick={handleReportClick}>
			<ReportAbuseLink
				type={comment.__typename === 'PetitionVideoComment' ? 'video_comment' : 'supporter_comment'}
				comment={{
					id: comment.id,
					likes: Number(comment.likeCount),
					role: comment.__typename === 'PetitionVideoComment' ? 'VIDEO_COMMENT' : 'SUPPORTER_COMMENT',
				}}
				petitionId={petitionId}
				petitionSlug={petitionSlug}
			>
				<Button
					icon={iconOutlinedFlag}
					size="small"
					mode="icon"
					variant="secondary"
					aria-label={`report comment from ${comment.user?.shortDisplayName}`}
					data-testid={`supporter-comment-${comment.id}-report-button`}
					sx={{
						border: 'none',
						background: 'primary-greyBackground',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': {
							background: 'rgba(0,0,0,0.1)',
						},
					}}
				>
					Report
				</Button>
			</ReportAbuseLink>
		</Flex>
	);
}
