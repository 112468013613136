import { useMemo } from 'react';

import type { Session, SessionUser } from '@change-corgi/core/session';

import { useSessionMutations } from './mutations';

type Result = {
	setUser: (
		uuid: string,
		user: SessionUser,
		loginState: Exclude<Session['loginState'], 'GUEST'>,
		hasMembership: boolean,
	) => void;
	clearUser: (uuid: string) => void;
};

export function useSetCurrentUser(): Result {
	const { setUser, clearUser } = useSessionMutations();

	return useMemo(
		() => ({
			setUser: (
				uuid: string,
				user: SessionUser,
				loginState: Exclude<Session['loginState'], 'GUEST'>,
				hasMembership: boolean,
			) => {
				setUser({ uuid, user, loginState, hasMembership });
			},
			clearUser: (uuid: string) => {
				clearUser({ uuid });
			},
		}),
		[setUser, clearUser],
	);
}
