import { useCallback, useMemo, useState } from 'react';

import { isLoaded, isLoading } from 'src/app/shared/utils/async';

import type { GeoScope, Place } from '../shared/types';

import { usePlacePredictions } from './usePlacePredictions';

type PlaceFieldHookProps = {
	geoScope?: GeoScope;
	defaultPlaceValue: Place | null;
	onSelect?: (place: Place | null) => void;
};

type PlaceFieldState = {
	placeInputValue: string;
	onPlaceInputChange: (newInputValue: string) => void;
	placeValue: Place | null;
	onPlaceChange: (newValue: Place | null) => void;
	placePredictions: Place[];
};

export const usePlaceField = ({ geoScope, defaultPlaceValue, onSelect }: PlaceFieldHookProps): PlaceFieldState => {
	const [placeInputValue, setPlaceInputValue] = useState('');
	const [placeValue, setPlaceValue] = useState(defaultPlaceValue);

	const placePredictionsResult = usePlacePredictions({ input: placeInputValue, geoScope });
	const placePredictions = useMemo(
		() => (isLoaded(placePredictionsResult) ? placePredictionsResult.data : []),
		[placePredictionsResult],
	);

	const onPlaceInputChange = (newValue: string) => {
		setPlaceInputValue(newValue);
	};

	const onPlaceChange = useCallback(
		(selectedPlace: Place | null) => {
			if (isLoading(placePredictionsResult)) return;

			setPlaceValue(selectedPlace);
			onSelect?.(selectedPlace);
		},
		[onSelect, placePredictionsResult],
	);

	return {
		placeInputValue,
		onPlaceInputChange,
		placeValue,
		onPlaceChange,
		placePredictions,
	};
};
