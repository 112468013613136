import type { TrackingFn } from '@change-corgi/core/react/tracking';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

function getTrackingCommonData({ facebook }: UtilityContext) {
	return {
		location: 'facebook',
		facebook_api_version: facebook.apiVersion,
		facebook_sdk_hash: facebook.sdkHash,
		facebook_locale: facebook.locale,
	};
}

export function trackShareIntent(
	eventName: string,
	trackingData: Record<string, string | number | boolean | undefined> | undefined,
	utilityContext: UtilityContext,
	track: TrackingFn,
): void {
	void track(eventName, { ...getTrackingCommonData(utilityContext), ...trackingData });
}

export function trackShareSuccess(
	eventName: string,
	trackingData: Record<string, string | number | boolean | undefined> | undefined,
	utilityContext: UtilityContext,
	track: TrackingFn,
): void {
	void track(eventName, { ...getTrackingCommonData(utilityContext), ...trackingData });
}

// eslint-disable-next-line max-params
export function trackShareFailure(
	eventName: string,
	{ error }: { error: string },
	trackingData: Record<string, string | number | boolean | undefined> | undefined,
	utilityContext: UtilityContext,
	track: TrackingFn,
): void {
	void track(eventName, {
		...getTrackingCommonData(utilityContext),
		share_failure_msg: error,
		...trackingData,
	});
}

export function trackShareCancel(
	eventName: string,
	trackingData: Record<string, string | number | boolean | undefined> | undefined,
	utilityContext: UtilityContext,
	track: TrackingFn,
): void {
	void track(eventName, { ...getTrackingCommonData(utilityContext), ...trackingData });
}
