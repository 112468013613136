import React, { useCallback, useEffect, useState } from 'react';
import type { JSX, PropsWithChildren } from 'react';

import { Waypoint } from 'react-waypoint';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { WaypointCallbackArgs } from './shared/types';

type Props = {
	fallback?: React.ReactNode;
	topOffset?: Waypoint.WaypointProps['topOffset'];
	/**
	 * triggers an immediate load of the contents when the user is a bot,
	 * so that the content can be indexed by indexers
	 */
	immediateForBots?: boolean;
};

export function ScrollableComponent({
	children,
	fallback,
	topOffset,
	immediateForBots,
}: PropsWithChildren<Props>): JSX.Element {
	const {
		userAgent: { isBot },
	} = useUtilityContext();
	const [shouldDisplay, setShouldDisplay] = useState(false);

	const onPositionChange = useCallback(
		({ currentPosition }: WaypointCallbackArgs) => {
			setShouldDisplay((alreadyDisplayed) => alreadyDisplayed || currentPosition !== 'below');
		},
		[setShouldDisplay],
	);

	useEffect(() => {
		if (isBot() && immediateForBots) {
			setShouldDisplay(true);
		}
	}, [immediateForBots, isBot]);

	return (
		<>
			<Waypoint onPositionChange={onPositionChange} topOffset={topOffset} />
			{shouldDisplay ? children : fallback || null}
		</>
	);
}
