import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { GammaPetitionDetailsPageStatsQuery, GammaPetitionDetailsPageStatsQueryVariables } from './stats.graphql';

export type QueryVariables = {
	petitionSlugOrId: string;
	maxSigners: number;
	shouldFetchRecruiter: boolean;
	recruiterId: string;
};

export type PetitionStats = NonNullable<GammaPetitionDetailsPageStatsQuery['petitionStats']>;

const QUERY = gql`
	query GammaPetitionDetailsPageStats($petitionSlugOrId: String!) {
		petitionStats: petitionBySlugOrId(slugOrId: $petitionSlugOrId) {
			id
			signatureState {
				signatureCount {
					displayed
					displayedLocalized
				}
				signatureGoal {
					displayed
					displayedLocalized
				}
				signatureTargetLocalized
			}
		}
	}
`;

export async function getPetitionStats(
	petitionSlugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionStats | undefined> {
	const { data } = await fetch<GammaPetitionDetailsPageStatsQuery, GammaPetitionDetailsPageStatsQueryVariables>({
		query: QUERY,
		variables: {
			petitionSlugOrId,
		},
	});
	if (!data?.petitionStats) {
		return undefined;
	}
	return data.petitionStats;
}
