import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-users-merge-accounts',
		path: '/dashboard/users/merge_accounts',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_users_merge_accounts" */ './index'), {
			exportName: 'AdminUsersMergeAccounts',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['product', 'campaigns', 'support', 'userResearch'],
		},
		frame: true,
		ssr: {
			enabled: false,
			seo: false,
		},
	},
];
