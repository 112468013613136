import type { I18nFieldValidator, NonI18nFieldValidator, WithI18nConfig, WithNonI18nConfig } from './shared/types';

export function equalsIgnoreCaseValidator({
	i18n,
}: WithI18nConfig<EmptyObject>): I18nFieldValidator<string | undefined> {
	return (value, _, meta) => (!value || meta?.initial?.toLowerCase() === value.toLowerCase() ? undefined : i18n);
}

export function nonI18nEqualsIgnoreCaseValidator({
	message,
}: WithNonI18nConfig<{ message: string }>): NonI18nFieldValidator<string | undefined> {
	return (value, _, meta) => (!value || meta?.initial?.toLowerCase() === value.toLowerCase() ? undefined : message);
}
