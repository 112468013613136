import { useCallback } from 'react';

import { useSendVerificationEmail } from '../useSendVerificationEmail';

import { useEmailVerificationModal } from './useEmailVerificationModal';

type Props = { showContinueAsGuest: boolean; userId: string; path: string };

export function useInformVerificationEmail(): (props: Props) => Promise<void> {
	const sendVerificationEmail = useSendVerificationEmail();

	const openEmailVerificationModal = useEmailVerificationModal();

	return useCallback(
		async ({ showContinueAsGuest, userId, path }) => {
			// not great UX to be sending an email every time the page is loaded/refreshed
			void sendVerificationEmail(path, userId);
			await openEmailVerificationModal({ path, showContinueAsGuest, userId }).waitForClosed();
		},
		[sendVerificationEmail, openEmailVerificationModal],
	);
}
