import type { JSX } from 'react';

import { Translate, TranslateHtml } from '@change-corgi/core/react/i18n';
import type { SessionUser } from '@change-corgi/core/session';
import { Button, FakeLink } from '@change-corgi/design-system/components/actions';
import { InlineMessage } from '@change-corgi/design-system/components/alerts';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { isLoading } from 'src/app/shared/utils/async';

import { LoginForm } from './components/LoginForm';
import { useLoginModalForm } from './hooks/useLoginModalForm';

type Props = {
	user?: SessionUser;
	email: string;
	onSuccess: (user: SessionUser) => void;
};

export function LoginModalForm({ user, email: emailProp, onSuccess }: Props): JSX.Element {
	const {
		data: { userInfo, email, password, formState, resetPasswordState },
		actions: { setEmail, setPassword, attemptLogin, sendResetPasswordLink },
	} = useLoginModalForm({ user, email: emailProp, onSuccess });

	return (
		<Box>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					void attemptLogin();
				}}
				data-qa="login-form"
			>
				<LoginForm
					userInfo={userInfo}
					formState={formState}
					email={email}
					setEmail={setEmail}
					setPassword={setPassword}
					password={password}
				/>
				{resetPasswordState === 'error' && (
					<InlineMessage variant="error" role="alert" mb={8}>
						<TranslateHtml value="corgi.login_or_signup.error.network" />
					</InlineMessage>
				)}
				{resetPasswordState === 'success' && (
					<InlineMessage variant="success" role="alert" mb={8}>
						<TranslateHtml value="corgi.login_or_signup.email.reset" replacements={{ emailAddress: email }} />
					</InlineMessage>
				)}
				<Text as="div" sx={{ textAlign: 'right' }} size="small" mb={16}>
					<FakeLink type="button" data-qa="forgot-password" onClick={sendResetPasswordLink}>
						<Translate value="fe.components.login_or_join.forgot_password" />
					</FakeLink>
				</Text>
				<Box mt={16}>
					<Button
						type="submit"
						sx={{ width: '100%' }}
						disabled={!!formState && isLoading(formState)}
						data-testid="login-modal-submit-button"
					>
						<Translate value="fe.components.login_or_join.log_in" />
					</Button>
				</Box>
			</form>
		</Box>
	);
}
