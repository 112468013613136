import { useCallback } from 'react';

import type { ReminderModalOptions } from './useReminderModal';
import { useReminderModal } from './useReminderModal';

export function useRemind(): (options: ReminderModalOptions) => Promise<boolean> {
	const openReminderModal = useReminderModal();

	return useCallback(
		async (options) => {
			const modalResult = await openReminderModal(options).waitForResult();
			return !!modalResult?.choice;
		},
		[openReminderModal],
	);
}
export type { ReminderModalOptions };
