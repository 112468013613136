import type { ParsedQs } from 'qs';

import { getQueryStringSingleValue } from 'src/app/shared/utils/query';

/**
 * Returns token by looking at query param, searching for, in order:
 * - cs_tk
 * - sfmc_tk
 * - tk
 *
 * Token being returned is prefixed with its type (e.g. cs_<token>)
 *
 * If prefix parameter is present, it is added to the name of the query param (e.g. `cs_<prefix>_tk`, `<prefix>_tk`)
 */
export function getQueryParamToken(query: ParsedQs, prefix?: string): string | undefined {
	const prefixWithUnderscore = prefix ? `${prefix}_` : '';
	const csToken = getQueryStringSingleValue(query, `cs_${prefixWithUnderscore}tk`);
	const sfmcToken = getQueryStringSingleValue(query, `sfmc_${prefixWithUnderscore}tk`);
	const monorailToken = getQueryStringSingleValue(query, `${prefixWithUnderscore}tk`);

	if (csToken) return `cs_${csToken}`;
	if (sfmcToken) return `sfmc_${sfmcToken}`;
	return monorailToken;
}
