import type { LinkGroup } from '../../shared/types';

import { useCommunityLinks } from './community';
import { useCompanyLinks } from './company';
import { useConnectLinks } from './connect';
import { useGuideLinks } from './guide';
import { useSupportLinks } from './support';

export function useLinks(): ReadonlyArray<LinkGroup | null> {
	// prettier-ignore
	return [
		useCompanyLinks(),
		useCommunityLinks(),
		useSupportLinks(),
		useConnectLinks(),
		useGuideLinks(),
	];
}
