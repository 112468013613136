import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		path: '/p/:slug/reasons_for_signing',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { slug }, queryString }) => `/p/${slug!}/c${queryString}`,
		reason: 'deprecated-url',
	},
];
