import type { JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { FocusFallbackContext } from './context';
import { FocusFallbackListener } from './listener';

export function FocusFallbackProvider({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	const value = useMemo(() => ({ fallbacks: [] }), []);

	return (
		<FocusFallbackContext.Provider value={value}>
			<FocusFallbackListener>{children}</FocusFallbackListener>
		</FocusFallbackContext.Provider>
	);
}
