import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		block_all: { type: 'boolean', optional: true },
		block_petition_ids: { type: 'array', shape: { type: 'number' } },
	},
});

export const FCM_PACIFIC_PETITION_PROMOTION_CONFIG = createFcmConfig('pacific_petition_promotion_config', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {},
});
