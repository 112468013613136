import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		isEnabled: { type: 'boolean' },
		systemPrompt: { type: 'string' },
		prompt: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					replacer: {
						type: 'string',
					},
					text: {
						type: 'string',
					},
				},
			},
		},
		options: {
			optional: true,
			type: 'object',
			shape: {
				model: {
					optional: true,
					type: 'string',
				},
			},
		},
	},
});

export const FCM_STARFISH_PSF_TO_SAP_LOCAL_FLOW = {
	name: 'starfish_psf_to_sap_local_flow',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		isEnabled: false,
		systemPrompt: '',
		prompt: [],
		options: undefined,
	},
};
