import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'petition-updates-opt-out',
		path: '/account_settings/petition_updates_opt_out',
		component: loadable(
			async () => import(/* webpackChunkName: "pages/accountSettings_petitionUpdatesOptOut" */ './index'),
			{
				exportName: 'PetitionUpdatesOptOut',
				fallback: Loading,
			},
		),
		restrictedAccess: {
			nonGuestOnly: true,
		},
		frame: true,
	},
];
