export type PxParam =
	| 'petitionId'
	| 'userId'
	| 'emailDomain'
	| 'emailHash'
	| 'paymentDeclineCode'
	| 'accountDate'
	| 'countryCode';

// naming convention for perimeterX custom param
// window._pxParam1, window._pxParam2 etc up to a maximum of 10.
type PxParamIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type PxParamVariableName = `_pxParam${PxParamIndex}`;

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		/* eslint-disable @typescript-eslint/naming-convention */
		_pxParam1: unknown;
		_pxParam2: unknown;
		_pxParam3: unknown;
		_pxParam4: unknown;
		_pxParam5: unknown;
		_pxParam6: unknown;
		_pxParam7: unknown;
		_pxParam8: unknown;
		_pxParam9: unknown;
		_pxParam10: unknown;
		/* eslint-enable @typescript-eslint/naming-convention */
	}
}

const MAP: Record<PxParam, PxParamIndex> = {
	petitionId: 1,
	userId: 2,
	emailDomain: 3,
	emailHash: 4,
	paymentDeclineCode: 5,
	accountDate: 6,
	countryCode: 7,
};

export function getPxParamVariableName(param: PxParam): PxParamVariableName {
	return `_pxParam${MAP[param]}`;
}
