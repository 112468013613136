import type { MutableRefObject } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { useFocusFallbackManualTrigger } from './useFocusFallbackManualTrigger';
import { useFocusFallbackRef } from './useFocusFallbackRef';
import { useLazyLoadedListFocusRefs } from './useLazyLoadedListFocusRefs';

type ListEvent =
	| {
			type: 'loadMore';
			/**
			 * the size of the list when "load more" was triggered
			 */
			itemsCount: number;
			/**
			 * will manually trigger a focus fallback
			 * can be useful when the load more button is not actually removed due to synchronous page loading
			 */
			triggerFocusFallback?: boolean;
	  }
	| {
			type: 'deleteItem';
			/**
			 * the index of the element that was deleted
			 */
			index: number;
	  };

type LastEvent =
	| {
			type: 'none';
	  }
	| {
			type: 'loadMore';
			firstNewItemIndex: number;
			previousItemsCount: number;
	  }
	| {
			type: 'deleteItem';
			deletedItemIndex: number;
	  };
type Result = {
	/**
	 * the ref that should be set on the list's container
	 */
	containerRef: MutableRefObject<null>;
	/**
	 * the ref that should be set on the loading element (like a spinner or a placeholder) that will be focused while loading
	 */
	loadingRef: MutableRefObject<null>;
	/**
	 * the ref that should be set on the "load more" button that will be focused if nothing else can
	 */
	loadMoreRef: MutableRefObject<null>;
	/**
	 * a function that returns the ref that should be set each item's focusable part
	 */
	getListItemRef: (itemIndex: number) => MutableRefObject<null> | undefined;
	/**
	 * function to call when a "load more" or an item deletion is requested
	 */
	recordListEvent: (event: ListEvent) => void;
	/**
	 * information about the last event ("load more" or item deletion) to allow for rendering related info (e.g. a11y alerts)
	 */
	lastEvent: LastEvent;
};

function getLastEvent(listEvent: ListEvent | undefined): LastEvent {
	if (!listEvent) {
		return { type: 'none' };
	}
	switch (listEvent.type) {
		case 'deleteItem':
			return { type: 'deleteItem', deletedItemIndex: listEvent.index };
		case 'loadMore':
			return {
				type: 'loadMore',
				firstNewItemIndex: listEvent.itemsCount,
				previousItemsCount: listEvent.itemsCount,
			};
		default:
			return { type: 'none' };
	}
}

export function useLazyLoadedListFocus(): Result {
	const { loadMoreRef, loadingRef, itemRef, lastItemRef } = useLazyLoadedListFocusRefs();

	const [lastEvent, setLastEvent] = useState<ListEvent | undefined>(undefined);

	const containerRef = useFocusFallbackRef(
		({ focus, container }) =>
			focus(itemRef.current) ||
			focus(loadingRef.current) ||
			focus(loadMoreRef.current) ||
			focus(lastItemRef.current) ||
			focus(container),
	);
	const triggerFocusFallback = useFocusFallbackManualTrigger();

	const getListItemRef = useCallback(
		(itemIndex: number) => {
			if (!lastEvent) return undefined;
			switch (lastEvent.type) {
				case 'deleteItem':
					if (itemIndex === lastEvent.index) return itemRef;
					break;
				case 'loadMore':
					if (itemIndex === lastEvent.itemsCount) return itemRef;
					if (itemIndex === lastEvent.itemsCount - 1) return lastItemRef;
					break;
				default:
					break;
			}
			return undefined;
		},
		[lastEvent, itemRef, lastItemRef],
	);

	const resultLastEvent = useMemo((): LastEvent => getLastEvent(lastEvent), [lastEvent]);

	const recordListEvent = useCallback(
		(event: ListEvent) => {
			const currentLoadMoreRef = loadMoreRef.current;
			if (event.type === 'loadMore' && event.triggerFocusFallback && currentLoadMoreRef) {
				setTimeout(() => triggerFocusFallback(currentLoadMoreRef), 1);
			}
			setLastEvent(event);
		},
		[triggerFocusFallback, setLastEvent, loadMoreRef],
	);

	return {
		containerRef,
		loadMoreRef,
		loadingRef,
		getListItemRef,
		recordListEvent,
		lastEvent: resultLastEvent,
	};
}
