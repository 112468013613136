import { useCallback, useState } from 'react';

import type { UploaderMethodProps } from 'src/app/shared/components/imageUpload';
import type { MutationState } from 'src/app/shared/utils/async';

import { saveImage } from './api';
import type { ImageData } from './types';

type RequestState = MutationState<{ data: ImageData }, { error: string }>;

type Uploader = UploaderMethodProps['upload'];
type UseSaveImageOptions = { onSuccess?: (image: ImageData) => void; onError?: (error: string) => void };
type UseSaveImageReturn = RequestState & {
	mutate: () => Promise<Extract<RequestState, { status: 'loaded' } | { status: 'error' }>>;
	reset: () => void;
};

export function useSaveImage(uploader: Uploader, { onSuccess, onError }: UseSaveImageOptions = {}): UseSaveImageReturn {
	const [state, setState] = useState<RequestState>({ status: 'idle' });

	const handleSaveImage = useCallback(async () => {
		setState({ status: 'loading' });

		try {
			const result = await saveImage(uploader);
			const successStatus = { status: 'loaded' as const, data: result };
			setState(successStatus);
			onSuccess?.(result);
			return successStatus;
		} catch (e) {
			const errorMessage = e instanceof Error ? e.message : 'Something went wrong';
			const errorStatus = { status: 'error' as const, error: errorMessage };
			setState(errorStatus);
			onError?.(errorMessage);
			return errorStatus;
		}
	}, [onError, onSuccess, uploader]);

	const handleReset = useCallback(() => {
		setState({ status: 'idle' });
	}, []);

	return { mutate: handleSaveImage, reset: handleReset, ...state };
}
