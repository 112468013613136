import type { SignatureSharedCreateSignatureMutation } from './create.graphql';
import { isPendingSignatureAvailable } from './shared/isPendingSignatureAvailable';
import type { SignPetitionResult } from './types';

export function normalizeSignPetitionResponse(
	gqlResponse: NonNullable<SignatureSharedCreateSignatureMutation['signPetition']>,
): SignPetitionResult {
	switch (gqlResponse.__typename) {
		case 'SignPetitionMutationSuccess':
			return { status: 'success', redirectUrl: gqlResponse.redirectUrl };
		case 'SignPetitionMutationInvalidCaptcha':
			return { status: 'error', reason: 'captcha' };
		case 'SignPetitionMutationGraylisted':
			return { status: 'error', reason: 'grayListed' };
		case 'SignPetitionMutationInvalidEmail':
			return { status: 'error', reason: 'invalidEmail' };
		case 'SignPetitionMutationGuestSignByExistingUserFailure':
			return {
				status: 'error',
				reason: 'guestSignByExistingUser',
				user: gqlResponse.user,
				pendingAvailable: isPendingSignatureAvailable(gqlResponse.user),
			};
		default:
			throw new Error('Unexpected sign petition mutation response typename');
	}
}
