import { useQuery } from '@tanstack/react-query';

import { createQRCode } from 'src/app/shared/utils/qrcode';

export const QUERY_KEY = 'hooks-qrcode';

type UseQRCodeOptions = { value: string; size: number; includeMargin?: boolean };
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useQRCode({ value, size, includeMargin = true }: UseQRCodeOptions) {
	return useQuery({
		queryKey: [QUERY_KEY, { value, size, includeMargin }],
		queryFn: async () => createQRCode(value, size, includeMargin),
	});
}
