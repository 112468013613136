import type { AppRoute } from 'src/shared/routes';

import { APP_ROUTES } from './routes';

const APP_ROUTES_ID_MAP = APP_ROUTES.reduce<Record<string, AppRoute>>((acc, route) => {
	return {
		...acc,
		[route.id]: route,
	};
}, {});

export function getAppRoutesIdMap(): Readonly<Record<string, AppRoute>> {
	return APP_ROUTES_ID_MAP;
}
