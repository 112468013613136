import { getElementDepth } from 'src/app/shared/utils/dom';

import type { FocusFallback } from '../types';

export function orderFallbacksByDepthDesc(fallbacks: readonly FocusFallback[]): readonly FocusFallback[] {
	return fallbacks
		.map(
			(fallback) =>
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[fallback, fallback.ref.current ? getElementDepth(fallback.ref.current) : 0] as [FocusFallback, number],
		)
		.sort(([_fallback1, depth1], [_fallback2, depth2]) => depth2 - depth1)
		.map(([fallback]) => fallback);
}
