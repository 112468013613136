import { useMemo } from 'react';

import { useLoadImage } from './useLoadImage';

/**
 * If images is tagged as processing, the hook will regularly check if it's available.
 * Once it is, it will return an updated image object with processing:false
 */
export function useProcessedImage<IMAGE extends { url: string; processing: boolean } | null | undefined>(
	image: IMAGE,
): IMAGE {
	const loaded = useLoadImage(image?.url, { processing: image?.processing, strategy: 'exponentialBackoff' });

	return useMemo(() => {
		if (!image || !image.processing) return image;
		return !loaded ? image : { ...image, processing: false };
	}, [image, loaded]);
}

/**
 * If images is tagged as processing, the hook will regularly check if it's available.
 * Until it is, it will return undefined. Once it is, it will return the url.
 */
export function useProcessedImageUrl<IMAGE extends { url: string; processing: boolean } | null | undefined>(
	image: IMAGE,
): string | undefined {
	const res = useProcessedImage(image);
	return !res || res.processing ? undefined : res.url;
}
