import { useMemo } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';
import type { PetitionDetails } from 'src/app/pages/petition/details/shared/types';

/**
 * @deprecated use usePetitionDetailsPageData instead
 */
export function usePetitionDetails(): PetitionDetails {
	const { data } = usePetitionDetailsPageData();

	return useMemo(() => {
		const { petition, ...rest } = data;
		return { ...petition, ...rest };
	}, [data]);
}
