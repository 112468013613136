import type { JSX } from 'react';

import { FaviconContainer } from './Favicon';
import { MetaContainer } from './Meta';

export function HeadContainer(): JSX.Element {
	return (
		<>
			<MetaContainer />
			<FaviconContainer />
		</>
	);
}
