import type { JSX } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { isSsr } from '@change-corgi/core/ssr';
import { getWindow } from '@change-corgi/core/window';
import { Separator } from '@change-corgi/design-system/components/content';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconAutoAwesome } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { ReportAIIssueLink } from 'src/app/shared/components/ReportAIIssueLink';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/app/shared/components/tooltips';

type Props = {
	content: JSX.Element;
	tooltipContent: JSX.Element;
};

export function AiGeneratedContent({ content, tooltipContent }: Props): JSX.Element {
	const { translate } = useI18n();

	return (
		<>
			<Flex sx={{ alignItems: 'center' }}>
				<Tooltip>
					<TooltipTrigger>
						<Flex
							sx={{
								alignItems: 'center',
								bg: 'primary-greyBackground',
								borderRadius: 'standard',
								marginRight: 16,
								paddingX: 16,
								paddingY: 8,
							}}
						>
							<Icon
								icon={iconAutoAwesome}
								aria-label={translate('corgi.components.ai_content.label')}
								color="typography-secondary"
								role="img"
								size="m"
								mr={4}
								sx={{ display: 'inline-block' }}
							/>
							<Text color="typography-secondary" size="caption">
								<Translate value="corgi.components.ai_content.label" />
							</Text>
						</Flex>
					</TooltipTrigger>
					<TooltipContent>{tooltipContent}</TooltipContent>
				</Tooltip>
				<Separator sx={{ flex: 1 }} />
			</Flex>
			<Text as="p" pt={8} pb={16}>
				{content}
			</Text>

			<ReportAIIssueLink pageUrl={isSsr() ? undefined : getWindow().location.href} type="generic" />
		</>
	);
}
