import { useEffect, useMemo } from 'react';
import type { JSX } from 'react';

import once from 'lodash/fp/once';
import { Helmet } from 'react-helmet-async';

import type { PerimeterXEnforcerChallengeWithBlockedUrl } from '@change-corgi/core/perimeterx';
import { Translate } from '@change-corgi/core/react/i18n';
import { getWindow } from '@change-corgi/core/window';
import { Button } from '@change-corgi/design-system/components/actions';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { PX_FAKE_CHALLENGE_APP_ID } from 'src/app/shared/utils/perimeterx';

// This component renders an in-line <div> whose contents are populated by a
// script included from our vendor PerimeterX, presenting a captcha (Google
// reCAPTCHA) challenge to users suspected of being bots. When the captcha is
// completed by the user, onSuccess() or onFailure() will be called as
// appropriate.
//
// See the PerimeterX sample implementation here:
// https://github.com/PerimeterX/perimeterx-abr-samples

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		_pxOnCaptchaSuccess: unknown;
	}
}

type Props = {
	pxResponse: PerimeterXEnforcerChallengeWithBlockedUrl;
	onSuccess?: () => void;
	onFailure?: () => void;
};

export function PerimeterXCaptcha({ pxResponse, onSuccess, onFailure }: Props): JSX.Element {
	// This should set window._pxOnCaptchaSuccess exactly once, and clean it up
	// when the component gets destroyed.
	useEffect(() => {
		const win = getWindow();
		win._pxOnCaptchaSuccess = once((isValid) => {
			if (isValid) onSuccess?.();
			else onFailure?.();
		});
		return () => {
			delete win._pxOnCaptchaSuccess;
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const script = useMemo(
		() => `
		window._pxBlockedUrl = '${pxResponse.blockedUrl}';
		window._pxAppId = '${pxResponse.appId}';
		window._pxJsClientSrc = '${pxResponse.jsClientSrc}';
		window._pxFirstPartyEnabled = ${String(pxResponse.firstPartyEnabled)};
		window._pxVid = '${pxResponse.vid}';
		window._pxUuid = '${pxResponse.uuid}';
		window._pxHostUrl = '${pxResponse.hostUrl}';
	`,
		[pxResponse],
	);

	return (
		<>
			<Helmet>
				<script>{script}</script>
				<script src={pxResponse.blockScript} />
			</Helmet>
			{/* using tabindex=0 to allow for tabbing inside the iframe (https://github.com/focus-trap/focus-trap-react/issues/459#issuecomment-915585105) */}
			<Text as="p" pb={16} tabIndex={0}>
				<Translate value="fe.components.px_captcha.preamble" />
			</Text>
			<Box variant="bordered" py={16} px={[0, 16]} my={24} sx={{ boxShadow: '0 2px 3px 0 rgba(54,49,53,0.2)' }}>
				{/* The vendor code specifically looks for a div with this id: */}
				<Flex id="px-captcha" sx={{ justifyContent: ['center', 'flex-start'] }} />
			</Box>
			{/* using tabindex=0 to allow for tabbing inside the iframe (https://github.com/focus-trap/focus-trap-react/issues/459#issuecomment-915585105) */}
			<Text as="p" tabIndex={0}>
				<Translate value="fe.components.px_captcha.helptext" />
			</Text>
			{pxResponse.appId === PX_FAKE_CHALLENGE_APP_ID && (
				<>
					<Separator my={8} />
					<Text as="p" pb={8} tabIndex={0}>
						This looks like a test challenge {'=>'} you can simulate success or failure
					</Text>
					<Box pb={8}>
						<Button type="button" onClick={() => onSuccess?.()} size="small">
							Simulate success
						</Button>
					</Box>
					<Box>
						<Button type="button" onClick={() => onFailure?.()} size="small">
							Simulate failure
						</Button>
					</Box>
					<Separator my={8} />
				</>
			)}
			<Text as="p" py={16} mt={16} color="typography-secondary" size="caption">
				<Translate value="fe.components.px_captcha.reference_id" /> {pxResponse.uuid}
			</Text>
		</>
	);
}
