import { useMemo } from 'react';

import type { PetitionSignatureCountState } from 'src/app/pages/petitionGamma/details/shared/types';

import { usePetitionDetails } from '../petitionDetails/petitionDetails';

import { usePetitionStats } from './petitionStats';

export function usePetitionSignatureCount(): PetitionSignatureCountState {
	const petitionDetails = usePetitionDetails();
	const petitionStatsState = usePetitionStats();

	const stats = useMemo(
		() =>
			petitionStatsState.status === 'loaded'
				? {
						petitionId: petitionStatsState.stats.id,
						upToDate: true as const,
						signatureCount: petitionStatsState.stats.signatureState.signatureCount,
						signatureGoal: petitionStatsState.stats.signatureState.signatureGoal,
						signatureTargetLocalized: petitionStatsState.stats.signatureState.signatureTargetLocalized,
					}
				: {
						petitionId: petitionDetails.id,
						upToDate: false as const,
						signatureCount: petitionDetails.signatureState.signatureCount,
						signatureGoal: petitionDetails.signatureState.signatureGoal,
						signatureTargetLocalized: petitionDetails.signatureState.signatureTargetLocalized,
					},
		[petitionStatsState, petitionDetails],
	);

	return stats;
}
