/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { useCallback, useState } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { getHistory, getLocation } from '@change-corgi/core/window';

import {
	loginOrSignupByFacebook,
	LoginOrSignupByFacebookError,
	requestFacebookPermissions,
} from 'src/app/shared/api/auth';

import { useSocialAuthTracking } from '../shared/hooks/useSocialAuthTracking';
import { useTrackSuccessSocial } from '../shared/hooks/useTrackSuccess';

export type FacebookLoginResultData = { existingUser: boolean };
type FacebookLoginResult =
	| { status: 'not_started' | 'loading' | 'cancelled' }
	| { status: 'error'; errorReason: 'STAFF_SAML' | 'OTHER' }
	| { status: 'success'; data: FacebookLoginResultData };

type FacebookLoginInput = {
	signupContext?: string;
	fbLoginType: string;
	optionalOnClickTrackProps?: Record<string, string | boolean>;
};

export function useFacebookLoginOrSignup(): {
	attemptFacebookLogin: (input: FacebookLoginInput, onWebview?: () => void) => Promise<void>;
	result: FacebookLoginResult;
} {
	const context = useUtilityContext();
	const track = useTracking();

	const [result, setResult] = useState<FacebookLoginResult>({ status: 'not_started' });
	const { trackLoginOrJoinAttemptSocial } = useSocialAuthTracking();
	const trackSuccessSocial = useTrackSuccessSocial();
	const attemptFacebookLogin = useCallback(
		async ({ fbLoginType, signupContext, optionalOnClickTrackProps }: FacebookLoginInput, onWebview?: () => void) => {
			void track('fb_attempt_login', { user_flow: fbLoginType, ...optionalOnClickTrackProps });
			trackLoginOrJoinAttemptSocial({
				loginOrSignupContext: 'combined',
				loginOrJoinMethod: 'manual_fb',
				hasSetPassword: false,
				userFlow: fbLoginType,
			});
			if (context.userAgent.getWebviewType() !== 'unknown') {
				const currentUrl = new URL(getLocation().href);
				currentUrl.searchParams.set('fb_authed', '1');
				getHistory().replaceState({}, '', currentUrl.toString()); // ignore dont_auto_login_via_facebook cookie when returning
				onWebview && onWebview();
			}

			setResult({ status: 'loading' });
			try {
				const fbAuthResponse = await requestFacebookPermissions({ context });
				// with FB Login for Business, accessToken and signedRequest can be undefined (https://github.com/DefinitelyTyped/DefinitelyTyped/pull/66759)
				if (!fbAuthResponse || !fbAuthResponse.accessToken || !fbAuthResponse.signedRequest) {
					setResult({ status: 'cancelled' });
					return;
				}
				const loginResult = await loginOrSignupByFacebook(
					{
						accessToken: fbAuthResponse.accessToken,
						signedRequest: fbAuthResponse.signedRequest,
						signupContext,
					},
					context,
				);
				const existingUser = loginResult !== 'CREATED';
				await trackSuccessSocial({
					loginMethod: 'facebook',
					loginOrJoinMethod: 'manual_fb',
					loginType: existingUser ? 'facebook_login' : 'facebook_signup',
					newUser: !existingUser,
					userFlow: fbLoginType,
				});
				setResult({
					status: 'success',
					data: {
						existingUser: loginResult !== 'CREATED',
					},
				});
			} catch (e) {
				if (e instanceof LoginOrSignupByFacebookError && e.status === 'INVALID_LOGIN_METHOD') {
					setResult({ status: 'error', errorReason: 'STAFF_SAML' });
				} else {
					setResult({ status: 'error', errorReason: 'OTHER' });
				}
			}
		},
		[context, track, trackLoginOrJoinAttemptSocial, trackSuccessSocial],
	);

	return { attemptFacebookLogin, result };
}
