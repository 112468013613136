import { type MutableRefObject, useEffect } from 'react';

type RefObject<T> = MutableRefObject<T>;

type Ref = RefObject<Element | Document | undefined | null>;
type Callback = () => void;
export function useScrollEnd(ref: Ref, callback: Callback): Ref {
	useEffect(() => {
		const el = ref.current;
		if (el) {
			el.addEventListener('scrollend', callback);
		}

		return () => {
			if (!el) return;
			el.removeEventListener('scrollend', callback);
		};
	}, [callback, ref]);

	return ref;
}
