import { useEffect } from 'react';

import { useAcceptMembershipReminderModal } from 'src/app/shared/hooks/membership';

import { useMembershipReminderPopupFcmExperiments } from '../context';

export function MembershipReminderPopup(): null {
	const {
		showMembershipReminderModal: { fcm, variation },
	} = useMembershipReminderPopupFcmExperiments();
	const openReminderModalPopup = useAcceptMembershipReminderModal();
	useEffect(() => {
		void openReminderModalPopup(variation, fcm.config);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return null;
}
