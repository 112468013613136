import { useCallback, useEffect, useState } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

type TrackingParameters = Parameters<ReturnType<typeof useTracking>>;

/**
 * This returns a tracking function that will send a tracking event after react has performed a
 * previously enqueued state update.
 *
 * This is useful for cases where we are using a page tracking context that makes use of state values from a parent
 * page state context to derive tracking event properties.
 *
 * Using this will ensure that any state updates that coincide with a tracking event being fired will have the new
 * state reflected in the event properties.
 *
 * @example
 * In an event handler:
 * setAmount(amount);
 * trackAmountChanged();
 *
 * In trackAmountChanged:
 * void deferredTrack('pp_promotion_amount_changed');
 *
 * Result: tracking properties that depend on amount will correspond to the amount set with the setAmount() call.
 */
export function useDeferredTrack(): (...args: TrackingParameters) => void {
	const track = useTracking();
	const [queue, setQueue] = useState<readonly TrackingParameters[]>([]);

	useEffect(() => {
		if (!queue.length) return;
		queue.forEach((args) => {
			void track(...args);
		});
		setQueue([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue]); // we only want to call track when this changes, ignore other deps

	return useCallback((...args) => {
		setQueue((current) => [...current, args]);
	}, []);
}
