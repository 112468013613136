import { useMemo } from 'react';

import { usePetitionDetails } from '../../petitionDetails';

export function useHeadlineReplacementsDm(): Record<string, string | number> {
	const petition = usePetitionDetails();

	return useMemo(
		() => ({
			petition_name_dm: petition.displayTitle,
			primary_dm_name: petition.primaryDecisionMakerConnection.nodes[0]?.displayName || '',
		}),
		[petition],
	);
}
