import { useMemo } from 'react';

import { useClosedModalTracking } from './closedModal';
import type { ClosedModalTrackingResult } from './closedModal';
import type { SignWithEmailButtonClickTrackingResult } from './emailButtonClick';
import { useEmailButtonClickTracking } from './emailButtonClick';
import type { SignatureErrorTrackingResult } from './error';
import { useSignatureErrorTracking } from './error';
import type { SignatureIntentTrackingResult } from './intent';
import { useSignatureIntentTracking } from './intent';
import { useSignatureMobileIntentTracking } from './mobileIntent';
import type { SignatureMobileIntentTrackingResult } from './mobileIntent';

type SignatureTrackingResult = SignatureMobileIntentTrackingResult &
	SignatureIntentTrackingResult &
	SignatureErrorTrackingResult &
	SignWithEmailButtonClickTrackingResult &
	ClosedModalTrackingResult;

export function useSignatureTracking(): SignatureTrackingResult {
	const mobileIntentTracking = useSignatureMobileIntentTracking();
	const intentTracking = useSignatureIntentTracking();
	const errorTracking = useSignatureErrorTracking();
	const emailButtonClickTracking = useEmailButtonClickTracking();
	const closedModalTracking = useClosedModalTracking();

	return useMemo(
		() => ({
			...mobileIntentTracking,
			...intentTracking,
			...errorTracking,
			...emailButtonClickTracking,
			...closedModalTracking,
		}),
		[mobileIntentTracking, intentTracking, errorTracking, emailButtonClickTracking, closedModalTracking],
	);
}
