import { useCallback } from 'react';

import type { ShareChannel } from '../shared/types';

import { useActionContext } from './actionContext';
import { getShareAction } from './shared/shareAction';

type Options<CH extends ShareChannel = ShareChannel> = {
	trackingEventName: string;
	getShortUrl: (channel: CH) => string;
	getText: (channel: CH, type: 'title' | 'body', context: { url: string }) => string;
	getTrackingData: (channel: CH) => Readonly<Record<string, boolean | string | number | undefined>>;
	onSuccess?: (channel: CH) => void | Promise<void>;
	onFailure?: (channel: CH) => void | Promise<void>;
};

export function useGetShareAction<CH extends ShareChannel = ShareChannel>({
	getShortUrl,
	getTrackingData,
	getText,
	trackingEventName,
	onSuccess,
	onFailure,
}: Options<CH>): (channel: CH) => () => Promise<boolean> {
	const actionContext = useActionContext();
	return useCallback(
		(channel) => async () => {
			const url = getShortUrl(channel);
			const success = await getShareAction(channel)(
				{
					url,
					trackingEventName,
					trackingData: getTrackingData(channel),
					text: {
						title: getText(channel, 'title', { url }),
						body: getText(channel, 'body', { url }),
					},
				},
				actionContext,
			);
			if (success) {
				void onSuccess?.(channel);
			} else {
				void onFailure?.(channel);
			}
			return success;
		},
		[actionContext, getShortUrl, getTrackingData, getText, onSuccess, onFailure, trackingEventName],
	);
}
