import { useMemo } from 'react';

import { useLocation } from 'react-router';

import type { MatchedRouteConfig } from '../shared/getRouteConfigFromPath';
import { getRouteConfigFromPath } from '../shared/getRouteConfigFromPath';

export function useCurrentRouteConfig(): MatchedRouteConfig | undefined {
	const location = useLocation();

	return useMemo(() => getRouteConfigFromPath(location.pathname), [location]);
}
