import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { SessionUser } from '@change-corgi/core/session';

import { getPetitionId } from 'src/app/shared/api/petitions';
import { FCM_SUPPORTER_VOICES_DETAILS_PAGE } from 'src/app/shared/fcm/configs';

import { userIsStaffOrStarter } from '../userIsStaffOrStarter';

import { getDigitFromSessionId } from './getDigitFromSessionId';
import type { SupporterVoicesConfig } from './types';

export const getSupporterVoicesConfig = async (
	utilityContext: UtilityContext,
	slugOrId: string,
	{
		sessionUUID,
		sessionUser,
	}: {
		sessionUser?: SessionUser | null;
		sessionUUID?: string;
	},
): Promise<SupporterVoicesConfig> => {
	let petitionId: string;
	if (Number(slugOrId)) petitionId = slugOrId;
	else petitionId = (await getPetitionId(slugOrId, utilityContext)) || '';

	const [{ supporterVoicesDetailsPageConfig }, staffOrStarter] = await Promise.all([
		utilityContext.fcm.get({
			supporterVoicesDetailsPageConfig: FCM_SUPPORTER_VOICES_DETAILS_PAGE,
		}),
		sessionUser ? userIsStaffOrStarter(utilityContext, petitionId) : false,
	]);

	if (!supporterVoicesDetailsPageConfig || !sessionUUID)
		return {
			supporterVoicesEnabled: false,
		};

	const { activatedPercentage, blocklist, openVideoCreationFromPetitionDetails } = supporterVoicesDetailsPageConfig;

	const digit = !sessionUser ? getDigitFromSessionId(sessionUUID) : parseInt(sessionUser.id, 10) % 10;
	const threshold = activatedPercentage / 10;
	const isValidUser = staffOrStarter || digit < threshold;
	const isInBlocklist = blocklist?.some((item) => item.id === petitionId);

	const supporterVoicesEnabled = isValidUser && !isInBlocklist;

	return {
		supporterVoicesEnabled,
		openVideoCreationFromPetitionDetails,
	};
};
