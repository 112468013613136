import type { UploaderMethodProps } from 'src/app/shared/components/imageUpload';

import type { ImageData } from './types';

type Uploader = UploaderMethodProps['upload'];

// `uploader` is making an api request the same way that the `fetch` on `utilityContext` does
export async function saveImage(uploader: Uploader): Promise<ImageData> {
	// this assumes only 1 image is being uploaded!!
	try {
		const { successful, failed } = await uploader();

		if (failed.length > 0) {
			throw new Error(failed[0].error);
		}

		if (!successful[0].uploadURL) {
			throw new Error('Bad URL returned from upload');
		}

		return successful[0];
	} catch (e: unknown) {
		// handle the unknown, although i don't think uploader can throw
		const message = e instanceof Error ? e.message : 'Unexpected error';
		throw new Error(message);
	}
}
