// TODO check if visible?
export function isFocusable(elt: Element | null | undefined): boolean {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (!elt || !(elt as HTMLElement).focus || !(elt as HTMLElement).matches) return false;
	if (elt.matches('input,select,textarea,button')) {
		return !elt.matches('[disabled]');
	}
	if (elt.matches('a,area')) {
		return true;
	}
	return elt.hasAttribute('tabindex');
}
