import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_TRANSACTION_FEE_UPSELL_CONFIGURATION = createFcmConfig('transaction_fee_upsell_configuration', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				membership: {
					type: 'object',
					shape: {
						enabled: { type: 'boolean' },
						ratio: { optional: true, type: 'number' },
					},
				},
			},
		}),
	),
	defaultValue: undefined,
});
