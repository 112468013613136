import type { JSX } from 'react';

import { GooglePlacesProvider } from 'src/app/shared/hooks/googlePlaces';

import { PlaceField } from './PlaceField';
import type { PlaceFieldProps } from './PlaceField';

export function PlaceFieldWrapper(props: PlaceFieldProps): JSX.Element {
	return (
		<GooglePlacesProvider>
			<PlaceField {...props} />
		</GooglePlacesProvider>
	);
}
