import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import type { CampaignTrackingErrorData, CampaignTrackingErrorName } from 'src/app/shared/hooks/campaignTracking';

import type { PageRenderingButton } from '../../../../shared/types';

type VideoLikeTrackingEvent =
	| 'supporter_voice_like_click'
	| 'supporter_voice_like_success'
	| 'supporter_voice_like_failure';

type VideoLikeTrackingOptions = {
	likeState: boolean;
	errors?: string;
};

type TrackLikeVideo = (trackingEvent: VideoLikeTrackingEvent, options?: VideoLikeTrackingOptions) => void;

type Result = ModelHookResult<
	undefined,
	{
		trackLikeVideo: TrackLikeVideo;
		trackError: (eventName: CampaignTrackingErrorName, e?: unknown, errorData?: CampaignTrackingErrorData) => void;
	}
>;

type UseLikeVideoTrackingParams = {
	videoLikeTrackingData: {
		petition_id: string;
		video_id: string;
		liker_id: string | undefined;
		page: PageRenderingButton;
	};
};

export function useLikeVideoTracking({ videoLikeTrackingData }: UseLikeVideoTrackingParams): Result {
	const { track, trackError } = useCampaignTracking();

	const trackLikeVideo: TrackLikeVideo = (trackingEvent, options) => {
		if (trackingEvent === 'supporter_voice_like_click') {
			void track(trackingEvent, {
				...videoLikeTrackingData,
				liked_state: options?.likeState,
			});
		}
		if (trackingEvent === 'supporter_voice_like_success') {
			void track(trackingEvent, {
				...videoLikeTrackingData,
				liked_state: options?.likeState,
			});
		}
		if (trackingEvent === 'supporter_voice_like_failure') {
			void track(trackingEvent, {
				...videoLikeTrackingData,
				liked_state: options?.likeState,
				errors: options?.errors,
			});
		}
	};

	return {
		actions: {
			trackLikeVideo,
			trackError,
		},
	};
}
