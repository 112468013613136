import { createCachePrefetchContextStateItemFunctions } from '@change-corgi/core/react/prefetch';

import { mutations, useShortUrlsState } from './context';

export const {
	useCachePrefetchContext: useShortUrlsCachePrefetchContext,
	createCachePrefetchContext: createShortUrlsCachePrefetchContext,
} = createCachePrefetchContextStateItemFunctions({
	cacheKey: 'shortUrls',
	useState: useShortUrlsState,
	mutations,
	initialState: { cache: {}, queue: [] },
});
