import { useCallback } from 'react';

import { useLocation } from 'react-router';

import { useNavigate } from '@change-corgi/core/react/router';
import type { TrackingFn } from '@change-corgi/core/react/tracking';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useCurrentUserAsync, useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useCanDeleteVideo } from '../../../shared/hooks/useCanDeleteVideo';
import { useDeleteVideoModal } from '../../../shared/hooks/useDeleteVideoModal';
import type { PageRenderingButton, SupporterVideo } from '../../../shared/types';

type Result = ModelHookResult<
	{ canDeleteVideo: boolean },
	{
		handleDeleteClick: () => Promise<void>;
	}
>;

export function useDeleteVideo({
	petitionId,
	video,
	renderedOn,
	afterDeleteAction,
}: {
	petitionId: string;
	video: SupporterVideo;
	renderedOn: PageRenderingButton;
	afterDeleteAction: (videoId?: string) => void;
}): Result {
	const navigate = useNavigate();
	const { track } = useCampaignTracking();
	const canDeleteVideo = useCanDeleteVideo(video);
	const openDeleteModal = useDeleteVideoModal({ petitionId, afterDeleteAction, renderedOn });

	const location = useLocation();

	const loginStateBase = useLoginStateAsync();
	const loginState = isLoaded(loginStateBase) ? loginStateBase.value : undefined;
	const currentUserBase = useCurrentUserAsync();
	const currentUser = isLoaded(currentUserBase) ? currentUserBase.value : undefined;

	const handleDeleteClick = useCallback(async () => {
		let trackingData: Parameters<TrackingFn>[1] = {
			petition_id: petitionId,
			deleter_id: currentUser?.id,
			loginState,
			page: renderedOn,
		};
		trackingData = video.videoUrl
			? { ...trackingData, video_id: video.id, video_creator_id: video.user?.id }
			: { ...trackingData, comment_id: video.id, comment_creator_id: video.user?.id };
		void track('supporter_voice_delete_button_click', trackingData);
		if (loginState === 'IDENTIFIED') {
			navigate(`/login_or_join/redirected?redirectTo=${location.pathname}`);
		} else {
			await openDeleteModal(video);
		}
	}, [currentUser, location, loginState, navigate, openDeleteModal, petitionId, renderedOn, track, video]);

	return {
		data: {
			canDeleteVideo,
		},
		actions: {
			handleDeleteClick,
		},
	};
}
