import type { ComponentProps, JSX } from 'react';

import { Image } from '@change-corgi/design-system/components/media';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Flex } from '@change-corgi/design-system/layout';

import { useProcessedImage } from 'src/app/shared/hooks/image';

type PhotoProps = {
	aspectRatio: '1/1' | '16/9';
	processing: boolean;
	alt: string;
	src: string;
};

export function Photo({
	aspectRatio,
	processing,
	src,
	alt,
	...rest
}: PhotoProps & ComponentProps<typeof Image>): JSX.Element {
	const image = useProcessedImage({ url: src, processing });

	if (image.processing) {
		return (
			<Flex
				sx={{ overflow: 'hidden', aspectRatio, alignItems: 'center', justifyContent: 'center', height: '100%' }}
				aria-hidden="true"
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
				{...(rest as any)}
			>
				<Loader size="xs" />
			</Flex>
		);
	}

	return (
		<Image
			src={image.url}
			alt={alt}
			fit="cover"
			sx={{
				aspectRatio,
				verticalAlign: 'top',
				height: '100%',
			}}
			loading="lazy"
			{...rest}
		/>
	);
}
