import { useState } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Image } from '@change-corgi/design-system/components/media';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

const DEFAULT_IMAGE = 'https://static.change.org/images/default-petition-darker.gif';
const TOP_LEFT_RADIUS = ['inherit', null, 0];
const BOTTOM_LEFT_RADIUS = 0;
const TOP_RIGHT_RADIUS = ['inherit'];
const BOTTOM_RIGHT_RADIUS = [0, null, 'inherit'];

type Props = {
	/**
	 * If undefined, the image loading state will be displayed. If empty string '', the default petition image will be displayed.
	 */
	imageUrl?: string;
	showVictory: boolean;
};

export function Media({ imageUrl, showVictory }: Props): JSX.Element {
	const [isError, setIsError] = useState(false);
	const showDefault = !imageUrl || isError;
	const showImageUrl = showDefault ? DEFAULT_IMAGE : imageUrl;

	return (
		<Box
			sx={{
				position: 'relative',
				overflow: 'hidden',
				width: ['100%', null, 260],
				height: [150, null, 236],
				borderTopLeftRadius: TOP_LEFT_RADIUS,
				borderBottomLeftRadius: BOTTOM_LEFT_RADIUS,
				borderTopRightRadius: TOP_RIGHT_RADIUS,
				borderBottomRightRadius: BOTTOM_RIGHT_RADIUS,
			}}
		>
			<Image
				fit={showDefault ? 'contain' : 'cover'}
				sx={{
					background: '#EBEBEB',
					width: '100%',
					height: '100%',
					// removes an unwanted padding under the image
					verticalAlign: 'top',
					borderTopLeftRadius: TOP_LEFT_RADIUS,
					borderBottomLeftRadius: BOTTOM_LEFT_RADIUS,
					borderTopRightRadius: TOP_RIGHT_RADIUS,
					borderBottomRightRadius: BOTTOM_RIGHT_RADIUS,
				}}
				src={showImageUrl}
				alt=""
				loading="lazy"
				onError={() => {
					setIsError(true);
				}}
			/>
			{showVictory && (
				<Box
					backgroundColor="primary-changeRed"
					color="typography-lightPrimary"
					p="8"
					sx={{ borderBottomRightRadius: 'standard', position: 'absolute', left: 0, top: 0 }}
				>
					<Text size="small">
						<Translate value="design-system.petition-card.victory-badge-label" />
					</Text>
				</Box>
			)}
		</Box>
	);
}
