import type { PrefetchContext, PrefetchedDataProps } from '@change-corgi/core/react/prefetch';

import { useCountryCode } from 'src/app/shared/hooks/l10n';
import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchMembershipBottomBannerDataFromApi, useMembershipBottomBannerDataFromApi } from './data';
import type { MembershipBottomBannerFcmState } from './fcm';
import { prefetchMembershipBottomBannerFcm, useAsyncMembershipBottomBannerFcm } from './fcm';
import type { MembershipBottomBannerFcmExperimentsState } from './fcmExperiments';
import {
	prefetchMembershipBottomBannerFcmExperiments,
	useAsyncMembershipBottomBannerFcmExperiments,
} from './fcmExperiments';

export type MembershipBottomBannerPrefetchedContext = Readonly<{
	data: Parameters<typeof useMembershipBottomBannerDataFromApi>[0];
}>;

export type MembershipBottomBannerCombinedState = MergedState<
	ReturnType<typeof useMembershipBottomBannerDataFromApi>,
	MembershipBottomBannerFcmState,
	MembershipBottomBannerFcmExperimentsState
>;

export function useContext(
	prefetchedData?: MembershipBottomBannerPrefetchedContext,
): MembershipBottomBannerCombinedState {
	const countryCode = useCountryCode();
	return useMergedStates(
		useMembershipBottomBannerDataFromApi(prefetchedData?.data, { countryCode }),
		useAsyncMembershipBottomBannerFcm(),
		useAsyncMembershipBottomBannerFcmExperiments(),
	);
}

export async function prefetchContext(context: PrefetchContext): Promise<MembershipBottomBannerPrefetchedContext> {
	const [data] = await Promise.all([
		prefetchMembershipBottomBannerDataFromApi(context, { countryCode: context.l10n.countryCode }),
		prefetchMembershipBottomBannerFcm(context),
		prefetchMembershipBottomBannerFcmExperiments(context),
	]);

	return { data };
}

export type MembershipBottomBannerPrefetchedDataProps = PrefetchedDataProps<MembershipBottomBannerPrefetchedContext>;
