import isEqual from 'lodash/isEqual';

import type { RawMediaCropInput } from 'src/app/shared/components/imageUpload';
import { MIN_IMAGE_DIMENSION_SIZE } from 'src/app/shared/hooks/uppyUploader';

export const getImageFileType = (image: string | undefined): string =>
	typeof image === 'string' ? image.substring('data:image/'.length, image.indexOf(';base64')) : 'none';

export const validateImageSize = (imageData?: RawMediaCropInput): boolean =>
	imageData ? imageData.h > MIN_IMAGE_DIMENSION_SIZE.HEIGHT || imageData.w > MIN_IMAGE_DIMENSION_SIZE.WIDTH : false;

// Because the height/width of the cropper component changes between media/preview
// and the cropper component only allows for cropping vertically, we can check for
// crop position rather than cropped height/width to check rough crop equality
export const isCropEqual = (a?: RawMediaCropInput, b?: RawMediaCropInput): boolean => {
	return isEqual({ x: a?.x, y: a?.y }, { x: b?.x, y: b?.y });
};
