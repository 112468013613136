import { useMemo } from 'react';

import type { Locale } from '@change-corgi/config/locales';
import type { TrackingFn } from '@change-corgi/core/react/tracking';
import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';

import { useEducationModal } from './instagram';

export type ShareActionContext = {
	countryCode: string;
	locale: Locale;
	utilityContext: UtilityContext;
	track: TrackingFn;
	instagram: {
		openEducationModal: ReturnType<typeof useEducationModal>;
	};
};

export function useActionContext(): ShareActionContext {
	const utilityContext = useUtilityContext();
	const track = useTracking();
	const countryCode = useCountryCode();
	const locale = useLocale();
	const openEducationModal = useEducationModal();

	return useMemo(() => {
		return { utilityContext, track, countryCode, locale, instagram: { openEducationModal } };
	}, [utilityContext, track, countryCode, locale, openEducationModal]);
}
