import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-bulk-unsubscribe',
		path: '/dashboard/bulk_unsubscribe',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_bulkUnsubscribe" */ './index'), {
			exportName: 'BulkUnsubscribe',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['staff', 'support'],
		},
		frame: true,
	},
];
