import { useCallback } from 'react';

import isUndefined from 'lodash/fp/isUndefined';
import omitBy from 'lodash/fp/omitBy';
import qs from 'qs';

import { useQueryParamValue } from 'src/app/shared/hooks/location';

function removeOrigin(url: string): string {
	const { pathname, search, hash } = new URL(url, 'http://dummy.com');
	return `${pathname}${search}${hash}`;
}

function addQueryParams(url: string, params: Record<string, string | undefined>): string {
	const { origin, pathname, search, hash } = new URL(url, 'http://dummy.com');
	const query = {
		...(search ? qs.parse(search, { ignoreQueryPrefix: true }) : {}),
		...omitBy(isUndefined)(params),
	};
	const queryString = Object.keys(query).length ? `?${qs.stringify(query, { encodeValuesOnly: true })}` : '';
	return `${origin}${pathname}${queryString}${hash}`;
}

export function useGenerateSuccessRedirectUrl({
	conserveRecruiter,
}: {
	conserveRecruiter?: boolean;
} = {}): (url: string) => string {
	const recruiter = useQueryParamValue('recruiter');

	return useCallback(
		(url: string) => removeOrigin(conserveRecruiter ? addQueryParams(url, { recruiter }) : url),
		[recruiter, conserveRecruiter],
	);
}
