import { useEffect, useMemo } from 'react';

import { useQueryParamValue } from 'src/app/shared/hooks/location';

import { usePromotedTrackingDataForCurrentPetition } from './promotedTrackingDataForCurrentPetition';
import type { PromotedTrackingData } from './types';

export function usePromotedTrackingDataWithQueryParams(): PromotedTrackingData | undefined {
	const queryPromotionToken = useQueryParamValue('pt');
	const queryPlacement = useQueryParamValue('source_location');
	const queryAlgorithm = useQueryParamValue('pt_algorithm');

	const [localstoragePromotedData, setLocalstoragePromotedData] = usePromotedTrackingDataForCurrentPetition();

	const promotionToken = queryPromotionToken || localstoragePromotedData?.token;
	const promotionPlacement = queryPlacement || localstoragePromotedData?.placement;
	const promotionAlgorithm = queryAlgorithm || localstoragePromotedData?.algorithm;

	const promotedData = useMemo(() => {
		if (!promotionToken) return undefined;
		return {
			token: promotionToken,
			placement: promotionPlacement,
			algorithm: promotionAlgorithm,
		};
	}, [promotionToken, promotionPlacement, promotionAlgorithm]);

	useEffect(() => {
		if (promotionToken && localstoragePromotedData?.token !== promotionToken)
			setLocalstoragePromotedData({ token: promotionToken, placement: queryPlacement, algorithm: queryAlgorithm });
	}, [promotionToken, localstoragePromotedData, queryPlacement, queryAlgorithm, setLocalstoragePromotedData]);

	return promotedData;
}
