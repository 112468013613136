import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { SharedUserCurrencyQuery, SharedUserCurrencyQueryVariables } from './api.graphql';

export type CurrencyInfo = Omit<
	NonNullable<NonNullable<SharedUserCurrencyQuery['country']>['currency']>,
	'id' | '__typename'
>;

export async function getUserCurrency(countryCode: string, { gql: { fetch } }: UtilityContext): Promise<CurrencyInfo> {
	const { data } = await fetch<SharedUserCurrencyQuery, SharedUserCurrencyQueryVariables>({
		query: gql`
			query SharedUserCurrency($countryCode: String!) {
				country(countryCode: $countryCode) {
					currency {
						id
						code
						exchangeRate
					}
				}
			}
		`,
		variables: { countryCode },
	});

	if (!data?.country?.currency) return { code: 'USD', exchangeRate: 1 };

	return { code: data.country.currency.code, exchangeRate: data.country.currency.exchangeRate };
}
