import { useMemo } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useShortUrls } from 'src/app/shared/hooks/shortUrls';

import type { ShareChannel } from '../shared/types';

type Options<CH extends ShareChannel = ShareChannel> = {
	disableShortLinks?: boolean;
	getUrl: (channel: CH) => string;
};

export function useShareShortUrls<CH extends ShareChannel = ShareChannel>(
	channels: readonly CH[],
	{ getUrl, disableShortLinks }: Options<CH>,
): Partial<Record<CH, string>> {
	const utilityContext = useUtilityContext();
	const urls = useMemo(
		() =>
			channels.reduce<Record<string, string>>(
				(acc, channel) => ({
					...acc,
					[channel]: utilityContext.resolveUrl(getUrl(channel)),
				}),
				{},
			),
		[channels, getUrl, utilityContext],
	) as Partial<Record<CH, string>>;
	const shortLinks = useShortUrls(disableShortLinks ? {} : urls) as Partial<Record<CH, string>>;
	return disableShortLinks ? urls : shortLinks;
}
