import lineConfig from 'config/line.json';

import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { useIsMobile } from 'src/app/shared/hooks/userAgent';

export function useLineAvailable(): boolean {
	const mobile = useIsMobile();
	const countryCode = useCountryCode();
	return mobile && lineConfig.countries.includes(countryCode);
}
