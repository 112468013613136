import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoading } from 'src/app/shared/utils/async';

import type { SupporterVideo } from '../../types';

export function useCanDeleteVideo(video: SupporterVideo | null): boolean {
	const currentUser = useCurrentUserAsync();

	if (isLoading(currentUser)) return false;
	if (!video) return false;

	const isVideoOwner = currentUser.value?.id === video.user?.id;
	return currentUser.value?.roles.support || currentUser.value?.roles.campaigns || isVideoOwner;
}
