import { isLoaded } from 'src/app/shared/utils/async';

import { prefetchFcm, useFcm } from './fcm';
import type { CountryBannerConfig, CountryBannerContext } from './types';

export function useCountryBannerBase(
	context: CountryBannerContext,
	petitionId?: string,
): CountryBannerConfig | undefined {
	const fcm = useFcm();

	if (!isLoaded(fcm)) {
		return undefined;
	}

	if (!fcm.values.banner) {
		return undefined;
	}

	if (!fcm.values.banner.context.includes(context)) {
		return undefined;
	}

	// banner_petition_id means “this banner concerns this specific petition”,
	// therefore we don’t want to show that banner when already showing that petition
	if (petitionId && fcm.values.banner.banner_petition_id && fcm.values.banner.banner_petition_id === petitionId) {
		return undefined;
	}

	return fcm.values.banner;
}

export const prefetchCountryBannerBase = prefetchFcm;
