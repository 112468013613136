import qs from 'qs';

import type { AppRedirect } from 'src/shared/routes';

const MAPPING: Record<string, string> = {
	popular_weekly: 'popular',
	recent: 'recent',
	victories: 'victories',
};

export const REDIRECTS: readonly AppRedirect[] = [
	{
		path: '/petitions',
		buildUrl: ({ query: { selected, ...query } }) => {
			return `/browse${typeof selected === 'string' && MAPPING[selected] ? `/${MAPPING[selected]}` : ''}${qs.stringify(
				query,
				{
					addQueryPrefix: true,
				},
			)}`;
		},
		reason: 'deprecated-url',
	},
];
