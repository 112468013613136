import type { JSX } from 'react';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { Translate } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconNavigateBefore } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

export function NavigationBreadcrumb({
	linkText,
	linkTarget,
	linkQaId,
	onClick,
}: {
	linkText: TranslationKey;
	linkTarget: string;
	linkQaId?: string;
	onClick?: () => void;
}): JSX.Element {
	return (
		<Flex sx={{ alignItems: 'center', mb: 18 }}>
			<Link
				onClick={onClick}
				sx={{
					fontWeight: 400,
					textDecoration: 'none',
				}}
				to={linkTarget}
				data-qa={linkQaId}
			>
				<Flex sx={{ justifyContent: 'center' }}>
					<Icon color="neutral-grey700" icon={iconNavigateBefore} size="xxl" />
					<Text size="small" sx={{ alignSelf: 'center', color: 'primary-black' }}>
						<Translate value={linkText} />
					</Text>
				</Flex>
			</Link>
		</Flex>
	);
}
