import type { TrackingFn } from '@change-corgi/core/react/tracking';
import { getWindow } from '@change-corgi/core/window';

import { trackShareFailure, trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
		failure: string;
	};
};

export async function shareNative(
	url: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
): Promise<boolean> {
	const { navigator } = getWindow();
	if (!navigator.canShare({ url })) {
		return false;
	}

	try {
		await navigator.share({ url });
		trackShareSuccess(trackingEvents.success, trackingData, track);
		return true;
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const error = e as Error;
		if (error.name === 'AbortError') {
			return false;
		}
		trackShareFailure(
			trackingEvents.failure,
			{
				...trackingData,
				errorName: error.name,
				errorMessage: error.message,
			},
			track,
		);
		return false;
	}
}
