import type { AppRoute } from 'src/shared/routes';

import { createRoutes, handleRecruiter, petitionSlugCanonicalRedirect } from 'src/app/shared/routes';

import { PetitionGammaHome } from '../../index';

import { resolvePetitionL10nForBotsFromSlugParam } from './shared/resolvePetitionL10nForBotsFromSlugParam';

export const ROUTES: readonly AppRoute[] = [
	...createRoutes(
		{
			component: PetitionGammaHome,

			frame: {
				footer: true,
				header: true,
				/* TODO(CHANGE-68180): This can use the design system default (large). */
				width: 'small',
			},
		},
		[
			{
				id: 'petition-gamma-details',
				path: '/pgamma/:slug',
				restrictedAccess: {
					authOnly: true,
					role: 'staff',
				},
				// Duplicated from petition routes logic
				beforeServerRender: async (context) => {
					await handleRecruiter(context, context.params.slug);
					await petitionSlugCanonicalRedirect(context);
				},
				ssr: {
					enabled: true,
					seo: true,
					resolveL10n: resolvePetitionL10nForBotsFromSlugParam,
					config: ({ loginState }) => {
						if (loginState === 'GUEST') {
							return {
								disallowUserData: true,
								cache: {
									enabled: true,
									lifetimeInSeconds: 1 * 60, // 1min
									queryParams: ['expired_session'],
								},
							};
						}
						// avoid caching for non-guest
						// mainly so that starters see their changes right away
						return {};
					},
				},
			},
		],
	),
];
