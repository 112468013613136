import type { JSX, PropsWithChildren } from 'react';

import { useLoadGooglePlaces } from '../hooks/useLoadGooglePlaces';

import { GooglePlacesContextProvider } from './context';

export function GooglePlacesProvider({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	const { status, autocompleteService } = useLoadGooglePlaces();

	return (
		<GooglePlacesContextProvider status={status} autocompleteService={autocompleteService}>
			{children}
		</GooglePlacesContextProvider>
	);
}
