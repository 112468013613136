import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_MEMBERSHIP_YEARLY_CONTRIBUTIONS_AMOUNTS = createFcmConfig('membership_yearly_contributions_amounts', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'object',
			shape: {
				yearly: { type: 'array', shape: { type: 'number' } },
				monthly: { type: 'array', shape: { type: 'number' }, optional: true },
				one_time: { type: 'array', shape: { type: 'number' }, optional: true },
			},
		}),
	),
	defaultValue: undefined,
});
