import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { createRoutes, Loading, starter } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	...createRoutes(
		{
			component: loadable(async () => import(/* webpackChunkName: "pages/hello" */ './index'), {
				exportName: 'Hello',
				fallback: Loading,
			}),
			envs: ['demo', 'development', 'staging'],
			frame: true,
			ssr: {
				enabled: true,
				seo: false,
			},
		},
		[
			{
				id: 'hello',
				path: ['/p/:slug/corgi/hello', '/p/:slug/corgi/hello-error'],
			},
			{
				id: 'hello2',
				path: '/corgi-dev/p/:slug/corgi/hello',
			},
			// used for permission check tests
			{
				id: 'hello-auth',
				path: '/p/:slug/corgi/hello-auth',
				restrictedAccess: {
					authOnly: true,
				},
			},
			{
				id: 'hello-staff',
				path: '/p/:slug/corgi/hello-staff',
				restrictedAccess: {
					authOnly: true,
					role: 'staff',
				},
			},
			{
				id: 'hello-support',
				path: '/p/:slug/corgi/hello-support',
				restrictedAccess: {
					authOnly: true,
					role: 'support',
				},
			},
			{
				id: 'hello-starter',
				path: '/p/:slug/corgi/hello-starter',
				restrictedAccess: {
					authOnly: true,
					role: ['staff', starter('slug')],
				},
			},
		],
	),
	// with server-caching
	{
		id: 'hello-cached',
		path: '/p/:slug/corgi/hello-cached',
		component: loadable(async () => import(/* webpackChunkName: "pages/hello" */ './index'), {
			exportName: 'Hello',
			fallback: Loading,
		}),
		envs: ['demo', 'development', 'staging'],
		frame: true,
		ssr: {
			enabled: true,
			seo: false,
			config: {
				disallowUserData: true,
				cache: {
					enabled: true,
					queryParams: ['error', 'redirect'],
					lifetimeInSeconds: 60,
				},
			},
		},
	},
	// CSR only
	{
		id: 'hello-csr',
		path: '/p/:slug/corgi/hello-csr',
		component: loadable(async () => import(/* webpackChunkName: "pages/hello" */ './index'), {
			exportName: 'Hello',
			fallback: Loading,
		}),
		envs: ['demo', 'development', 'staging'],
		frame: true,
	},
];
