import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { Session } from '@change-corgi/core/session';
import { getLocation } from '@change-corgi/core/window';

import { useSetCurrentUser } from '../set';

import { useGetL10nChanged } from './l10n';
import { useGetNewSession } from './newSession';

type CallbackOptions = {
	reloadPageOnFailure?: boolean;
	reloadPageOnL10nChange?: boolean;
	onFailure?: () => void;
	onReloadPage?: (reason: 'l10n_changed' | 'session_retrieval_failure', session: Session | undefined) => void;
};

export function useRefreshSession(): (options?: CallbackOptions) => Promise<Session> {
	const getNewSession = useGetNewSession();
	const getL10nChanged = useGetL10nChanged();
	const { setUser, clearUser } = useSetCurrentUser();
	const { tracker, errorReporter } = useUtilityContext();

	return useCallback(
		async ({ onFailure, onReloadPage, reloadPageOnFailure, reloadPageOnL10nChange }: CallbackOptions = {}) => {
			try {
				const newSession = await getNewSession();

				if (reloadPageOnL10nChange && getL10nChanged(newSession) !== 'none') {
					onReloadPage?.('l10n_changed', newSession);
					getLocation().reload();
					return newSession;
				}

				if (newSession.loginState === 'GUEST') {
					clearUser(newSession.uuid);
				} else {
					setUser(newSession.uuid, newSession.user, newSession.loginState, newSession.hasMembership);
				}

				tracker.updateSessionInfo({ loginState: newSession.loginState });
				errorReporter.updateSessionInfo({ loginState: newSession.loginState });

				return newSession;
			} catch (e) {
				onFailure?.();
				if (reloadPageOnFailure) {
					onReloadPage?.('session_retrieval_failure', undefined);
					getLocation().reload();
				}
				throw e;
			}
		},
		[getNewSession, tracker, errorReporter, getL10nChanged, setUser, clearUser],
	);
}
