import { useMemo } from 'react';

import { orderFallbacksByDepthDesc } from '../shared/orderFallbacksByDepthDesc';
import type { ValidFocusFallback } from '../types';

import { useFocusFallbackContext } from './useFocusFallbackContext';

export function useGetFocusFallbacks(): ((element: HTMLElement) => readonly ValidFocusFallback[]) | undefined {
	const context = useFocusFallbackContext();

	return useMemo(() => {
		if (!context) return undefined;

		return (element: HTMLElement) =>
			orderFallbacksByDepthDesc(context.fallbacks).filter((fallback): fallback is ValidFocusFallback => {
				const {
					ref: { current },
				} = fallback;
				return !!current && current.contains(element);
			});
	}, [context]);
}
