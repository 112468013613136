import { useIsMembershipEligibleUser } from 'src/app/shared/hooks/membership';
import { useIsMobile } from 'src/app/shared/hooks/userAgent';

import type { MembershipBottomBannerData } from '../../data';

type Props = {
	subscriptionConfig: MembershipBottomBannerData['subscriptionConfig'];
	membershipBottomBannerEnabled: boolean;
};

export function useIsEligible(): (props: Props) => boolean {
	const isMembershipEligibleUser = useIsMembershipEligibleUser();
	const isMobile = useIsMobile();

	return function isEligible({ subscriptionConfig, membershipBottomBannerEnabled }: Props): boolean {
		return !isMobile && isMembershipEligibleUser && membershipBottomBannerEnabled && !!subscriptionConfig;
	};
}
