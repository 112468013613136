import { useMemo } from 'react';

import { useLoginOrJoinAttemptTracking } from './useLoginOrJoinAttemptTracking';
import { useLoginOrJoinSuccessTracking } from './useLoginOrJoinSuccessTracking';
import { useSocialAuthSuccessTracking } from './useSocialAuthSuccessTracking';

type Result = ReturnType<typeof useSocialAuthSuccessTracking> &
	ReturnType<typeof useLoginOrJoinSuccessTracking> &
	ReturnType<typeof useLoginOrJoinAttemptTracking>;

export function useSocialAuthTracking(): Result {
	const socialAuthSuccessTrackingFunctions = useSocialAuthSuccessTracking();
	const loginOrJoinSuccessTrackingFunctions = useLoginOrJoinSuccessTracking();
	const loginOrJoinAttemptTrackingFunctions = useLoginOrJoinAttemptTracking();

	return useMemo(
		() => ({
			...loginOrJoinAttemptTrackingFunctions,
			...socialAuthSuccessTrackingFunctions,
			...loginOrJoinSuccessTrackingFunctions,
		}),
		[socialAuthSuccessTrackingFunctions, loginOrJoinAttemptTrackingFunctions, loginOrJoinSuccessTrackingFunctions],
	);
}
