import { useEffect, useState } from 'react';

import { useQueryParamValue } from 'src/app/shared/hooks/location';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoading } from 'src/app/shared/utils/async';

import { useFacebookAutoLogin } from './login';
import { useGetFacebookAutoLoginContext } from './loginContext';
import { useFacebookAutoLoginTracking } from './tracking';
import { useUpdateSessionOrReload } from './updateSession';

/**
 * This attempts a client-side FB login when the user is not authenticated
 * This can happen when the user is logged into FB, but their FB cookies
 * on the change.org domain are absent
 */
export function useAttemptLoginByFacebook(): void {
	const [attempted, setAttempted] = useState(false);
	const sessionState = useSessionAsync();
	const { trackAttempt } = useFacebookAutoLoginTracking();
	const getLoginContext = useGetFacebookAutoLoginContext();
	const autoLogin = useFacebookAutoLogin();
	const updateSessionOrReload = useUpdateSessionOrReload();
	const fbAuthed = useQueryParamValue('fb_authed');

	useEffect(() => {
		if (isLoading(sessionState) || attempted) {
			return;
		}

		// only attempt FB login if cookie prefs allow for it
		// using fb_authed as an exception as that is set when the user explicitly requested to log in using FB
		if (!sessionState.value.cookiePrefs.marketing && !fbAuthed) {
			return;
		}

		setAttempted(true);

		(async () => {
			try {
				const loginContext = await getLoginContext();

				if (!loginContext) return;

				trackAttempt();

				// Autologin may sign in or create a user account: If loginContext exists and we've made it this far,
				// it means the user has granted our app FB permissions. When the user is in webview, they will have been
				// redirected off the site so account creation must be performed when they return to Change.org
				await autoLogin(loginContext);

				await updateSessionOrReload();
			} catch (e) {
				// ignore failures so they're not reported (they are already tracked)
			}
		})();
	}, [
		fbAuthed,
		sessionState,
		attempted,
		setAttempted,
		getLoginContext,
		autoLogin,
		updateSessionOrReload,
		trackAttempt,
	]);
}
