import { useCallback, useState } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { loginOrSignupByGoogle, LoginOrSignupByGoogleError } from 'src/app/shared/api/auth';

import { useSocialAuthTracking } from '../shared/hooks/useSocialAuthTracking';
import { useTrackSuccessSocial } from '../shared/hooks/useTrackSuccess';

type GoogleLoginInput = {
	signupContext: string;
	loginType: string;
	optionalOnClickTrackProps?: Record<string, string | boolean>;
};

type GoogleLoginResult =
	| { status: 'not_started' | 'loading' | 'cancelled' }
	| { status: 'success'; data: GoogleLoginResultData }
	| { status: 'error'; errorReason: 'STAFF_SAML' | 'OTHER' };

export type GoogleLoginResultData = {
	existingUser: boolean;
	email: string;
	displayName: string;
	firstName: string;
	lastName: string;
	picture?: string;
};

type HandleGoogleLoginCallback = ({
	token,
	data,
}: {
	token?: string;
	data?: {
		email: string;
		displayName: string;
		givenName: string;
		familyName: string;
		profilePicture?: string;
	};
}) => void;

// eslint-disable-next-line max-lines-per-function
export function useHandleGoogleLogin({ signupContext, loginType, optionalOnClickTrackProps }: GoogleLoginInput): {
	result: GoogleLoginResult;
	handleGoogleLoginClick: () => void;
	handleGoogleLogin: HandleGoogleLoginCallback;
} {
	const context = useUtilityContext();
	const track = useTracking();
	const trackSuccessSocial = useTrackSuccessSocial();
	const [result, setResult] = useState<GoogleLoginResult>({ status: 'not_started' });
	const { trackLoginOrJoinAttemptSocial } = useSocialAuthTracking();

	const siteLogin = useCallback(
		async (googleIdToken: string, googleProfile: Omit<GoogleLoginResultData, 'existingUser'>) => {
			setResult({ status: 'loading' });
			try {
				const loginResult = await loginOrSignupByGoogle(googleIdToken, signupContext, context);
				const existingUser = loginResult !== 'CREATED';
				await trackSuccessSocial({
					loginMethod: 'google',
					loginOrJoinMethod: 'manual_google',
					loginType: existingUser ? 'google_login' : 'google_signup',
					newUser: !existingUser,
					userFlow: loginType,
				});
				setResult({
					status: 'success',
					data: { ...googleProfile, existingUser },
				});
			} catch (e) {
				if (e instanceof LoginOrSignupByGoogleError && e.status === 'INVALID_LOGIN_METHOD') {
					setResult({ status: 'error', errorReason: 'STAFF_SAML' });
				} else {
					setResult({ status: 'error', errorReason: 'OTHER' });
				}
			}
		},
		[context, loginType, signupContext, trackSuccessSocial],
	);

	const handleGoogleLoginClick = useCallback(() => {
		void track('google_login_button_click', { context: loginType, ...optionalOnClickTrackProps });

		trackLoginOrJoinAttemptSocial({
			loginOrSignupContext: 'combined',
			loginOrJoinMethod: 'manual_google',
			hasSetPassword: false,
			userFlow: loginType,
		});
	}, [track, loginType, optionalOnClickTrackProps, trackLoginOrJoinAttemptSocial]);

	const handleGoogleLogin: HandleGoogleLoginCallback = useCallback(
		({ token, data }) => {
			if (token && data) {
				void siteLogin(token, {
					email: data.email,
					firstName: data.givenName,
					lastName: data.familyName,
					displayName: data.displayName,
					picture: data.profilePicture,
				});
			}
		},
		[siteLogin],
	);

	return { handleGoogleLogin, handleGoogleLoginClick, result };
}
