import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import type { SubEnvironment } from '@change-corgi/config/environments';
import { facebookAppIds } from '@change-corgi/config/facebook';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

function getFacebookAppId(environment: EnvironmentUtils): string {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const env = (environment.getDemoEnvironment() as SubEnvironment | undefined) || environment.getEnvironment();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return facebookAppIds[env as SubEnvironment] || facebookAppIds.development;
}

export function MetaFacebookContainer(): JSX.Element {
	const { environment } = useUtilityContext();

	return (
		<Helmet>
			<meta property="fb:app_id" content={getFacebookAppId(environment)} />
		</Helmet>
	);
}
