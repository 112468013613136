import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

type Id = 'a1' | 'a2' | 'a3' | 'a4' | 'a5';
const IDS: readonly Id[] = ['a1', 'a2', 'a3', 'a4', 'a5'];

export const FCM_PSF_COMBO_CURRENCY_AMOUNT = createFcmConfig('psf_combo_currency_amount', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'record',
			keys: IDS,
			shape: { type: 'number', optional: true },
		}),
	),
	defaultValue: undefined,
});
