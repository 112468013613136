import { useMemo } from 'react';

import snakeCase from 'lodash/snakeCase';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import type { ExperimentTextProps } from '../../type';

export type LocalizedCountsConfig = Record<string, Record<string, number> | undefined>;

export function useLayout(localizedCountsConfig: LocalizedCountsConfig, variant?: string): ExperimentTextProps | null {
	const session = useSessionAsync();
	return useMemo(() => {
		if (!isLoaded(session)) return null;
		if (variant === 'variant_1') {
			return {
				variant,
				name: session.value.user?.firstName,
				signatureCount: session.value.user?.totalSignatureCount ?? 0,
			};
		}

		if (variant === 'variant_3') {
			const { country, city, stateCode, firstName } = session.value.user || {};

			const countryCode = country?.countryCode?.toLowerCase();
			const userCity = city?.toLowerCase();

			if (!countryCode || !userCity) return null;

			const localizedTypeCountsConfig = localizedCountsConfig;
			const formattedLocationString: string = `${stateCode?.toLowerCase()}_${snakeCase(userCity)}`;
			const countryValues = localizedTypeCountsConfig[countryCode];
			const signatureCount = countryValues ? countryValues[formattedLocationString] : 0;

			if (!signatureCount) return null;

			return {
				variant,
				name: firstName,
				locationName: userCity ?? '',
				signatureCount,
			};
		}
		return null;
	}, [session, variant, localizedCountsConfig]);
}
