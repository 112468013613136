import { useMemo } from 'react';

type SyncShareChannel = never; // TODO omit async channels like gmail from ShareChannel

export function useSyncAvailableShareChannels(): Readonly<Record<SyncShareChannel, boolean>> {
	// const gmail = useGmail(); // gmail will need user info + fcm info

	return useMemo(
		() => ({
			// TODO
			// gmail
		}),
		[],
	);
}
