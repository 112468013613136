import type { FcmAccessControlConfigValueType } from 'src/app/shared/fcm/configs';
import { useSession } from 'src/app/shared/hooks/session';

import { identifyVariation } from './identifyVariation';
import { idToPercentage } from './idToPercentage';
import { sessionIdOrUserId } from './sessionIdOrUserId';

export function useIdentifyUserVariation(featureAccessConfigValue: FcmAccessControlConfigValueType): {
	actual: string;
} {
	const session = useSession();

	const userId = sessionIdOrUserId(session);
	const position = idToPercentage(userId);
	const { variants } = featureAccessConfigValue;
	const variant = identifyVariation(position, variants);

	return { actual: variant };
}
