import { createStateContext } from '@change-corgi/core/react/context';

import { mutations } from './mutations';
import type { FcmCacheState } from './types';

export const {
	StateContext: FcmStateContext,
	StateProvider: FcmStateContextProvider,
	useStateContext: useFcmState,
} = createStateContext<FcmCacheState, typeof mutations, { initialState?: FcmCacheState }>({
	name: 'FcmStateContext',
	mutations,
	initialState: ({ initialState }) => initialState || { cache: {}, queue: [] },
});

export type { FcmCacheState };

export { mutations };
