import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		toolbar: { type: 'string' },
		// Note: Please update with RichTextEditor config properties
	},
});

export const FCM_OCTOPUS_TAKO_EDITOR_CONFIG = createFcmConfig('octopus_tako_editor_config', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: undefined,
});
