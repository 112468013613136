import type { MutableRefObject } from 'react';
import { useRef } from 'react';

type Refs = {
	loadMoreRef: MutableRefObject<null>;
	loadingRef: MutableRefObject<null>;
	itemRef: MutableRefObject<null>;
	lastItemRef: MutableRefObject<null>;
};

export function useLazyLoadedListFocusRefs(): Refs {
	const loadingRef = useRef(null);
	const loadMoreRef = useRef(null);

	const itemRef = useRef(null);
	const lastItemRef = useRef(null);

	return {
		loadMoreRef,
		loadingRef,
		itemRef,
		lastItemRef,
	};
}
