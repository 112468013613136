import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	CreateAppUserAlreadyExistsErrorFieldsFragment,
	CreateAppUserFieldsFragment,
	CreateAppUserMutation,
	CreateAppUserMutationVariables,
} from './index.graphql';

export type User = CreateAppUserFieldsFragment['user'];

export type CreateUserParams = CreateAppUserMutationVariables['input'];

export type UserAlreadyExistsError = CreateAppUserAlreadyExistsErrorFieldsFragment;

const query = gql`
	mutation CreateAppUser($input: CreateUserInput!) {
		createUser(input: $input) {
			__typename
			... on CreateUserSuccess {
				...CreateAppUserFields
			}
			... on CreatePendingUserSuccess {
				success
			}
			... on UserAlreadyExistsError {
				...CreateAppUserAlreadyExistsErrorFields
			}
			... on PasswordBlocklistedError {
				error
			}
		}
	}
	fragment CreateAppUserFields on CreateUserSuccess {
		user {
			id
			displayName
			shortDisplayName
			country {
				countryCode
			}
			stateCode
			city
			formattedLocationString
			firstName
			lastName
			uuid
			email
			createdAt
		}
	}
	fragment CreateAppUserAlreadyExistsErrorFields on UserAlreadyExistsError {
		userId
		userPasswordSet
	}
`;

export async function createUser(
	{ firstName, lastName, email, countryCode, signupMethod, signupContext, phoneNumber }: CreateUserParams,
	{ gql: { fetch } }: UtilityContext,
): Promise<User | boolean> {
	const input = { email, firstName, lastName, countryCode, signupMethod, signupContext, phoneNumber };
	const { data, errors } = await fetch<CreateAppUserMutation, CreateAppUserMutationVariables>({
		path: '/createUser',
		query,
		variables: { input },
		important: true, // to ensure this rate limited mutation is not batched
	});
	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from mutation.');
	if (data.createUser.__typename === 'PasswordBlocklistedError') {
		throw new Error('PasswordBlocklistedError');
	}

	// FIXME throw error with message
	// eslint-disable-next-line @typescript-eslint/only-throw-error
	if (data.createUser.__typename === 'UserAlreadyExistsError') throw data.createUser;
	if (data.createUser.__typename === 'CreatePendingUserSuccess') return data.createUser.success;
	return data.createUser.user;
}
