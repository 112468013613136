import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { createRoutes, Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	...createRoutes(
		{
			component: loadable(async () => import(/* webpackChunkName: "pages/membership_join" */ './index'), {
				exportName: 'MembershipJoin',
				fallback: Loading,
			}),
			ssr: {
				// switch this to true to enable SSR
				enabled: false,
				seo: false,
			},
		},
		[
			{
				id: 'membership-join',
				path: ['/member', '/member/join', '/member/subscribe', '/s/:sp_slug_or_id'],
				frame: true,
			},
			{
				id: 'membership-join-psf',
				path: '/p/:slug/psf/membership',
				frame: false,
			},
		],
	),
];
