import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		// old url from when we used team-based prefixes - should not be used anymore
		path: '/co/s/:membershipSlug/post_promote_thank_you',
		buildUrl: ({ params: { membershipSlug }, queryString }) =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			`/s/${membershipSlug!}/post_promote_thank_you${queryString}`,
		reason: 'deprecated-url',
	},
];
