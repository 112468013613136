import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

import { FCM_EXPERIMENT_MEMBERSHIP_YEARLY_CONTRIBUTIONS } from './config';

export const {
	FcmExperimentsContext: MembershipBottomBannerFcmExperimentsContext,
	FcmExperimentsProvider: MembershipBottomBannerFcmExperimentsContextProvider,
	useFcmExperiments: useMembershipBottomBannerFcmExperiments,
	useAsyncFcmExperiments: useAsyncMembershipBottomBannerFcmExperiments,
	prefetchFcmExperiments: prefetchMembershipBottomBannerFcmExperiments,
} = createFcmExperimentsContext({
	name: 'MembershipBottomBannerFcmExperimentsContext',
	dataProperty: 'fcmExperiments',
	configs: {
		showYearlyContributions: FCM_EXPERIMENT_MEMBERSHIP_YEARLY_CONTRIBUTIONS,
	},
	prefetch: true,
});

export type MembershipBottomBannerFcmExperimentsState = ReturnType<typeof useAsyncMembershipBottomBannerFcmExperiments>;
export type MembershipBottomBannerFcmExperiments = LoadedStateData<MembershipBottomBannerFcmExperimentsState>;
