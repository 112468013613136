import gql from 'graphql-tag';

import type { Utilities } from '@change-corgi/core/react/utilityContext';

import type { SharedPetitionLocaleQuery, SharedPetitionLocaleQueryVariables } from './petitionLocale.graphql';

export async function getPetitionLocale(
	slugOrId: string,
	utilities: Pick<Utilities, 'gql' | 'http'>,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<SharedPetitionLocaleQuery, SharedPetitionLocaleQueryVariables>({
		query: gql`
			query SharedPetitionLocale($slugOrId: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					originalLocale {
						localeCode
					}
				}
			}
		`,
		variables: { slugOrId },
	});

	return data?.petition?.originalLocale?.localeCode;
}
