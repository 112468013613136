import { useMemo } from 'react';

import type { Sharer } from 'src/app/pages/petition/details/shared/types';

import { useHeadlineReplacementsDm } from './dm';
import { useHeadlineReplacementsPetition } from './petition';
import { useHeadlineReplacementsRandom } from './random';
import { useHeadlineReplacementsSharer } from './sharer';
import { useHeadlineReplacementsSignatures } from './signatures';
import { useHeadlineReplacementsStarter } from './starter';

export function useHeadlineReplacements(sharer: Sharer | undefined): Record<string, string | number> {
	const randomValues = useHeadlineReplacementsRandom();
	const starterValues = useHeadlineReplacementsStarter();
	const petitionValues = useHeadlineReplacementsPetition();
	const sharerValues = useHeadlineReplacementsSharer(sharer);
	const dmValues = useHeadlineReplacementsDm();
	const signaturesValues = useHeadlineReplacementsSignatures();

	return useMemo(
		() => ({
			...petitionValues,
			...signaturesValues,
			...starterValues,
			...dmValues,
			...sharerValues,
			...randomValues,
		}),
		[petitionValues, signaturesValues, starterValues, dmValues, sharerValues, randomValues],
	);
}
