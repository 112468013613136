import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'membership-thankyou',
		path: '/member/v2/thank_you',
		component: loadable(async () => import(/* webpackChunkName: "pages/membership_thankyou" */ './index'), {
			exportName: 'MembershipJoinThankyou',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
	},
];
