import { getBlogUrl } from '@change-corgi/config/blog';
import { getPressUrl } from '@change-corgi/config/press';
import { useI18n } from '@change-corgi/core/react/i18n';

import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';

import type { LinkGroup } from '../../shared/types';

export function useCommunityLinks(): LinkGroup {
	const { translate } = useI18n();
	const countryCode = useCountryCode();
	const locale = useLocale();

	return {
		title: translate('fe.components.footer.community'),
		links: [
			{
				href: getBlogUrl({ locale, countryCode }),
				title: translate('fe.components.footer.blog'),
			},
			{ href: getPressUrl({ locale, countryCode }), title: translate('fe.components.footer.press') },
			{ href: '/policies/community', title: translate('fe.components.footer.community_guidelines') },
		],
	};
}
