import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { HttpClient } from '@change-corgi/core/http';
import type { I18n, Translations } from '@change-corgi/core/i18n';
import type { Session } from '@change-corgi/core/session';

import { getTranslations as _getTranslations, setupI18n as _setupI18n, getTranslationsCacheBuster } from 'src/app/i18n';
import { markTimelineEnd, markTimelineStart } from 'src/app/shared/performance';

type Options = Readonly<{
	errorReporter: ErrorReporter;
	session: Session;
	http: HttpClient;
	environment: EnvironmentUtils;
	filterTranslations: boolean;
	translationsIdentifier?: string;
	/**
	 * translations to use as a priority, generally coming from hydration data
	 */
	translations: Translations | undefined;
}>;

export async function setupI18n({
	errorReporter,
	session,
	http,
	environment,
	filterTranslations,
	translationsIdentifier,
	translations,
}: Options): Promise<{ i18n: I18n; i18nError?: true }> {
	const {
		locale: { localeCode: locale },
	} = session;

	const env = environment.getDemoEnvironment() || environment.getEnvironment();
	const cacheBuster = getTranslationsCacheBuster({ env, translationsIdentifier });

	markTimelineStart('i18n');
	try {
		const i18n = await _setupI18n({
			locale,
			translations,
			reportError: errorReporter.report.bind(errorReporter),
			getTranslations: async (translationsLocale) =>
				_getTranslations(translationsLocale, {
					getAsset: http.getAsset.bind(http),
					reportError: errorReporter.report.bind(errorReporter),
					filterTranslations,
					cacheBuster,
				}),
		});
		markTimelineEnd('i18n');
		return { i18n };
	} catch (e) {
		const i18n = await _setupI18n({
			locale,
			reportError: errorReporter.report.bind(errorReporter),
			getTranslations: async (_locale: string) => Promise.resolve({}),
		});
		markTimelineEnd('i18n');
		return { i18n, i18nError: true };
	}
}
