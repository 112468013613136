import type { SVGProps } from 'react';

export function SignCtaIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M23.12 5.12C23.69 4.55 24 3.8 24 3s-.31-1.55-.88-2.12a3 3 0 0 0-4.24 0L8 11.76V16h4.24zm-1.41-2.83c.19.19.29.44.29.71s-.1.52-.29.71L20.42 5l-1.41-1.41L20.3 2.3a.996.996 0 0 1 1.41 0zM10 14v-1.41L17.59 5 19 6.41 11.41 14zm14 8v2c-1.54 0-2.29-1.12-2.83-1.95-.5-.75-.75-1.05-1.17-1.05-.51 0-.9.44-1.51 1.15-.7.83-1.57 1.85-3.03 1.85s-2.32-1.03-3-1.87C11.88 21.43 11.5 21 11 21s-.97.39-1.71 1.03C8.28 22.91 7.02 24 5 24c-2.76 0-5-2.24-5-5s2.24-5 5-5h1v2H5c-1.65 0-3 1.35-3 3s1.35 3 3 3c1.28 0 2.1-.72 2.98-1.48C8.84 19.77 9.73 19 11 19c1.47 0 2.32 1.03 3 1.87.58.7.96 1.13 1.46 1.13s.9-.44 1.51-1.15c.7-.83 1.57-1.85 3.03-1.85s2.29 1.12 2.83 1.95c.5.75.75 1.05 1.17 1.05"
			/>
		</svg>
	);
}
