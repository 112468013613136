import { useCallback, useEffect, useMemo, useState } from 'react';

import type { ParsedQs } from 'qs';
import qs from 'qs';
import type { NavigateOptions } from 'react-router';
import { useLocation } from 'react-router';

import { useNavigate } from '@change-corgi/core/react/router';

export function useQueryString(): ParsedQs {
	const { search } = useLocation();

	return useMemo(
		() => ({
			...qs.parse(search, { ignoreQueryPrefix: true }),
		}),
		[search],
	);
}

/**
 * Use this to partially update the current url's query params.
 *
 * @example
 * const updateQueryParams = useUpdateQueryParams();
 * // ...
 * updateQueryParams({ foo: 42 }); // updates/adds foo and conserve other params
 * updateQueryParams({ foo: undefined }); // clears foo and conserve other params
 *
 */
export function useUpdateQueryString(): (query: ParsedQs, options?: Pick<NavigateOptions, 'replace'>) => void {
	const location = useLocation();
	const navigate = useNavigate();

	return useCallback(
		(newQuery, options) => {
			const query = qs.parse(location.search, { ignoreQueryPrefix: true });
			navigate(
				`${location.pathname}${qs.stringify(
					{ ...query, ...newQuery },
					{ addQueryPrefix: true, arrayFormat: 'repeat' },
				)}`,
				{
					...options,
					// to avoid a page change, especially when using fe's corgi proxy
					forceMode: 'internal',
				},
			);
		},
		[location, navigate],
	);
}

export function useQueryParamValue<S extends string = string>(key: string): S | undefined {
	const queryString = useQueryString();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const value = queryString[key] as S | S[] | undefined;
	// eslint-disable-next-line no-nested-ternary
	return value === undefined ? undefined : Array.isArray(value) ? value[0] : value;
}

export function useQueryParamValues<S extends string = string>(key: string): readonly S[] {
	const queryString = useQueryString();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const value = queryString[key] as S | S[] | undefined;
	// eslint-disable-next-line no-nested-ternary
	const values = value === undefined ? [] : Array.isArray(value) ? [...value] : [value];
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => values, values);
}

/**
 * Returns a query param value and clears it from the url by navigating to the same url but without the query param
 *
 * The key cannot change over time
 */
export function useClearedQueryParamValue<S extends string = string>(key: string): S | undefined {
	const value = useQueryParamValue<S>(key);
	const [initialValue] = useState(value);
	const updateQueryString = useUpdateQueryString();

	useEffect(() => {
		if (value === undefined) return;

		updateQueryString({ [key]: undefined }, { replace: true });
	}, [key, value, updateQueryString]);

	return initialValue;
}
