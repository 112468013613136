import { useEffect, useState } from 'react';

import { useBannerClosedStateStorage } from '../useBannerClosedStateStorage';

type Result = { displayed: boolean };

export function useMembershipBottomBanner(): Result {
	const [waitTimeCompleted, setWaitTimeCompleted] = useState<boolean>(false);
	const { bannerClosed } = useBannerClosedStateStorage();

	useEffect(() => {
		if (bannerClosed) return () => {};
		const timer = setTimeout(() => {
			setWaitTimeCompleted(true);
		}, 10000);

		return () => {
			clearTimeout(timer);
		};
	}, [bannerClosed]);

	return {
		displayed: waitTimeCompleted,
	};
}
