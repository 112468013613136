import type { WordBreak } from '../types';

import { getBestTruncatedValue } from './getBestTruncatedValue';

type Options = Readonly<{
	checkContentsFit: () => boolean;
	wordBreak: WordBreak;
}>;

export function truncateElement(el: Text, { checkContentsFit, wordBreak }: Options): () => void {
	const valueBeforeTruncate = el.nodeValue;
	// eslint-disable-next-line no-param-reassign
	el.nodeValue = getBestTruncatedValue(el, {
		checkContentsFit,
		wordBreak,
	});
	return () => {
		// eslint-disable-next-line no-param-reassign
		el.nodeValue = valueBeforeTruncate;
	};
}
