import { useMemo } from 'react';

import { useCommonFcm } from 'src/app/shared/hooks/commonFcm';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

export function useIsMembershipEligibleCountry(): boolean {
	const commonFcm = useCommonFcm();
	const session = useSessionAsync();

	return useMemo(() => {
		if (!isLoaded(commonFcm) || !isLoaded(session)) return false;
		if (!commonFcm.values.membershipEnabled) return false;
		if (session.value.country.membershipProgram === null || !session.value.country.membershipProgram.id) return false;
		return true;
	}, [commonFcm, session]);
}
