import { useMemo, useState } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

/**
 * This returns a tracking function that will send a tracking event only the first time it's called,
 * making all subsequent calls no-ops (in which case it resolves with "false"),
 * even if the tracking data (from the args or the TrackingContext) changes.
 *
 * This will of course still send multiple tracking events if the component using the hook is unmounted and re-mounted.
 *
 * It is possible to pass an array of deps to reset the internal "tracked" flag when those change
 *
 * @example
 * const trackClick = useTrackOnce();
 * const trackFocus = useTrackOnce();
 *
 * return <>
 *   <Button onClick={() => trackClick('button_click')} />
 *   <Input onFocus={() => trackFocus('input_focus')} />
 * </>
 */
// TODO: variant with event name? e.g. useTrackOnce('button_click')
export function useTrackOnce(deps: readonly unknown[] = []): ReturnType<typeof useTracking> {
	const track = useTracking();
	const [tracked, setTracked] = useState<readonly unknown[] | false>(false);

	return useMemo(() => {
		// in case the returned function is called twice before the next render, we still want to make sure we only track once
		let trackedInSameRender = false;

		return async (...args) => {
			if ((tracked && areDepsEqual(tracked, deps)) || trackedInSameRender) return false;
			setTracked(deps);
			trackedInSameRender = true;
			return track(...args);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [track, tracked, ...deps]);
}

function areDepsEqual(deps1: readonly unknown[], deps2: readonly unknown[]) {
	if (deps1.length !== deps2.length) return true;
	return deps1.every((val, idx) => val === deps2[idx]);
}
