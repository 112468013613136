import { NOT_KNOWN } from './constants';
import { getEmailDomain } from './getEmailDomain';
import { getMethod } from './getMethod';
import { isFbLoggedIn } from './isFbLoggedIn';
import type { SuccessfulLoginOrJoinTrackingParams, SuccessfulLoginOrJoinTrackingProps } from './types';

export const getSuccessfulLoginOrJoinTrackingProps = ({
	email,
	facebook,
	loginMethod,
	loginOrJoinMethod,
	loginType,
	newUser,
	userFlow,
	userId,
}: SuccessfulLoginOrJoinTrackingParams): SuccessfulLoginOrJoinTrackingProps => ({
	connected_to_facebook: facebook?.isConnected || loginMethod === 'facebook',
	connected_to_google: loginMethod === 'google',
	context: newUser ? 'sign_up' : 'log_in',
	email_domain: email ? getEmailDomain(email) : NOT_KNOWN,
	fb_logged_in: isFbLoggedIn(facebook?.authStatus) || loginMethod === 'facebook',
	google_logged_in: loginMethod === 'google',
	google_permissions: 'none',
	has_set_password: loginMethod === 'email',
	login_or_join_method: loginOrJoinMethod,
	login_type: loginType,
	method: getMethod(loginMethod),
	new_user: newUser,
	user_flow: userFlow,
	user_id: userId === undefined ? undefined : parseInt(userId, 10),
});
