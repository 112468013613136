import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-edit-goal',
		path: '/dashboard/petitions/:slug/edit_goal',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_editGoal" */ './index'), {
			exportName: 'EditGoal',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
		frame: true,
	},
];
