import qs from 'qs';

import type { TrackingFn } from '@change-corgi/core/react/tracking';
import { openWindow } from '@change-corgi/core/window';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

export function shareEmail(
	subject: string,
	body: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
): void {
	openWindow(`mailto:?${qs.stringify({ subject, body }, { encodeValuesOnly: true })}`);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
