import type { JSX } from 'react';

import { Banner } from 'src/app/shared/components/banners/layout';
import type { CountryBannerConfig, CountryBannerContext } from 'src/app/shared/hooks/banners/country';

import { useCountryBannerClickTracking, useTrackCountryBannerView } from '../hooks/trackCountryBanner';

export function CountryBannerContainer({
	petitionId,
	context,
	countryBanner,
}: {
	countryBanner: CountryBannerConfig;
	context: CountryBannerContext;
	petitionId?: string;
}): JSX.Element {
	useTrackCountryBannerView(countryBanner, context, petitionId);
	const trackClick = useCountryBannerClickTracking(countryBanner, context, petitionId);
	return (
		<Banner
			mobileContent={countryBanner.mobileContent}
			desktopContent={countryBanner.content}
			onLinkClick={trackClick}
		/>
	);
}
