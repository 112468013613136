import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'admin-users-impersonate',
		path: '/dashboard/users/impersonate',
		component: loadable(async () => import(/* webpackChunkName: "pages/admin_users_impersonate" */ './index'), {
			exportName: 'AdminUsersImpersonate',
			fallback: Loading,
		}),
		restrictedAccess: {
			authOnly: true,
			role: ['support', 'campaigns'],
		},
		frame: true,
		ssr: {
			enabled: false,
			seo: false,
		},
	},
];
