import { useMemo } from 'react';
import type { JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

type Args = {
	dailySignatureCount: number;
	dailySignatureCountLocalized: string;
	weeklySignatureCount: number;
	weeklySignatureCountLocalized: string;
	createdLessThanOneDayAgo: boolean;
};

export function useSocialProofLabel({
	dailySignatureCount,
	dailySignatureCountLocalized,
	weeklySignatureCount,
	weeklySignatureCountLocalized,
	createdLessThanOneDayAgo,
}: Args): string | JSX.Element | JSX.Element[] {
	const { translate, translatePluralHtml } = useI18n();
	return useMemo(() => {
		if (createdLessThanOneDayAgo) {
			return translate('corgi.petition.details.social_proof.gaining_momentum');
		}
		if (dailySignatureCount >= 50) {
			return translatePluralHtml('corgi.petition.details.social_proof.signed_today', dailySignatureCount, {
				num: dailySignatureCountLocalized,
			});
		}
		if (weeklySignatureCount >= 50) {
			return translatePluralHtml('corgi.petition.details.social_proof.signed_this_week', weeklySignatureCount, {
				num: weeklySignatureCountLocalized,
			});
		}
		return translate('corgi.petition.details.social_proof.support_now');
	}, [
		translate,
		translatePluralHtml,
		createdLessThanOneDayAgo,
		dailySignatureCount,
		dailySignatureCountLocalized,
		weeklySignatureCount,
		weeklySignatureCountLocalized,
	]);
}
