import { getMediaAssetsBasePath } from '@change-corgi/config/mediaAssets';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

export const useMediaAssetsBasePath = (): string => {
	const {
		environment: { getApiEnvironment },
	} = useUtilityContext();
	const environment = getApiEnvironment();
	return getMediaAssetsBasePath(environment !== 'development' ? environment : 'demo');
};
