import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { createPrefetchableAsyncDataContext } from 'src/app/shared/contexts/asyncData';

export const {
	Context: MembershipReminderPopupDataContext,
	Provider: MembershipReminderPopupDataContextProvider,
	useContext: useMembershipReminderPopupData,
	useAsyncData: useMembershipReminderPopupDataFromApi,
	prefetchAsyncData: prefetchMembershipReminderPopupDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'MembershipReminderPopupDataContext',
	dataProperty: 'data',
	hasUserData: true,
	getData: async (_utilityContext: UtilityContext) => Promise.resolve({}),
});
