import type { WordBreak } from '../types';

import { getTruncatedValue } from './getTruncatedValue';

const SPLIT_CHARS = ['.', ' '];

type Options = Readonly<{
	checkContentsFit: () => boolean;
	wordBreak: WordBreak;
}>;

export function getBestTruncatedValue(el: Text, { checkContentsFit, wordBreak }: Options): string {
	if (wordBreak === 'always') {
		return getTruncatedValue(el, { splitChar: '', checkContentsFit });
	}

	// get truncated value for each split char
	const possibleValues = SPLIT_CHARS.map((splitChar) => getTruncatedValue(el, { splitChar, checkContentsFit }));
	// select the longest possible value
	const bestPossibleValue = possibleValues.reduce<string>(
		(acc, value) => (value.length > acc.length ? value : acc),
		'',
	);

	if (bestPossibleValue || wordBreak === 'never') {
		return bestPossibleValue;
	}

	// if the longest possible value is empty, and word breaking is allowed,
	// try with word breaking
	return getTruncatedValue(el, { splitChar: '', checkContentsFit });
}
