import { getLocation } from '@change-corgi/core/window';

function getRedirectPathFromLocation(): string {
	const location = getLocation();
	return `${location.pathname}${location.search}`;
}

export function getLoginRedirectUrl(path?: string): string {
	return `/login_or_join?redirectTo=${encodeURIComponent(path || getRedirectPathFromLocation())}`;
}
