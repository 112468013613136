import type { RefObject } from 'react';
import { useEffect } from 'react';

import { createFallback } from '../shared/createFallback';
import type { FocusFallbackListener } from '../types';

import { useFocusFallbackContext } from './useFocusFallbackContext';

export function useFocusFallback(
	ref: RefObject<HTMLElement | null | undefined> | undefined,
	listener?: FocusFallbackListener,
): void {
	const context = useFocusFallbackContext();

	useEffect(() => {
		if (!context || !ref) return undefined;

		const fallback = createFallback(ref, listener);

		context.fallbacks = [...context.fallbacks, fallback];

		return () => {
			context.fallbacks = context.fallbacks.filter((f) => f !== fallback);
		};
	}, [context, ref, listener]);
}
