import { useMemo } from 'react';

import random from 'lodash/fp/random';

import { usePetitionDetails } from '../../petitionDetails';

export function useHeadlineReplacementsRandom(): Record<string, string | number> {
	const petition = usePetitionDetails();

	// we want to force re-randomizing when the petition changes
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const randomNumbers = useMemo(() => [random(1, 9), random(10, 99), random(100, 999)], [petition]);

	return useMemo(
		() => ({
			random_1_digit_number: randomNumbers[0],
			random_2_digit_number: randomNumbers[1],
			random_3_digit_number: randomNumbers[2],
		}),
		[randomNumbers],
	);
}
