import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useIntersectionObserver } from '../useIntersectionObserver';

type ButtonState = 'none' | 'start' | 'all' | 'end';

type Ref = MutableRefObject<Element | null>;
type UseScrollContainerReturn = ModelHookResult<
	{ scrollRightDisabled: boolean; scrollLeftDisabled: boolean },
	{ handleScrollEnd: () => void; scrollToRight: () => void; scrollToLeft: () => void }
>;

// eslint-disable-next-line max-lines-per-function
export function useScrollContainer(scrollContainerRef: Ref): UseScrollContainerReturn {
	const [buttonState, setButtonState] = useState<ButtonState>('none');
	const entry = useIntersectionObserver({ root: scrollContainerRef, threshold: 0.5 });
	const scrollLeftDisabled = buttonState === 'start' || buttonState === 'none';
	const scrollRightDisabled = buttonState === 'end' || buttonState === 'none';

	const scrollToLeft = () => {
		if (!scrollContainerRef.current || !entry) return;

		const scrollContainer = scrollContainerRef.current;
		const startPosition = scrollContainer.scrollLeft;

		const currentCard = entry.target;
		const previousCard = currentCard.previousElementSibling;
		const scrollAmount = previousCard ? previousCard.scrollWidth : currentCard.scrollWidth;

		scrollContainerRef.current.scrollTo({
			left: startPosition - scrollAmount,
			behavior: 'smooth',
		});
	};

	const scrollToRight = () => {
		if (!scrollContainerRef.current || !entry) return;

		const scrollContainer = scrollContainerRef.current;
		const startPosition = scrollContainer.scrollLeft;

		const currentCard = entry.target;
		const nextCard = currentCard.nextElementSibling;
		const scrollAmount = nextCard ? nextCard.clientWidth : currentCard.clientWidth;

		scrollContainer.scrollTo({
			left: startPosition + scrollAmount,
			behavior: 'smooth',
		});
	};

	const getButtonState = useCallback((): ButtonState => {
		const scrollContainer = scrollContainerRef.current;
		if (!scrollContainer) return buttonState;

		const isScrollable = scrollContainer.scrollWidth !== scrollContainer.clientWidth;
		if (!isScrollable) return 'none';

		const isStartOfScroll = scrollContainer.scrollLeft === 0;
		if (isStartOfScroll) return 'start';

		// offset calculation is sometimes off by one, so we check both scenarios
		const offset = scrollContainer.scrollWidth - scrollContainer.clientWidth;
		const isEndOfScroll = offset === scrollContainer.scrollLeft || offset + 1 === scrollContainer.scrollLeft;
		if (isEndOfScroll) return 'end';

		return 'all';
	}, [buttonState, scrollContainerRef]);

	// initialize button state
	useEffect(() => {
		if (scrollContainerRef?.current) {
			setButtonState(getButtonState());
		}
	}, [getButtonState, scrollContainerRef]);

	const handleScrollEnd = () => {
		setButtonState(getButtonState());
	};

	return {
		data: { scrollLeftDisabled, scrollRightDisabled },
		actions: { handleScrollEnd, scrollToRight, scrollToLeft },
	};
}
