import type { ForwardedRef, JSX, PropsWithChildren, RefObject } from 'react';
import { createRef, forwardRef, PureComponent } from 'react';

import throttle from 'lodash/fp/throttle';

import { waitForFonts } from 'src/app/shared/utils/fonts';

import { truncate } from './shared/truncate';
import type { WordBreak } from './shared/types';

type Props = Readonly<{
	/*
	 * when using a relative height, it is recommended to enable watchResize
	 */
	height: number | string;
	/*
	 * The ellipsis
	 *
	 * Default to "…"
	 */
	ellipsis?: string | JSX.Element;
	/*
	 * if true, will use height instead of max-height
	 */
	fixedHeight?: boolean;
	/*
	 * change the word breaking behavior
	 *
	 * - avoid (default): only breaks words if necessary
	 * - never: never breaks word
	 * - always: always breaks word to fit the more text as possible
	 *
	 * example of word braking:
	 * "Hello wor..." instead of "Hello ..."
	 */
	wordBreak?: WordBreak;
	/*
	 * updates when the element is resized
	 * (using ResizeObserver if available)
	 */
	watchResize?: boolean;
	/*
	 * The throttling of the resize update (for performance's sake), in ms
	 *
	 * Default it 200ms
	 */
	watchResizeThrottling?: number;
}>;

type State = Readonly<{
	fontsReady: boolean;
	windowResizeTs: number;
	containerVisible: boolean;
}>;

const HeightContainer = forwardRef(function HeightContainerInner(
	{ fixedHeight, height, children }: PropsWithChildren<{ fixedHeight: boolean; height: number | string }>,
	ref: ForwardedRef<HTMLDivElement>,
) {
	const heightType = fixedHeight ? 'height' : 'maxHeight';
	return (
		<div
			sx={{
				[heightType]: typeof height === 'string' ? height : `${height}px`,
				overflow: 'hidden',
			}}
			ref={ref}
		>
			{children}
		</div>
	);
});

const DEFAULT_RESIZE_THROTTLING = 200;

export class VerticalEllipsis extends PureComponent<PropsWithChildren<Props>, State, true> {
	private readonly containerRef: RefObject<HTMLDivElement>;
	private readonly contentsRef: RefObject<HTMLDivElement>;
	private readonly ellipsisRef: RefObject<HTMLSpanElement>;
	private readonly resizeObserver?: ResizeObserver;
	private onResizeThrottled?: () => void;

	// used to reset DOM to its state before truncation
	private resetDom: undefined | (() => void);

	// used to cleanup between mounts
	private cleanUpObserver: undefined | (() => void);

	constructor(props: Props) {
		super(props);

		this.containerRef = createRef<HTMLDivElement>();
		this.contentsRef = createRef<HTMLDivElement>();
		this.ellipsisRef = createRef<HTMLSpanElement>();

		this.state = { fontsReady: false, windowResizeTs: 0, containerVisible: true };

		this.updateOnResizeThrottled();

		this.resizeObserver =
			typeof ResizeObserver !== 'undefined'
				? new ResizeObserver(() => {
						this.onResizeThrottled?.();
					})
				: undefined;

		void this.waitForFonts();
	}

	private async waitForFonts() {
		this.setState({ fontsReady: await waitForFonts() });
	}

	private updateOnResizeThrottled() {
		const { watchResizeThrottling } = this.props;
		const onResize = this.onResize.bind(this);
		const throttling = watchResizeThrottling ?? DEFAULT_RESIZE_THROTTLING;
		this.onResizeThrottled = throttling > 0 ? throttle(throttling)(onResize) : onResize;
	}

	private onResize() {
		this.resetDom?.();
		this.truncate();
	}

	getSnapshotBeforeUpdate(): true | null {
		// cheating a bit and using this to reset the DOM that we modified in truncate
		// so that React updates its DOM using the DOM as it was before truncating
		if (this.resetDom) {
			this.resetDom();
			this.resetDom = undefined;
			return true;
		}
		return null;
	}

	// eslint-disable-next-line class-methods-use-this
	componentDidUpdate(_prevProps: Props, _prevState: State, snapshot: true | null | undefined): void {
		const { watchResize } = this.props;

		this.updateOnResizeThrottled();

		if (!watchResize) {
			this.cleanUpObserver?.();
		}

		if (snapshot) {
			this.truncate();
		}
	}

	componentDidMount(): void {
		const { watchResize } = this.props;

		const container = this.containerRef.current;

		watchResize && container && this.resizeObserver?.observe(container);

		this.cleanUpObserver = () => container && this.resizeObserver?.unobserve(container);

		this.truncate();
	}

	componentWillUnmount(): void {
		this.cleanUpObserver?.();
		this.resetDom?.();
	}

	private truncate() {
		const containerDiv = this.containerRef.current;
		const contentsDiv = this.contentsRef.current;
		const ellipsisDiv = this.ellipsisRef.current;

		if (!containerDiv || !contentsDiv || !ellipsisDiv) {
			return;
		}

		const { height, wordBreak } = this.props;

		this.resetDom = truncate({
			height,
			container: containerDiv,
			contents: contentsDiv,
			ellipsis: ellipsisDiv,
			wordBreak: wordBreak || 'avoid',
		});
	}

	render(): JSX.Element {
		const { height, children, fixedHeight, ellipsis } = this.props;
		return (
			<HeightContainer ref={this.containerRef} fixedHeight={!!fixedHeight} height={height}>
				<div ref={this.contentsRef}>{children}</div>
				<span ref={this.ellipsisRef}>{ellipsis || '…'}</span>
			</HeightContainer>
		);
	}
}
