import React from 'react';

import { TranslateHtml, useI18n } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Image } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useButtonContainer } from './hooks/useButtonContainer';

type Props = Parameters<typeof useButtonContainer>[0];

export function ButtonContainer(props: Props): React.JSX.Element {
	const { translate } = useI18n();
	const {
		data: { selectedAmount, buttonAmounts, buttonText, ctaButtonLink, otherLink, paymentTerm },
		actions: { onClick },
	} = useButtonContainer(props);

	return (
		<Flex sx={{ flexFlow: 'column', gap: 8 }}>
			<Flex sx={{ gap: 8, flexWrap: 'wrap' }}>
				{buttonAmounts.map(({ amount, amountLocalized, link }, index) => {
					return (
						<ButtonLink
							variant={amount === selectedAmount.amount ? 'secondaryEmphasis' : 'secondary'}
							target="_blank"
							size="medium"
							to={link}
							key={amount}
							sx={{ flexGrow: 1, flexShrink: 0, flexBasis: '33%' }}
							onClick={onClick}
							data-qa={`membership-popup-amount-button-${index}`}
						>
							{paymentTerm === 'yearly' && `${amountLocalized} per year`}
							{paymentTerm === 'monthly' &&
								translate('fe.pages.subscriptions.manage.amount_per_month', { amount: amountLocalized })}
							{paymentTerm === 'one-time' && amountLocalized}
						</ButtonLink>
					);
				})}
			</Flex>
			<ButtonLink
				variant="secondary"
				size="medium"
				target="_blank"
				to={otherLink}
				onClick={onClick}
				data-qa="membership-popup-other-button"
			>
				{translate('corgi.membership.awareness.other_amount_label')}
			</ButtonLink>
			<ButtonLink target="_blank" to={ctaButtonLink} onClick={onClick} data-qa="membership-popup-continue-button">
				{buttonText}
			</ButtonLink>
			<Box sx={{ textAlign: 'center' }}>
				<Text>
					<TranslateHtml
						value="corgi.membership.awareness.pay_with"
						replacements={{
							paypalImageElement: (
								<Image
									alt="paypal"
									src="https://static.change.org/membership/paypal.svg"
									sx={{
										marginBottom: '-4px',
										display: 'inline',
									}}
								/>
							),
						}}
					/>
				</Text>
			</Box>
		</Flex>
	);
}
