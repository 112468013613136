// should this be moved to fe-design-system as an option on Text?

import type { ComponentProps, JSX, ReactNode } from 'react';
import { Children } from 'react';

import { Text } from '@change-corgi/design-system/typography';

export function TextNl2Br({ children, ...rest }: ComponentProps<typeof Text>): JSX.Element {
	const childrenAsArray = Children.toArray(children);

	return <Text {...rest}>{childrenAsArray.map((child) => nl2br(child))}</Text>;
}

const NEWLINE_REGEX = /(\r\n|\r|\n)/g;

function nl2br(str: ReactNode) {
	if (typeof str !== 'string') {
		return str;
	}

	return str.split(NEWLINE_REGEX).map((line, index) => {
		if (line.match(NEWLINE_REGEX)) {
			// eslint-disable-next-line react/no-array-index-key
			return <br key={index} />;
		}
		return line;
	});
}
