import gql from 'graphql-tag';

import type { Utilities } from '@change-corgi/core/react/utilityContext';

import type {
	PetitionDetailsPageSetRecruiterMutation,
	PetitionDetailsPageSetRecruiterMutationVariables,
} from './setRecruiter.graphql';

const QUERY = gql`
	mutation PetitionDetailsPageSetRecruiter($input: SetPetitionRecruiterInput!) {
		setPetitionRecruiter(input: $input) {
			success
		}
	}
`;

export async function setPetitionRecruiter(
	input: PetitionDetailsPageSetRecruiterMutationVariables['input'],
	utilities: Pick<Utilities, 'gql' | 'errorReporter'>,
): Promise<void> {
	try {
		await utilities.gql.fetch<
			PetitionDetailsPageSetRecruiterMutation,
			PetitionDetailsPageSetRecruiterMutationVariables
		>({
			query: QUERY,
			variables: {
				input,
			},
		});
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		void utilities.errorReporter.report(new Error(`Error storing petition recruiter data: ${e}`));
	}
}
