import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		minVideoSize: { type: 'number' },
		maxVideoSize: { type: 'number' },
		maxImageSize: { type: 'number' },
		// Note: Please update details file description for new properties added.
	},
});
export const FCM_CAMPAIGN_SUPPORTER_VOICES_SETTINGS = {
	name: 'campaign_supporter_voices_settings',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		minVideoSize: 0.04,
		maxVideoSize: 20,
		maxImageSize: 5,
	} as ReturnType<typeof normalizer>,
};
