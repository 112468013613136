import { useCallback, useEffect } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { CountryBannerConfig, CountryBannerContext } from 'src/app/shared/hooks/banners/country';

type ViewEventProps = Readonly<{
	petition_id?: string;
	context: string;
	banner_petition_id?: string;
}>;

export function useTrackCountryBannerView(
	countryBanner: CountryBannerConfig,
	context: CountryBannerContext,
	petitionId?: string,
): void {
	const track = useTracking();

	useEffect(() => {
		const bannerPetitionId = countryBanner.banner_petition_id;
		void track<ViewEventProps>('country_banner_view', {
			petition_id: petitionId,
			context,
			...(bannerPetitionId && { banner_petition_id: bannerPetitionId }),
		});
	}, [track, petitionId, countryBanner, context]);
}

export function useCountryBannerClickTracking(
	countryBanner: CountryBannerConfig,
	context: CountryBannerContext,
	petitionId?: string,
): () => void {
	const track = useTracking();

	return useCallback(() => {
		const bannerPetitionId = countryBanner.banner_petition_id;
		void track<ViewEventProps>('country_banner_click', {
			petition_id: petitionId,
			context,
			...(bannerPetitionId && { banner_petition_id: bannerPetitionId }),
		});
	}, [track, petitionId, countryBanner, context]);
}
