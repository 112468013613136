import { useCallback, useMemo, useState } from 'react';

import type { TranslationKey } from '@change-corgi/core/i18n';

const RESET_LABEL_TIMER = 3000;

type ShareData = { clickedState: boolean; buttonLabel: TranslationKey };
type ShareActions = { share: () => Promise<void> };
type UseDefaultShareButtonResult = ModelHookResult<ShareData, ShareActions>;
type UseShareButtonArgs = {
	action: () => Promise<boolean> | Promise<void>;
	label: TranslationKey;
	successLabel?: TranslationKey;
};
export function useShareButton({ action, label, successLabel }: UseShareButtonArgs): UseDefaultShareButtonResult {
	const [clickedState, setClickedState] = useState(false);

	const share = useCallback(async () => {
		await action();
		if (successLabel) {
			setClickedState(true);
			// Timeout to reset the style
			window.setTimeout(() => {
				setClickedState(false);
			}, RESET_LABEL_TIMER);
		}
	}, [action, setClickedState, successLabel]);

	const buttonLabel = useMemo(
		() => (successLabel && clickedState ? successLabel : label),
		[clickedState, successLabel, label],
	);

	return {
		data: { clickedState, buttonLabel },
		actions: { share },
	};
}
