import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'record',
	shape: {
		type: 'array',
		shape: { type: 'number' },
	},
});

export const FCM_CONTEXTUAL_PROMOTION_BUTTONS_BANDIT_CONFIG = createFcmConfig(
	'contextual_promotion_buttons_bandit_config',
	{
		normalizer: createJsonNormalizer(normalizer),
		defaultValue: {},
	},
);
