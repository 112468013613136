import { useMemo } from 'react';

import { usePetitionDetailsPageFcm } from 'src/app/pages/petition/details/pageContext';

/**
 * @deprecated use usePetitionDetailsPageFcm() instead
 */
export function useFcm() {
	const fcm = usePetitionDetailsPageFcm();
	return useMemo(
		() => ({
			status: 'loaded',
			values: fcm,
		}),
		[fcm],
	);
}

/**
 * @deprecated use usePetitionDetailsPageFcm() instead
 */
export const useSignFormFcm = useFcm;
