import type { ChangeEvent, JSX } from 'react';
import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Select } from '@change-corgi/design-system/components/forms';

type Props = {
	id: string;
	locales: ReadonlyArray<{ display: string; locale: string }>;
	currentLocale: string;
	onLocaleChange?: (locale: string) => void;
};

export function LocaleSelect({ id, onLocaleChange, locales, currentLocale }: Props): JSX.Element | null {
	const { translate } = useI18n();

	const onChange = useCallback(
		(e: ChangeEvent<HTMLSelectElement>) => onLocaleChange && onLocaleChange(e.target.value),
		[onLocaleChange],
	);

	return (
		<Select
			id={id}
			data-testid="locale-select"
			aria-label={translate('fe.components.footer.locale')}
			sx={{ fontSize: 12 }}
			value={currentLocale}
			onChange={onChange}
		>
			{locales.map(({ display, locale }) => (
				<option value={locale} key={locale}>
					{display}
				</option>
			))}
		</Select>
	);
}
