import type { JSX } from 'react';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { Translate } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { SocialButton } from '@change-corgi/design-system/components/social';
import { iconCheck } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';
import { Text } from '@change-corgi/design-system/typography';

import type { ShareChannel } from 'src/app/shared/hooks/share';

import { useShareButton } from './hooks/useShareButton';

type SupportedShareChannels = Exclude<ShareChannel, 'qrcode' | 'flyer'>;
type ShareButtonProps = {
	action: () => Promise<boolean> | Promise<void>;
	channel: SupportedShareChannels;
	label: TranslationKey;
	successLabel?: TranslationKey;
	labelSize?: ResponsiveValue<'default' | 'small'>;
	isSelected?: boolean;
};
export function ShareButton({
	action,
	channel,
	label,
	successLabel,
	labelSize,
	isSelected = false,
}: ShareButtonProps): JSX.Element {
	const { data, actions } = useShareButton({ action, label, successLabel });
	const socialButtonStyling = isSelected
		? { width: '100%', borderWidth: '2px', borderColor: 'black' }
		: { width: '100%' };
	return (
		<SocialButton channel={channel} onClick={actions.share} sx={socialButtonStyling}>
			<Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
				<Text
					aria-live="polite"
					as="span"
					size={labelSize}
					sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					<Translate value={data.buttonLabel} />
				</Text>
				{data.clickedState ? <Icon icon={iconCheck} size={20} ml={4} color="typography-positive" /> : null}
			</Flex>
		</SocialButton>
	);
}
