import type { JSX } from 'react';

import { stripHtmlTags } from '@change-corgi/core/html';
import { memo } from '@change-corgi/core/react/core';
import { Text } from '@change-corgi/design-system/typography';

import type { PetitionContentUpdate } from 'src/app/pages/petition/details/shared/types';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

export const UpdateBody = memo(function UpdateBody({ update }: Props): JSX.Element | null {
	if (!update.description) {
		return null;
	}

	return (
		<Text
			as="div"
			color="typography-primary"
			lineClamp={[4, 3]}
			sx={{
				height: [96, 72],
			}}
		>
			{stripHtmlTags(update.description)}
		</Text>
	);
});
