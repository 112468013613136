import type { TrackingFn } from '@change-corgi/core/react/tracking';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { trackShareCancel, trackShareFailure, trackShareIntent, trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		intent: string;
		success: string;
		failure: string;
		cancel: string;
	};
};

export async function shareFacebook(
	url: string,
	{ trackingEvents, trackingData }: Options,
	utilityContext: UtilityContext,
	track: TrackingFn,
): Promise<boolean> {
	const { errorReporter, facebook } = utilityContext;
	trackShareIntent(trackingEvents.intent, trackingData, utilityContext, track);
	try {
		const isDesktop = !['mobile', 'tablet'].includes(
			utilityContext.userAgent.getParsedUserAgent().getDevice().type || '',
		);
		const response = await facebook.ui({
			method: 'share',
			href: url,
			// the SDK defaults to iframe on desktop when the user
			// is logged in with their FB account
			...(isDesktop && { display: 'popup' }),
		});
		if (response.cancelled) {
			trackShareCancel(trackingEvents.cancel, trackingData, utilityContext, track);
			return false;
		}
		trackShareSuccess(trackingEvents.success, trackingData, utilityContext, track);
		return true;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		void errorReporter.report(new Error(`Could not share to facebook: ${e}`));
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		trackShareFailure(trackingEvents.failure, { error: e.toString() }, trackingData, utilityContext, track);
		return false;
	}
}
