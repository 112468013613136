import type { TrackingFn } from '@change-corgi/core/react/tracking';
import { openWindow } from '@change-corgi/core/window';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

export function shareLine(
	url: string,
	text: string | undefined,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
): void {
	const msg = encodeURIComponent(text ? `${text}\n${url}` : url);
	openWindow(`line://msg/text/${msg}`);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
