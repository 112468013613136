import isEqual from 'lodash/isEqual';
import { useCustomCompareCallback } from 'use-custom-compare';

import type { ConfirmModalOptions } from './useConfirmModal';
import { useConfirmModal } from './useConfirmModal';

/**
 * Can be used to open a confirm modal.
 *
 * Options can be passed to the hook itself, or to the function returned by the hook.
 *
 * @example
 * const confirm = useConfirm({
 *  title: 'Are you sure?',
 *  text: [
 *    'paragraph 1',
 *    'paragraph 2',
 *  ],
 *  });
 *  // ...
 *  if (await confirm()) {
 *    // do something
 *  }
 */
export function useConfirm(
	options: Partial<ConfirmModalOptions> = {},
): (optionsOverrides?: Partial<ConfirmModalOptions>) => Promise<boolean> {
	const openConfirmModal = useConfirmModal();

	return useCustomCompareCallback(
		async (optionsOverrides = {}) => {
			const modalResult = await openConfirmModal({ ...options, ...optionsOverrides }).waitForResult();
			return !!modalResult?.choice;
		},
		[openConfirmModal, options],
		(prevDeps, nextDeps) => isEqual(prevDeps, nextDeps),
	);
}
