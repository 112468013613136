import { Button } from '@change-corgi/design-system/components/actions';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { PageRenderingButton, SupporterVideo } from '../../shared/types';

import { useLikeVideo } from './hooks/useLikeVideo';
import { LikeIcon, UnLikeIcon } from './LikeVideoIcons';

type LikeVideoProps = {
	video: SupporterVideo;
	petitionId: string;
	renderedOn: PageRenderingButton;
};

// eslint-disable-next-line max-lines-per-function
export function LikeVideo({ video, petitionId, renderedOn }: LikeVideoProps): React.JSX.Element | null {
	const {
		data: { isVideoLiked, videoLikeCount, isGuest },
		actions: { handleLikeClick },
	} = useLikeVideo({ video, petitionId, renderedOn });

	if (isGuest) {
		return null;
	}

	const ariaPrefix = isVideoLiked ? 'unlike video' : 'like video';

	return (
		<Button
			size="small"
			aria-label={`${ariaPrefix} from ${video.user?.shortDisplayName}`}
			data-testid={`supporter-video-${video.id}-like-button-combo`}
			sx={{
				border: 'none',
				background: 'primary-greyBackground',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					background: 'rgba(0,0,0,0.1)',
				},
			}}
			onClick={handleLikeClick}
		>
			<Flex sx={{ alignItems: 'center' }}>
				{isVideoLiked ? <UnLikeIcon videoId={video.id} /> : <LikeIcon videoId={video.id} />}
				<Text
					fontWeight="bold"
					size="small"
					data-testid={`supporter-video-${video.id}-like-count`}
					ml={8}
					color={isVideoLiked ? 'primary-changeRed' : 'primary-black'}
				>
					{videoLikeCount}
				</Text>
			</Flex>
		</Button>
	);
}
