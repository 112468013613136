import type { JSX } from 'react';

import { useI18n, withI18nPlaceholders } from '@change-corgi/core/react/i18n';
import { ComboBox } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/growth/petition-place.yml';

import { usePlaceField } from '../hooks/usePlaceField';
import type { GeoScope, Place } from '../shared/types';

export type PlaceFieldProps = {
	label: string;
	geoScope?: GeoScope;
	defaultPlaceValue?: Place | null;
	onSelect?: (place: Place | null) => void;
	error?: string | null;
};

function PlaceFieldComponent({
	geoScope,
	defaultPlaceValue = null,
	label,
	onSelect,
	error,
}: PlaceFieldProps): JSX.Element {
	const { translate } = useI18n();

	const { placeInputValue, onPlaceInputChange, placeValue, onPlaceChange, placePredictions } = usePlaceField({
		geoScope,
		defaultPlaceValue,
		onSelect,
	});

	return (
		<Box mt={16}>
			<ComboBox
				id="petition-place"
				name="petition-place"
				label={label || translate('corgi.components.place.label')}
				inputValue={placeInputValue}
				onInputChange={(_, newInputValue) => onPlaceInputChange(newInputValue)}
				value={placeValue}
				onChange={(_, newValue) => onPlaceChange(newValue)}
				options={placePredictions}
				filterOptions={(_) => placePredictions}
				optionToString={(place) => place.displayName}
				// overriding the combobox popover zIndex to place the listbox
				// over the content on the page -- a fix for correct placement of the listbox
				// should be moved to the design system
				// eslint-disable-next-line @typescript-eslint/naming-convention
				sx={{ '& #petition-place-popover': { zIndex: 5 } }}
				error={error}
			/>
		</Box>
	);
}

export const PlaceField = withI18nPlaceholders(I18N_PLACEHOLDERS, PlaceFieldComponent);
