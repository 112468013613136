import type { NonBetaLocale } from '@change-corgi/config/locales';
import { routes } from '@change-corgi/config/routes';
import type { Routes } from '@change-corgi/config/routes';

import { useLocale } from 'src/app/shared/hooks/l10n';

export function useRoutes(): Routes {
	const locale = useLocale();
	return routes[locale as NonBetaLocale] || routes['en-US'];
}
