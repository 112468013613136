import type { QueryHookResult } from '@change-corgi/core/react/async';
import { useQuery } from '@change-corgi/core/react/async';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

import type { CurrencyInfo } from './api';
import { getUserCurrency } from './api';

// TODO could we just add the currency to the session object in fe-core?
export function useCurrentUserCurrency(): QueryHookResult<{ currency: CurrencyInfo }> {
	const utilityContext = useUtilityContext();
	// FIXME: should this use user.country.code and only countryCode as a fallback for guest users?
	const countryCode = useCountryCode();

	return useQuery(
		async () => ({ currency: await getUserCurrency(countryCode, utilityContext) }),
		[countryCode, utilityContext],
	);
}

export type { CurrencyInfo };
