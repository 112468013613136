import { useMemo } from 'react';

import { useCommonFcm } from 'src/app/shared/hooks/commonFcm';
import { useIsMembershipEligibleCountry } from 'src/app/shared/hooks/membership';
import { isLoaded } from 'src/app/shared/utils/async';

import type { BadgeProps } from '../index';

type Props = Omit<BadgeProps, 'size' | 'sourceLocation' | 'verticalAlign'>;

export function useIsVisible({ userId, membershipData }: Props): boolean {
	const isVisitorEligibleForMembership = useIsMembershipEligibleCountry();
	const commonFcm = useCommonFcm();

	return useMemo(() => {
		if (!isVisitorEligibleForMembership) return false;

		if (!membershipData) return false;
		if (membershipData.source.startsWith('POST_PROMOTE_UPSELL')) return false;
		if (!isLoaded(commonFcm)) return false;
		const { membershipBadge } = commonFcm.values;
		if (!membershipBadge) return false;
		return membershipBadge.enabled && !membershipBadge.disable_for_users.includes(Number.parseInt(userId, 10));
	}, [commonFcm, isVisitorEligibleForMembership, membershipData, userId]);
}
