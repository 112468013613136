import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		path: '/p/:slug/smart/edit',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { slug }, queryString }) => `/p/${slug!}/smart${queryString}`,
		reason: 'deprecated-url',
	},
];
