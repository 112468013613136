import type { RestrictedAccessConfigRoleFn } from 'src/shared/routes';

import { getStarterId } from './api/getStarterId';

export function starter(slugOrIdParam: string): RestrictedAccessConfigRoleFn {
	// function is named for the benefit of src/server/misc/routesStats.ts
	// eslint-disable-next-line @typescript-eslint/no-shadow
	return async function starter({ session, utilities, params }) {
		const slugOrId = params[slugOrIdParam];
		if (!slugOrId) throw new Error(`Unexpected empty param for "${slugOrIdParam}"`);
		if (!session.user) return false;
		const starterId = await getStarterId(utilities, slugOrId);
		return starterId === session.user.id;
	};
}
