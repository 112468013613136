import type { JSX } from 'react';

import { Image } from '@change-corgi/design-system/components/media';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Box, Flex } from '@change-corgi/design-system/layout';

import type { PetitionContentUpdate } from 'src/app/pages/petitionGamma/details/shared/types';
import { useLoadImage } from 'src/app/shared/hooks/image';

type Props = Readonly<{
	update: PetitionContentUpdate;
}>;

const MAX_ATTEMPTS = 10;
const POLLING_TIME = 3 * 1000; // 3sec
const IMAGE_WIDTH = 64;

export function UpdateMedia({ update }: Props): JSX.Element | null {
	const { photo } = update;

	const imageLoaded = useLoadImage(photo?.url, {
		maxAttempts: MAX_ATTEMPTS,
		pollingTime: POLLING_TIME,
		processing: photo?.processing,
	});

	if (!photo) {
		return null;
	}

	if (imageLoaded) {
		return (
			<Box>
				<Image
					aspectRatio="1"
					fit="cover"
					sx={{
						width: IMAGE_WIDTH,
						borderRadius: 'standard',
						borderStyle: 'solid',
						borderColor: 'neutral-grey50',
						borderWidth: 1,
					}}
					src={photo.url}
					alt=""
					loading="lazy"
				/>
			</Box>
		);
	}

	return (
		<Box>
			<Flex
				sx={{
					aspectRatio: '1',
					width: IMAGE_WIDTH,
					alignItems: 'center',
					justifyContent: 'center',
				}}
				aria-hidden="true"
			>
				<Loader size="xs" />
			</Flex>
		</Box>
	);
}
