import { NOT_KNOWN } from './constants';

export function isFbLoggedIn(status?: string): 'not_known' | boolean {
	if (status === 'connected') {
		return true;
	}
	if (status === 'unknown') {
		return NOT_KNOWN;
	}
	return false;
}
