import { useMemo } from 'react';

import { isError, isLoading } from 'src/app/shared/utils/async';
import type { State, StateWithoutError } from 'src/app/shared/utils/async';

import type { MergedState, MergedStateWithoutError } from './types';

/**
 * hook that can be used to merge multiple state-like objects.
 *
 * - if any is loading => loading state
 * - otherwise, if any is error => first error state
 * - otherwise => loaded state with merged properties
 *
 * if the error state has error data, it is recommended to at least set the "error" property
 * which can then be used as a discriminant in the merged state
 */
function useMergedStates<S1 extends StateWithoutError>(...states: readonly [S1]): MergedStateWithoutError<S1>;
function useMergedStates<S1 extends StateWithoutError, S2 extends StateWithoutError>(
	...states: readonly [S1, S2]
): MergedStateWithoutError<S1, S2>;
function useMergedStates<S1 extends StateWithoutError, S2 extends StateWithoutError, S3 extends StateWithoutError>(
	...states: readonly [S1, S2, S3]
): MergedStateWithoutError<S1, S2, S3>;
function useMergedStates<
	S1 extends StateWithoutError,
	S2 extends StateWithoutError,
	S3 extends StateWithoutError,
	S4 extends StateWithoutError,
>(...states: readonly [S1, S2, S3, S4]): MergedStateWithoutError<S1, S2, S3, S4>;
function useMergedStates<
	S1 extends StateWithoutError,
	S2 extends StateWithoutError,
	S3 extends StateWithoutError,
	S4 extends StateWithoutError,
	S5 extends StateWithoutError,
>(...states: readonly [S1, S2, S3, S4, S5]): MergedStateWithoutError<S1, S2, S3, S4, S5>;
function useMergedStates<S1 extends State>(...states: readonly [S1]): MergedState<S1>;
function useMergedStates<S1 extends State, S2 extends State>(...states: readonly [S1, S2]): MergedState<S1, S2>;
function useMergedStates<S1 extends State, S2 extends State, S3 extends State>(
	...states: readonly [S1, S2, S3]
): MergedState<S1, S2, S3>;
function useMergedStates<S1 extends State, S2 extends State, S3 extends State, S4 extends State>(
	...states: readonly [S1, S2, S3, S4]
): MergedState<S1, S2, S3, S4>;
function useMergedStates<S1 extends State, S2 extends State, S3 extends State, S4 extends State, S5 extends State>(
	...states: readonly [S1, S2, S3, S4, S5]
): MergedState<S1, S2, S3, S4, S5>;
function useMergedStates<
	S1 extends State,
	S2 extends State,
	S3 extends State,
	S4 extends State,
	S5 extends State,
	S6 extends State,
>(...states: readonly [S1, S2, S3, S4, S5, S6]): MergedState<S1, S2, S3, S4, S5, S6>;
function useMergedStates<
	S1 extends State,
	S2 extends State,
	S3 extends State,
	S4 extends State,
	S5 extends State,
	S6 extends State,
	S7 extends State,
>(...states: readonly [S1, S2, S3, S4, S5, S6, S7]): MergedState<S1, S2, S3, S4, S5, S6, S7>;
function useMergedStates(...states: readonly State[]): State {
	const loadingState = useMemo(() => ({ status: 'loading' as const }), []);

	return useMemo(
		() => {
			if (states.some(isLoading)) return loadingState;
			const errorState = states.find(isError);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (errorState) return errorState as State;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return states.reduce((acc, state) => ({ ...acc, ...state }), {}) as State;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loadingState, ...states],
	);
}

export { useMergedStates };
