import { useTracking as useTrackingBase } from '@change-corgi/core/react/tracking';

import { useRemind } from 'src/app/shared/hooks/modals';
import type { ReminderModalOptions } from 'src/app/shared/hooks/modals';

type ModalData = Omit<ReminderModalOptions, 'continueDataQa' | 'remindMeLaterDataQa'>;
export function useMembershipReminderModal(): (modalData: ModalData, page: string) => Promise<boolean> {
	const openReminderModal = useRemind();
	const track = useTrackingBase();

	return async function displayMembershipReminderModal(
		modalData: Omit<ModalData, 'continueDataQa' | 'remindMeLaterDataQa'>,
		page: string,
	): Promise<boolean> {
		void track('membership_reminder_view', { source: page });
		return openReminderModal({
			...modalData,
			continueDataQa: 'reminder-modal-continue',
			remindMeLaterDataQa: 'reminder-modal-remind-me-later',
		});
	};
}
