import { NOT_KNOWN } from './constants';

export function getEmailDomain(email: string): string {
	const emailDomain = email.split('@')[1];
	if (!emailDomain) return NOT_KNOWN;
	const provider = emailDomain.split('.com')[0];
	const providerMap: Record<string, string> = {
		gmail: 'gmail',
		yahoo: 'yahoo',
		hotmail: 'hotmail',
		icloud: 'icloud',
	};
	return providerMap[provider] ?? NOT_KNOWN;
}
