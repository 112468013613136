import type { AppRoute } from 'src/shared/routes';

/**
 * Utility function to create multiple routes (e.g. with different SSR config)
 * with a common base
 */
export function createRoutes<
	COMMON_KEYS extends Exclude<keyof AppRoute, 'path' | 'id'>,
	PARTIAL_KEYS extends Exclude<keyof AppRoute, COMMON_KEYS>,
>(
	common: Pick<AppRoute, COMMON_KEYS>,
	partialRoutes: ReadonlyArray<Pick<AppRoute, PARTIAL_KEYS>>,
): readonly AppRoute[] {
	return partialRoutes.map((partial) => ({ ...common, ...partial }) as AppRoute);
}
