import { normalizeUpdate } from './normalizeUpdate';
import type { ApiPetitionUpdates, PetitionInfo, PetitionUpdate, PetitionUpdates } from './types';

export function normalizeUpdates<U extends PetitionUpdate = PetitionUpdate>(
	{ nodes, pageInfo }: ApiPetitionUpdates,
	petitionInfo: PetitionInfo,
): PetitionUpdates<U> {
	return {
		updates: (nodes || []).map((update) => normalizeUpdate(update, petitionInfo)) as U[],
		endCursor: (pageInfo.hasNextPage ? pageInfo.endCursor : null) || null,
	};
}
