import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetails } from '../../petitionDetails';

export type SignatureMobileIntentTrackingResult = Readonly<{
	trackMobileSignIntent: () => void;
}>;

export function useSignatureMobileIntentTracking(): SignatureMobileIntentTrackingResult {
	const track = useTracking();
	const petition = usePetitionDetails();

	return useMemo(
		() => ({
			trackMobileSignIntent: () => {
				void track<{ petition_id: string; context: string }>('mobile_sign_intent', {
					petition_id: petition.id,
					context: 'petitions_show',
				});
			},
		}),
		[petition, track],
	);
}
