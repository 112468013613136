import keys from 'lodash/fp/keys';
import mapValues from 'lodash/fp/mapValues';

import type { FeatureConfigData, FeatureConfigInfo } from '@change-corgi/core/fcm';
import type { PrefetchContext } from '@change-corgi/core/react/prefetch';

import type { useFcmCachePrefetchContext } from './cache';
import { reportMultipleConfigs } from './shared/reportMultipleConfigs';
import { updateConfigsMap } from './shared/updateConfigsMap';

type FcmCachePrefetchContext = ReturnType<typeof useFcmCachePrefetchContext>;

async function prefetchFcm<T extends Record<string, FeatureConfigInfo>>(
	configs: T,
	{
		cache: {
			fcm: { getState, setLoaded },
		},
		utilityContext,
	}: PrefetchContext<FcmCachePrefetchContext>,
): Promise<FeatureConfigData<T>> {
	const multipleConfigs = updateConfigsMap(configs);
	reportMultipleConfigs(multipleConfigs, utilityContext);

	const state = getState();

	const fcmToLoad = keys(configs).reduce<Record<string, FeatureConfigInfo>>((acc, key) => {
		const config = configs[key];
		if (state.cache[config.name]) {
			return acc;
		}
		return { ...acc, [config.name]: config };
	}, {});

	const result = await utilityContext.fcm.get(fcmToLoad);

	setLoaded(result);

	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	return mapValues(({ name }) => {
		if (!state.cache[name]) {
			return undefined;
		}
		return (state.cache[name] as { status: 'loaded'; value: T }).value;
	}, configs) as FeatureConfigData<T>;
	/* eslint-enable @typescript-eslint/consistent-type-assertions */
}

export function createFcmPrefetch<T extends Record<string, FeatureConfigInfo>>(
	configs: T,
): (context: PrefetchContext<FcmCachePrefetchContext>) => Promise<FeatureConfigData<T>> {
	return async (context: PrefetchContext<FcmCachePrefetchContext>) => prefetchFcm(configs, context);
}
