import { useCallback } from 'react';

import type { PageRenderingButton, SupporterComment } from '../../types';
import { useDeleteCommentModalBase } from '../useDeleteCommentModalBase';

type UseDeleteCommentProps = {
	petitionId: string;
	renderedOn: PageRenderingButton;
	afterDeleteAction: (commentId?: string) => void;
};

export function useDeleteCommentModal({
	petitionId,
	afterDeleteAction,
	renderedOn,
}: UseDeleteCommentProps): (comment: SupporterComment) => Promise<void> {
	const openModal = useDeleteCommentModalBase();

	const openDeleteModal = useCallback(
		async (comment: SupporterComment) => {
			const result = await openModal({
				petitionId,
				comment,
				renderedOn,
			}).waitForResult();

			if (result?.value === 'success') {
				afterDeleteAction(comment.id);
			}
		},
		[openModal, petitionId, afterDeleteAction, renderedOn],
	);

	return openDeleteModal;
}
