import {
	FCM_ENABLE_BLACK_HISTORY_LOGO,
	FCM_ENABLE_CLIMATE_STRIKE_LOGO,
	FCM_ENABLE_PRIDE_LOGO,
} from '@change-corgi/config/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm: useLogoFcm, prefetchFcm: prefetchLogoFcm } = createFcmFunctions(
	{
		showBlackHistoryLogo: FCM_ENABLE_BLACK_HISTORY_LOGO,
		showPrideLogo: FCM_ENABLE_PRIDE_LOGO,
		showClimateStrikeLogo: FCM_ENABLE_CLIMATE_STRIKE_LOGO,
	},
	{ hook: true, prefetch: true },
);
