import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

type Props = Readonly<{
	onClick?: () => void;
}>;

export function SkipToMainContentButton({ onClick }: Props): JSX.Element {
	return (
		<Box as="nav" aria-labelledby="skip-to-main-content">
			<button
				type="button"
				sx={{
					...buttonResetStyles,
					position: 'absolute',
					zIndex: 10,
					left: 0,
					top: -1000,
					outline: 'none',
					backgroundColor: 'primary-white',
					borderRadius: 'standard',
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: 'neutral-grey100',
					px: 32,
					m: 4,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:focus': {
						top: 0,
						outline: 'none',
					},
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:hover': {
						borderColor: 'neutral-grey100',
					},
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:active': {
						borderColor: 'neutral-grey100',
						backgroundColor: 'primary-white',
					},
				}}
				id="skip-to-main-content"
				onClick={onClick}
				data-testid="skip-to-main-content"
			>
				<Text
					size="small"
					color="primary-changeRed"
					fontWeight="bold"
					sx={{
						textDecoration: 'underline',
						lineHeight: '44px',
					}}
				>
					<Translate value="fe.components.header.skip_to_main_content" />
				</Text>
			</button>
		</Box>
	);
}
