import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { createRoutes, Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	...createRoutes(
		{
			restrictedAccess: {
				authOnly: true,
			},
			frame: true,
			preventCookieWall: true,
			ssr: {
				enabled: true,
				// this is a mutation page so we want to avoid navigating to it using react-router
				isolated: true,
				seo: false,
			},
		},
		[
			{
				id: 'verify-email',
				path: '/verify_your_email',
				component: loadable(async () => import(/* webpackChunkName: "pages/verify_email" */ './index'), {
					exportName: 'VerifyEmail',
					fallback: Loading,
				}),
			},
			{
				id: 'confirm-email',
				path: '/account_settings/confirm_email/:emailId',
				component: loadable(async () => import(/* webpackChunkName: "pages/verify_email" */ './index'), {
					exportName: 'ConfirmEmail',
					fallback: Loading,
				}),
			},
		],
	),
];
