import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_MEMBERSHIP_BEFORE_YOU_GO_MODAL_EXPERIMENT = createFcmConfig(
	'membership_before_you_go_modal_experiment',
	{
		normalizer: createJsonNormalizer(
			createShapeNormalizer({
				type: 'object',
				shape: {
					experiment_name: { type: 'string' },
					variant_1: {
						type: 'object',
						shape: {
							heading: {
								type: 'string',
							},
							body: { type: 'string' },
							continueLabel: { type: 'string' },
							remindMeLaterLabel: { type: 'string' },
						},
					},
					variant_2: {
						type: 'object',
						shape: {
							heading: {
								type: 'string',
							},
							body: { type: 'string' },
							continueLabel: { type: 'string' },
							remindMeLaterLabel: { type: 'string' },
						},
						optional: true,
					},
				},
			}),
		),
		defaultValue: undefined,
	},
);
