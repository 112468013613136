import type { ApiOrganization, ApiUser, PetitionInfo } from './types';

type ApiData = Readonly<{
	slug: string;
	organization: ApiOrganization;
	user: ApiUser;
}>;

export function normalizePetitionInfo({ slug, organization, user }: ApiData): PetitionInfo {
	if (organization) {
		return {
			slug,
			starter: {
				displayName: organization.name,
				photo: organization.photo?.userSmall || null,
			},
		};
	}
	return {
		slug,
		starter: {
			displayName: user.displayName,
			photo: user.photo?.userSmall || null,
		},
	};
}
