import type { RestrictedAccessConfigRoleFn } from 'src/shared/routes';

import { getCanEditPetition } from './api/canEdit';

export function canEditPetition(slugOrIdParam: string): RestrictedAccessConfigRoleFn {
	// function is named for the benefit of src/server/misc/routesStats.ts
	// eslint-disable-next-line @typescript-eslint/no-shadow
	return async function canEditPetition({ session, utilities, params }) {
		const slugOrId = params[slugOrIdParam];
		if (!slugOrId) throw new Error(`Unexpected empty param for "${slugOrIdParam}"`);
		if (!session.user) return false;
		return getCanEditPetition(utilities, slugOrId);
	};
}
