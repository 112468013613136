import type { JSX } from 'react';

import { Translate, TranslateHtml } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Box } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	year: number;
};

export function Copyright({ year }: Props): JSX.Element | null {
	return (
		<Box>
			<Text as="div" size="caption" fontWeight="bold" color="neutral-grey700">
				<Translate value="fe.components.footer.copyright" replacements={{ year }} />
			</Text>
			<Text as="div" size="caption" color="neutral-grey700">
				<TranslateHtml
					value="fe.components.footer.recaptcha.branding_text"
					replacements={{
						googlePrivacyPolicyLink: (
							<RouterLink
								to="https://policies.google.com/privacy"
								sx={{
									...linkResetStyles,
									textDecoration: 'underline',
								}}
								target="_blank"
							>
								<Translate value="fe.components.footer.recaptcha.privacy_policy_link_text" />
							</RouterLink>
						),
						googleToSLink: (
							<RouterLink
								to="https://policies.google.com/terms"
								sx={{
									...linkResetStyles,
									textDecoration: 'underline',
								}}
								target="_blank"
							>
								<Translate value="fe.components.footer.recaptcha.terms_of_service_link_text" />
							</RouterLink>
						),
					}}
				/>
			</Text>
		</Box>
	);
}
