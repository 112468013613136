import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { verifyNewUserEmail } from './api/graphql/verifyNewUserEmail';

export function useSendVerificationEmail(): (path: string, userId: string) => Promise<boolean> {
	const utilityContext = useUtilityContext();

	return useCallback(
		async (path, userId) => {
			return verifyNewUserEmail(path, utilityContext, userId);
		},
		[utilityContext],
	);
}
