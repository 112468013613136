import type { JSX } from 'react';

import { getHelpUrl } from '@change-corgi/config/help';
import type { Locale } from '@change-corgi/config/locales';
import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';
import { Translate } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';

export type FlashMessageCopy = {
	emphasizedText: TranslationKey;
	secondaryText: TranslationKey;
	redirectLink: JSX.Element | null;
	textColor: string;
	backgroundColor: string;
};
const DEFAULT_EMPHASIZED_ERROR_TEXT = translationKey('corgi.sap.preview.pre_publish_error.default_emphasized_text');

const DEFAULT_ERROR_COPY: FlashMessageCopy = {
	emphasizedText: DEFAULT_EMPHASIZED_ERROR_TEXT,
	secondaryText: translationKey('corgi.sap.preview.pre_publish_error.default_secondary_text'),
	redirectLink: null,
	textColor: 'typography-lightPrimary',
	backgroundColor: 'status-critical500',
};

// TODO: refactor params as an object
function determineRedirectLink(
	locale: Locale,
	publishError: string | null,
	redirectTo: string,
	textColor?: string,
): JSX.Element {
	const sx = textColor
		? {
				color: textColor,
				/* eslint-disable-next-line @typescript-eslint/naming-convention */
				':hover': { color: textColor },
			}
		: {};

	if (publishError?.includes('session_timeout')) {
		return (
			<Link sx={sx} to={`/login_or_join/redirected?redirectTo=${redirectTo}`}>
				<Translate value="corgi.sap.preview.redirect.sign_in" />
			</Link>
		);
	}

	return (
		<Link sx={sx} to={getHelpUrl(locale, 'contact-us')} target="_blank">
			<Translate value="corgi.sap.preview.redirect.support" />
		</Link>
	);
}

// TODO: refactor params as an object
export const determinePublishError = (
	locale: Locale,
	publishError: string | null,
	redirectTo: string,
): FlashMessageCopy | undefined => {
	if (publishError?.includes('other')) {
		return DEFAULT_ERROR_COPY;
	}

	if (publishError?.includes('session_timeout')) {
		const sessionTimeoutErrorCopy: FlashMessageCopy = {
			...DEFAULT_ERROR_COPY,
			secondaryText: translationKey('corgi.sap.preview.pre_publish_error.session_timeout_secondary_text'),
			redirectLink: determineRedirectLink(locale, publishError, redirectTo, DEFAULT_ERROR_COPY.textColor),
		};
		return sessionTimeoutErrorCopy;
	}

	const inputValidationErrorCopy: FlashMessageCopy = {
		...DEFAULT_ERROR_COPY,
		secondaryText: translationKey('corgi.sap.preview.pre_publish_error.input_secondary_text'),
		redirectLink: determineRedirectLink(locale, publishError, redirectTo, DEFAULT_ERROR_COPY.textColor),
	};

	return publishError ? inputValidationErrorCopy : undefined;
};
