import { useEffect } from 'react';

import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { useUppy as useUppyCore } from '@uppy/react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { ImageLocation } from '@change-corgi/middle-end/gql';

import { getPhotoUploadCredentials } from 'src/app/shared/hooks/uppyUploader';
import { genAWSUploadId } from 'src/app/shared/utils/upload';

export function useUppy({
	maxNumberOfFiles,
	maxFileSize,
	imageLocation,
	autoProceed = false,
}: {
	maxNumberOfFiles: number;
	maxFileSize: number;
	imageLocation: ImageLocation;
	autoProceed?: boolean;
}): ModelHookResult<
	{
		uppy: Uppy;
	},
	undefined
> {
	const utilityContext = useUtilityContext();
	const uppy = useUppyCore(
		() =>
			new Uppy({
				autoProceed,
				restrictions: {
					maxNumberOfFiles,
					allowedFileTypes: ['image/png', 'image/gif', 'image/jpeg'],
					maxFileSize,
				},
			}),
	);

	useEffect(() => {
		uppy.use(AwsS3, {
			id: genAWSUploadId(),
			getUploadParameters: async (file) => {
				const uploadCredentials = await getPhotoUploadCredentials(file.name, utilityContext, imageLocation);
				return {
					method: uploadCredentials ? 'post' : '',
					url: uploadCredentials?.postUrl ?? '',
					fields: (uploadCredentials?.s3formData ?? {}) as Record<string, string>,
				};
			},
		});
	}, [imageLocation, uppy, utilityContext]);

	return {
		data: {
			uppy,
		},
	};
}
