import type { PropsWithChildren } from 'react';
import React from 'react';

import type { FcmAccessControlConfigValueType } from 'src/app/shared/fcm/configs';

import { useUserInSet, useUserInVariant, useUserStaff } from '../hooks';
import type { FeatureAccessGuardFallback } from '../shared/types';

type Props = {
	featureAccessConfigValue: FcmAccessControlConfigValueType;
	variant: string;
	fallback?: FeatureAccessGuardFallback;
};

export function UserIsInVariantOrStaffOrInSet({
	featureAccessConfigValue,
	variant,
	fallback,
	children,
}: PropsWithChildren<Props>): React.JSX.Element | null {
	const inVariant = useUserInVariant(featureAccessConfigValue, variant);
	const staff = useUserStaff();
	const allowed = useUserInSet(featureAccessConfigValue);
	const isOpen = inVariant.isOpen || staff.isOpen || allowed.isOpen;

	if (!isOpen) {
		return fallback || null;
	}

	return <>{children}</>;
}
