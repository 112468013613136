import { useCallback, useEffect, useState } from 'react';

import type { PrefetchUserContext } from '@change-corgi/core/react/prefetch';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { isAtLeastIdentified } from '@change-corgi/core/session';

import { useCurrentUserIdAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { getRecentUserPetition } from './api';

const DISABLED_STATE = { status: 'loaded', enabled: false } as const;
const LOADING_STATE = { status: 'loading' } as const;

type DisabledState = typeof DISABLED_STATE;
type LoadingState = typeof LOADING_STATE;
type EnabledState = { status: 'loaded'; enabled: true; petitionId: string; petitionSlug: string };
export type PrefetchedStarterBannerData = DisabledState | EnabledState;

type HookState = (DisabledState | LoadingState | EnabledState) & { onClose: () => void };

export function useStarterDashboardPromptingBanner(prefetchedData?: PrefetchedStarterBannerData): HookState {
	const utilityContext = useUtilityContext();
	const userState = useCurrentUserIdAsync();
	const initialState = prefetchedData ?? LOADING_STATE;
	const [config, setConfig] = useState(initialState);

	useEffect(() => {
		if (isLoaded(config)) return;

		(async () => {
			if (isLoaded(userState)) {
				if (!userState.value) {
					setConfig(DISABLED_STATE);
					return;
				}

				const petition = await getRecentUserPetition(utilityContext);
				if (!petition) {
					setConfig(DISABLED_STATE);
					return;
				}

				setConfig({ status: 'loaded', enabled: true, petitionId: petition.id, petitionSlug: petition.slug });
			}
		})();
	}, [config, userState, utilityContext]);

	const handleBannerClose = useCallback(() => {
		setConfig(DISABLED_STATE);
	}, []);

	return { ...config, onClose: handleBannerClose };
}

export async function prefetchStarterDashboardPromptingBanner(
	context: PrefetchUserContext,
): Promise<PrefetchedStarterBannerData> {
	const { session, utilityContext } = context;

	// if user is a guest, banner is disabled
	if (!isAtLeastIdentified(session)) return DISABLED_STATE;

	const petition = await getRecentUserPetition(utilityContext);

	// if user has no recent petitions, banner is disabled
	if (!petition) return DISABLED_STATE;

	// else banner is enabled
	return { status: 'loaded', enabled: true, petitionId: petition.id, petitionSlug: petition.slug };
}
