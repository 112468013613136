import { isValidElement } from 'react';

/**
 * Assertion function that validates that the children of a `ReportAbuseLinkContainer` are a single React element.
 */
export function useValidateReportAbuseChildren(children: React.ReactNode): asserts children is React.ReactElement {
	if (!isValidElement(children)) {
		throw new Error('ReportAbuseLinkContainer must have a single child that is a React element');
	}
}
