import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		path: '/corgi-dev/p/:slug/corgi/hello/old',
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildUrl: ({ params: { slug }, queryString }) => `/corgi-dev/p/${slug!}/corgi/hello${queryString}`,
		reason: 'deprecated-url',
	},
];
