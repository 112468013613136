import type { JSX, PropsWithChildren } from 'react';

import { PaypalContext } from './context';
import { usePaypalState } from './usePaypalState';

export function PaypalProvider({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	const state = usePaypalState();

	return <PaypalContext.Provider value={state}>{children}</PaypalContext.Provider>;
}
