export function isActionsPageEnabled(
	campaignActionsConfig:
		| {
				enabled: boolean;
				activatedPercentage: number;
				specificPetitions: ReadonlyArray<{ id: number }>;
				blocklist?: ReadonlyArray<{ id: number }>;
		  }
		| undefined,
	petitionId: string,
): boolean {
	if (!campaignActionsConfig) return false;

	const { activatedPercentage, enabled, specificPetitions, blocklist } = campaignActionsConfig;

	const isSpecifiedPetition = !!specificPetitions.find((p) => p.id === Number(petitionId));
	const isInBlocklist = blocklist?.some((p) => p.id === Number(petitionId));

	const lastPetitionIdValue = Number(petitionId.charAt(petitionId.length - 1));
	const checkValue = activatedPercentage / 10;
	const validPetition = lastPetitionIdValue < checkValue || isSpecifiedPetition;

	return enabled && validPetition && !isInBlocklist;
}
