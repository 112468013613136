import type { MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

export function useDropDownRef(): {
	isOpen: boolean;
	toggleDropDown: () => void;
	ref: MutableRefObject<HTMLDivElement | null>;
} {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggleDropDown = (): void => {
		setIsOpen((wasOpen: boolean) => !wasOpen);
	};

	const ref = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		const clickListener = (e: MouseEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (!ref.current?.contains(e.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('click', clickListener);
		return () => {
			document.removeEventListener('click', clickListener);
		};
	}, []);

	return { ref, isOpen, toggleDropDown };
}
