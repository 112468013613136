import { useMemo } from 'react';

import type { UseQueryResult } from '@tanstack/react-query';

import type {
	LoadedStateData,
	MappedLoadedState,
	MappedLoadedStateWithoutError,
	State,
	StateWithoutError,
} from 'src/app/shared/utils/async';
import { mapLoadedStateData } from 'src/app/shared/utils/async';

/**
 * Utility hook that allows to remap loaded values from a state
 *
 * @example
 * useMappedLoadedState({ status: 'loaded', foo: 42, bar: 51 }, ({ bar, foo }) => ({ foobar: bar + foo }))
 */
function useMappedLoadedState<S extends StateWithoutError, R extends Record<string, unknown>>(
	state: S,
	mapper: (values: LoadedStateData<S>) => R,
): MappedLoadedStateWithoutError<S, R>;
function useMappedLoadedState<S extends State | UseQueryResult, R extends Record<string, unknown>>(
	state: S,
	mapper: (values: LoadedStateData<S>) => R,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
): MappedLoadedState<S, R>;

function useMappedLoadedState<S extends State | UseQueryResult, R extends Record<string, unknown>>(
	state: S,
	mapper: (values: LoadedStateData<S>) => R,
): State {
	return useMemo(() => mapLoadedStateData(state, mapper), [state, mapper]);
}

export { useMappedLoadedState };
