import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		multiplier_percent: { type: 'number' },
		impressions_served_threshold: { type: 'number' },
		sign_conversion_percent_threshold: { type: 'number' },
	},
});

export const FCM_REVENUE_DYNAMIC_CPM = createFcmConfig('revenue_dynamic_cpm', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: undefined,
});
