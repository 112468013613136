import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		starterPercentage: {
			type: 'number',
		},
		maximumPerPetition: {
			type: 'number',
		},
	},
});

export const FCM_PETITIONS_MEDIA_HITS_CONFIG = {
	name: 'petitions_media_hits_config',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		starterPercentage: 0,
		maximumPerPetition: 5,
	} as ReturnType<typeof normalizer>,
};
