import { MembershipOnsitePlacements } from 'src/app/shared/components/membershipAwarenessExperiments';

import { useMembershipAwarenessBannerFcm, useMembershipAwarenessBannerFcmExperiments } from '../context';

import { useLayout } from './hooks/useLayout';

export function MembershipAwarenessBanner(): React.JSX.Element | null {
	const {
		data: { show, url },
		actions: { onClickClose, onClickSupport },
	} = useLayout();
	const { membershipAwarenessBannerExperiment } = useMembershipAwarenessBannerFcmExperiments();
	const { localizedCountsConfig } = useMembershipAwarenessBannerFcm();
	const variant = membershipAwarenessBannerExperiment.variation;

	if (!show) return null;

	return (
		<MembershipOnsitePlacements
			variant={variant}
			localizedCountsConfig={localizedCountsConfig}
			onClickSupport={onClickSupport}
			onClickClose={onClickClose}
			url={url}
		/>
	);
}
