import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'search-v2',
		path: '/v2/search',
		component: loadable(async () => import(/* webpackChunkName: "pages/search_v2" */ './index'), {
			exportName: 'SearchV2',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: true,
		},
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
	},
];
