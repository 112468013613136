import isEmail from 'validator/lib/isEmail';

import type { I18nFieldValidator, NonI18nFieldValidator, WithI18nConfig, WithNonI18nConfig } from './shared/types';

export function emailFormatValidator({
	i18n,
}: WithI18nConfig<EmptyIntersection>): I18nFieldValidator<string | undefined> {
	return (value) => (!value || isEmail(value) ? undefined : i18n);
}

export function nonI18nEmailFormatValidator({ message }: WithNonI18nConfig): NonI18nFieldValidator<string | undefined> {
	return (value) => (!value || isEmail(value) ? undefined : message);
}
