import { useCallback } from 'react';

import type { TrackingFn } from '@change-corgi/core/react/tracking';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useLocale } from 'src/app/shared/hooks/l10n';

import { useCanReportVideo } from '../../../shared/hooks/useCanReportVideo';
import type { SupporterVideo } from '../../../shared/types';

type Result = ModelHookResult<
	{ canReportVideo: boolean },
	{
		handleReportClick: () => void;
	}
>;

export function useReportVideo({ petitionId, video }: { petitionId: string; video: SupporterVideo }): Result {
	const { track } = useCampaignTracking();
	const locale = useLocale();
	const allowedLocales = ['en-US', 'en-GB'];

	const { canReportVideo, currentUser, loginState } = useCanReportVideo(video);

	const handleReportClick = useCallback(() => {
		let payload: Parameters<TrackingFn>[1] = {
			petition_id: petitionId,
			reporter_id: currentUser?.id,
			loginState,
		};
		payload = video.videoUrl
			? { ...payload, video_id: video.id, video_creator_id: video.user?.id }
			: { ...payload, comment_id: video.id, comment_creator_id: video.user?.id };

		void track('supporter_voice_report_button_click', payload);
	}, [currentUser, loginState, petitionId, track, video]);

	return {
		data: { canReportVideo: canReportVideo && allowedLocales.includes(locale) },
		actions: {
			handleReportClick,
		},
	};
}
