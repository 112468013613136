import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'starter-onboarding',
		path: '/p/:slug/starter-onboarding/:step',
		component: loadable(async () => import(/* webpackChunkName: "pages/starter_onboarding" */ './index'), {
			exportName: 'StarterOnboarding',
			fallback: Loading,
		}),
		frame: {
			footer: false,
			header: true,
			width: 'large',
		},
		restrictedAccess: {
			authOnly: true,
			// role: canEditPetition('slug'),
			role: 'staff',
		},
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: false,
		},
	},
];
