import type { I18nFieldValidator, NonI18nFieldValidator, WithI18nConfig, WithNonI18nConfig } from './shared/types';

export function patternValidator({
	i18n,
	pattern,
}: WithI18nConfig<{ pattern: RegExp }>): I18nFieldValidator<string | undefined> {
	return (value) => (!value || pattern.test(value) ? undefined : i18n);
}

export function nonI18nPatternValidator({
	message,
	pattern,
}: WithNonI18nConfig<{ pattern: RegExp }>): NonI18nFieldValidator<string | undefined> {
	return (value) => (!value || pattern.test(value) ? undefined : message);
}
