import { useMemo } from 'react';

import type { ShareChannel } from '../shared/types';

import { useGetShareAction } from './getAction';

type ShareAction<CH extends ShareChannel = ShareChannel> = {
	channel: CH;
	url: string;
	shortUrl?: string;
	action: () => Promise<boolean>;
};

type Options<CH extends ShareChannel = ShareChannel> = {
	trackingEventName: string;
	getUrl: (channel: CH) => string;
	getShortUrl: (channel: CH) => string;
	getText: (channel: CH, type: 'title' | 'body', context: { url: string }) => string;
	getTrackingData: (channel: CH) => Readonly<Record<string, boolean | string | number | undefined>>;
	onSuccess?: (channel: CH) => void | Promise<void>;
	onFailure?: (channel: CH) => void | Promise<void>;
};

export function useShareActions<CH extends ShareChannel = ShareChannel>(
	channels: readonly CH[],
	{ getUrl, ...options }: Options<CH>,
): ReadonlyArray<ShareAction<CH>> {
	const getShareAction = useGetShareAction(options);
	const { getShortUrl } = options;
	return useMemo(
		() =>
			channels.map((channel) => {
				const url = getUrl(channel);
				const shortUrl = getShortUrl(channel);
				return {
					channel,
					url,
					shortUrl: url === shortUrl ? undefined : shortUrl,
					action: getShareAction(channel),
				};
			}),
		[channels, getShareAction, getUrl, getShortUrl],
	);
}
