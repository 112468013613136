import type { WordBreak } from '../types';

import { computeHeight } from './computeHeight';
import { genCheckContentsFit } from './genCheckContentsFit';
import { getElementToTruncate } from './getElementToTruncate';
import { hideElement } from './hideElement';
import { truncateElement } from './truncateElement';

type Options = Readonly<{
	height: number | string;
	container: HTMLElement;
	contents: HTMLElement;
	ellipsis: HTMLElement;
	wordBreak: WordBreak;
}>;

export function truncate({ container, contents, ellipsis, wordBreak, height }: Options): () => void {
	const computedHeight = computeHeight(container, height);

	const checkContentsFit = genCheckContentsFit(contents, computedHeight);

	if (checkContentsFit()) {
		const unHide = hideElement(ellipsis);

		return () => {
			unHide();
		};
	}

	const { elementToTruncate, reset } = getElementToTruncate({
		checkContentsFit,
		ellipsisEl: ellipsis,
		contentsEl: contents,
	});

	const resetElementToTruncate: undefined | (() => void) =
		elementToTruncate &&
		truncateElement(elementToTruncate, {
			checkContentsFit,
			wordBreak,
		});

	return () => {
		reset();
		resetElementToTruncate?.();
	};
}
