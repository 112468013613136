import type { JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { useIsFirstAppRender } from '@change-corgi/core/react/ssr/render';

import { useSessionState } from '../stateContext/context';

import { SessionContextProvider as SessionContextProviderBase } from './context';

export function SessionContextProvider({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	const [{ session, sessionUpdates }, mutations] = useSessionState();
	const firstRender = useIsFirstAppRender();
	const value = useMemo(() => {
		return {
			sync: session,
			async:
				session && !firstRender
					? {
							status: 'loaded' as const,
							value: session,
						}
					: { status: 'loading' as const },
			sessionUpdates,
			mutations,
		};
	}, [session, sessionUpdates, mutations, firstRender]);

	return <SessionContextProviderBase value={value}>{children}</SessionContextProviderBase>;
}
