import { Children, cloneElement, type PropsWithChildren } from 'react';

import { useOpenReportAbuseModal } from '../hooks/useOpenReportAbuseModal';
import { useValidateReportAbuseChildren } from '../hooks/useValidateReportAbuseChildren';
import type { ReportContext } from '../shared/types';

/**
 * Container component that wraps a child component and opens the report abuse modal on click.
 *
 * This component expects a single child that is a `<button>` or `<a>` element.
 */
export const ReportAbuseLinkContainer: React.FC<ReportAbuseLinkContainerProps> = ({ children, ...reportContext }) => {
	const openReportAbuseModal = useOpenReportAbuseModal({ reportContext });

	const child = Children.only(children);

	useValidateReportAbuseChildren(child);

	return cloneElement(child, {
		onClick: openReportAbuseModal,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'data-qa': `report-abuse-${reportContext.type}`,
	});
};

type ReportAbuseLinkContainerProps = PropsWithChildren<ReportContext>;
