import { createStateContext } from '@change-corgi/core/react/context';

import { initialState } from './initialState';
import { mutations } from './mutations';
import type { MembershipBottomBannerState } from './types';

export const {
	StateContext: MembershipBottomBannerStateContext,
	StateProvider: MembershipBottomBannerStateContextProvider,
	useStateContext: useMembershipBottomBannerState,
} = createStateContext<MembershipBottomBannerState, typeof mutations>({
	name: 'MembershipBottomBannerStateContext',
	mutations,
	initialState: { ...initialState() },
});
