import { createModalHook } from '@change-corgi/design-system/modals';

import { EducationModal } from './components/EducationModal';
import { EducationModalHeading } from './components/EducationModalHeading';

export const useEducationModal = createModalHook<EmptyObject, { successfulShare: boolean }>({
	name: 'InstagramShareEducationModal',
	heading: EducationModalHeading,
	body: function EducationModalBody({ closeModal }) {
		return (
			<EducationModal
				onContinue={() => {
					closeModal({ successfulShare: true });
				}}
			/>
		);
	},
	// if share attempt is done within another modal, we need to kill that modal
	// to avoid multiple layers of modals
	killOpenModal: true,
});
