import { useLocation } from 'react-router';

function useRedirectPathFromLocation(): string {
	const location = useLocation();
	return `${location.pathname}${location.search}`;
}

export function useLoginRedirectUrl(path?: string): string {
	const currentPath = useRedirectPathFromLocation();
	return `/login_or_join?redirectTo=${encodeURIComponent(path || currentPath)}`;
}
