import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { SetStateCodeMutation, SetStateCodeMutationVariables } from './api.graphql';

export type SetStateCodeInput = SetStateCodeMutationVariables['input'];

const QUERY = gql`
	mutation SetStateCode($input: SetStateCodeInput!) {
		setStateCode(input: $input) {
			success
		}
	}
`;

export async function setStateCode(
	{ stateCode }: { stateCode: string },
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const result = await fetch<SetStateCodeMutation, SetStateCodeMutationVariables>({
		query: QUERY,
		variables: {
			input: {
				stateCode,
			},
		},
	});

	return !!result.data?.setStateCode.success;
}
