import type { PropsWithChildren } from 'react';
import React from 'react';

import { withPrefetchedData } from '@change-corgi/core/react/prefetch';
import { Box } from '@change-corgi/design-system/layout';

import { MembershipAwarenessBanner } from 'src/app/shared/components/membershipAwarenessBanner';

import { Footer } from './footer';
import { Header } from './header';
import { prefetch } from './prefetch';
import type { DefaultLayoutProps } from './shared/types';

export const DefaultLayout = withPrefetchedData(
	({ hideHeader, hideFooter, width, children }: PropsWithChildren<DefaultLayoutProps>): React.JSX.Element | null => {
		if (hideFooter && hideHeader) {
			return (
				<Box
					// needed so that App can focus fallback on it
					data-maincontent-principal
					sx={{ height: '100%' }}
				>
					{children}
				</Box>
			);
		}

		// TODO(CHANGE-68189): This is an additional defaulting step that is necessary because of DS's naming. Once we
		// make 'large' the actual default we will be consistent with the DS and we won't need a distinction between
		// `width` and `containerVariant`. See:
		// https://github.com/change/fe-design-system/blob/d246c383/src/theme/container.ts#L3
		const containerVariant = width === 'large' ? 'default' : 'small';

		return (
			<>
				{/* https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/ */}
				<Box>
					{!hideHeader && (
						<>
							<Header containerVariant={containerVariant} sx={{ flexShrink: 0 }} />
							<MembershipAwarenessBanner />
						</>
					)}
					<Box
						// needed so that App can focus fallback on it
						data-maincontent-principal
						// this is to avoid blank space after the footer
						// using flex:1 on the body currently doesn't work because of the banners being outside of the layout
						// and that can sometimes result in double scrollbars on desktop
						// 300px come from manually measuring the height of the footer and header
						// (not great but better than nothing for now)
						sx={{ minHeight: hideFooter ? undefined : [null, 'calc(100vh - 300px)'] }}
						data-qa="page-content"
					>
						{children}
					</Box>
					{!hideFooter && <Footer containerVariant={containerVariant} sx={{ flexShrink: 0 }} />}
				</Box>
			</>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => {
			await prefetch(context);
		},
	},
);
