import type { PrefetchContext } from '@change-corgi/core/react/prefetch';

import type { CountryBannerConfig, CountryBannerContext } from './types';
import { prefetchCountryBannerBase, useCountryBannerBase } from './useCountryBannerBase';

export function useCountryBanner(
	context: CountryBannerContext,
	petition?: { id: string; signed: boolean },
): CountryBannerConfig | undefined {
	const countryBanner = useCountryBannerBase(context, petition?.id);
	if (context === 'home') return countryBanner;
	return !petition || (countryBanner?.banner_petition_id === petition.id && petition.signed)
		? undefined
		: countryBanner;
}

export async function prefetchCountryBanner(context: PrefetchContext): Promise<void> {
	await prefetchCountryBannerBase(context);
}
