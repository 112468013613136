import { useLoginState } from 'src/app/shared/hooks/session';
import { setPxUserData } from 'src/app/shared/utils/payments';

export function useSetPxGuestUserData(): (email: string) => void {
	const authState = useLoginState();
	return function setPxGuestUserData(email) {
		if (authState !== 'GUEST') return;
		setPxUserData({ email });
	};
}
